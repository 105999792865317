<template>
    <div class="mb-6 pa-3">
        <v-row class="mb-n5 mt-n2" dense>
            <v-col cols="6">
                <h4>ESTADO DE GARANTIAS</h4>
            </v-col>
            <v-col cols="6">
                <v-row dense>
                    <v-col cols="4">
                        <v-select :items="array_estados" label="ESTADO" dense outlined v-model="estados"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date1"
                            label="INICIO"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date2"
                            label="INICIO"></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-simple-table class="elevation-1 mt-2" fixed-header height="65vh">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Paciente
                        </th>
                        <th class="text-left">
                            Producto
                        </th>
                        <th class="text-left">
                            Especialista
                        </th>
                        <th class="text-center">
                            Estado
                        </th>
                        <th class="text-center">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td>{{ item.nom_cliente }}</td>
                        <td>{{ item.producto.nombre }}</td>
                        <td>{{ item.espe }}</td>
                        <td>
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="#FFB200" v-if="item.estado == 'PENDIENTE'">mdi-circle</v-icon>
                                    <v-icon color="#46FF00" v-if="item.estado == 'ACEPTADO'">mdi-circle</v-icon>
                                    <v-icon color="#0017FF" v-if="item.estado == 'ENTREGADO'">mdi-circle</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                        <td style="font-size:90%;">
                            <v-img v-if="item.estado == 'ACEPTADO'" @click="confirma_llegada(item)" class="mx-auto mt-n2"
                                height="30" width="30" src="/duda.gif"></v-img>
                        </td>
                        <td>

                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
import store from '@/store/index'
import {
    all_mediciones,
    busca_tabla,
    allcitas,
    edita_cita,
    buscaCliente,
    edita_medicion,
    all_garantias_p,
    edita_garantias_p
} from '../../db'
import moment from 'moment'
export default {

    data: () => ({
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        desserts: [],
        array_estados: ['TODOS', 'PENDIENTE', 'ACEPTADO', 'ENTREGADO'],
        estados: 'TODOS'
    }),

    created() {
        this.inicio()
    },
    mounted() {
        all_garantias_p().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_garantias_p().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_garantias_p().orderByChild('fecha').startAt(moment(String(this.date1)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            var array = this.desserts
            if (this.estados == 'TODOS') {
                return array
            } else {
                return array.filter((item) => (item.estado == this.estados))
            }


        }
    },
    methods: {
        async confirma_llegada(item) {
            if (confirm('ESTA SEGURO DE CONFIRMAR?')) {
                edita_garantias_p(item.key + '/estado', 'ENTREGADO')
            }
        },
        async inicio() {
            var dia = moment(String(new Date)).format('DD')
            this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
            this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
        },
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                let key = item.key
                data.key = key
                array.push(data);
            });

            this.desserts = array.reverse();
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY hh:mm A')
        },
    }
}
</script>
