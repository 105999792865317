<template>
    <v-dialog v-model="dial_L" max-width="1050px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="nuevo()" v-if="edicion">mdi-plus</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">

            <v-simple-table dark fixed-header height="70vh" dense>
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Codigo
                            </th>
                            <th class="text-left">
                                Razon Social
                            </th>
                            <th class="text-left">
                                Direccion
                            </th>
                            <th class="text-left" v-if="edicion">
                                Accion
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in array_tabla" :key="item.id" @click="selecciona(item)">
                            <td>{{ item.codigo }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.direccion }}</td>
                            <td width="10" v-if="edicion">
                                <div class="d-flex pa-1">
                                    <v-icon color="green" medium @click="editar(item)">mdi-pencil</v-icon>
                                    <v-icon color="red" medium @click="eliminar(item)">mdi-delete</v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <v-card-text>

                    </v-card-text>

                </template>
            </v-simple-table>

        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large color="green" @click="agregar()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <div>
                    <v-row class="mt-n2" dense>
                        <v-col cols="6">
                            <v-select outlined :disabled="permiso_edita" dense v-model="tipodoc" :items="arraydocumento"
                                menu-props="auto" hide-details label="TIPO DOC"></v-select>

                        </v-col>
                        <v-col cols="6">
                            <v-text-field outlined type="number" dense v-model="codigo" label="N°Doc"
                                append-icon="mdi-magnify" @click:append="busca_proveedor()"
                                @keyup.enter="busca_proveedor()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-textarea dense outlined v-model="nombre" auto-grow filled label="Nombres" rows="1"></v-textarea>
                    <v-textarea dense outlined v-model="direccion" auto-grow filled label="Direccion" rows="1"></v-textarea>
                    <v-text-field outlined type="number" dense v-model="celular" label="Celular"></v-text-field>
                </div>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import axios from "axios"
import {
    agrega_tabla,
    busca_tabla
} from '../../db'
import store from '@/store/index'
export default {
    name: 'caja',
    props: {
        dial_L: '',
        edicion: ''
    },
    data() {
        return {
            arraydocumento: ['DNI', 'RUC', 'PASSAPORTE', 'CE'],
            tipodoc: 'DNI',
            dialogo_agrega: false,
            codigo: '',
            direccion: '',
            nombre: '',
            celular: '',
            array_tabla: [],
            edit: false
        }
    },
    mounted() {
        busca_tabla('vendedores').on("value", this.onDataChange);
    },
    beforeDestroy() {
        busca_tabla('vendedores').off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            busca_tabla('vendedores').on("value", this.onDataChange);
            return this.array_tabla
        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_tabla = array;
        },
        nuevo() {
            this.tipodoc = 'DNI'
            this.codigo = ""
            this.nombre = ''
            this.direccion = ''
            this.celular = ''
            this.edit = false
            this.dialogo_agrega = true
        },
        agregar() {
            store.commit("dialogoprogress", 1)
            if (this.edit) {
                var i = this.array_tabla.findIndex(array => array.codigo === this.codigo)
                this.array_tabla[i].tipodoc = this.tipodoc
                this.array_tabla[i].codigo = this.codigo.trim()
                this.array_tabla[i].nombre = this.nombre.toUpperCase()
                this.array_tabla[i].direccion = this.direccion.toUpperCase()
                this.array_tabla[i].celular = this.celular
                this.edit = false
            } else {
                this.array_tabla.push({
                    tipodoc: this.tipodoc,
                    codigo: this.codigo.trim(),
                    nombre: this.nombre.toUpperCase(),
                    direccion: this.direccion.toUpperCase(),
                    celular: this.celular,
                })
            }
            agrega_tabla("vendedores", this.array_tabla).then(() => {
                store.commit("dialogoprogress")
                this.dialogo_agrega = false
            })

        },
        editar(date) {
            this.edit = true
            this.tipodoc = date.tipodoc
            this.codigo = date.codigo
            this.nombre = date.nombre
            this.direccion = date.direccion
            this.celular = date.celular
            this.dialogo_agrega = true
        },
        eliminar(item) {
            store.commit("dialogoprogress", 1)
            var i = this.array_tabla.findIndex(array => array.codigo === item.codigo)
            this.array_tabla.splice(i, 1)
            agrega_tabla("vendedores", this.array_tabla).then(() => {
                store.commit("dialogoprogress", 1)
                this.dialogo_agrega = false
            })
        },
        selecciona(item) {
            if (!store.state.tabla_proveedores) {
                this.$emit('array', item)
                store.commit('dialogo_tabla_proveedores', false)
            }
        },
        busca_proveedor() {
            store.commit("dialogoprogress", 1)
            if (this.codigo.length == 11) {
                var documento = "RUC"
            } else {
                var documento = "DNI"
            }
            var self = this
            var token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + documento.toLowerCase() + '/' + this.codigo, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress", 1),
                    self.llenardatos(response.data.data, documento)
                ))
        },
        llenardatos(data, documento) {
            console.log(data)
            if (data != undefined) {
                if (documento == 'DNI') {
                    console.log(data)
                    this.nombre = data.nombre_completo
                    this.direccion = data.direccion_completa
                }
                if (documento == 'RUC') {
                    this.nombre = data.nombre_o_razon_social
                    this.direccion = data.direccion_completa
                }
            } else {
                store.commit('dialogosnackbar', 'Documento no existe')
            }

        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
