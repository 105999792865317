import { obtenContador, grabaAnulacionreferecia, grabaCabeceraRA, grabaDetalleRA, sumaContador } from '@/db'
import { resumenBajaSunat, resumenComprobantes } from '@/servidorsunat'
import moment from 'moment'

export const ejecutaresumen = (array) => {
    var resp = obtencorrelativo(array.tipocomprobante).then((r) => {

        if (array.tipocomprobante == "F") {
            var motivo = "Error en Documento"
            var modoanula = "RA"
        } else {
            var motivo = 3
            var modoanula = "RC"
        }
        var items = []
        items.push({
            item: 1,
            tipo_comprobante: array.cod_comprobante,
            serie: array.serie,
            correlativo: array.correlativoDocEmitido,
            motivo: motivo,
            total_a_pagar: (parseFloat(array.total)).toFixed(2),
            total_op_gravadas: array.total_op_gravadas,
            total_impuestos: array.igv
        })
        var a = enviaresumenes(r, items, modoanula).then((r) => {
            return r
        })
        return a
    })
    return resp
}

function obtencorrelativo(Tipo) {
    var a = obtenContador().once("value").then((snapshot) => {
        console.log(snapshot.val())
        if (snapshot.exists()) {
            if (Tipo == "F") {
                return snapshot.val().ordenresumen
            } else {
                return snapshot.val().ordenresumen
            }

        }
    })
    return a
}

function enviaresumenes(r, arrayItems, modoanula) {
    var fecha_1 = moment().format('YYYY-MM-DD')
    var fecha_2 = moment().format('YYYYMMDD')
    var fecha = moment().unix()

    var arrayCabecera = {
        fecha: fecha,
        tipo_comprobante: modoanula,
        fecha_referencia: fecha_1,
        fecha_envio: fecha_1,
        serie: fecha_2,
        correlativo: r,
        estado: 'PENDIENTE',
        mensajeSunat: '',
        ticket: '',
    }
    if (modoanula == "RA") {
        var a = resumenBajaSunat(arrayCabecera, arrayItems).then((ra) => {
            finaldeanulacion(arrayCabecera, arrayItems, r, modoanula)
            return ra
        })
        return a
    }
    else {

        var a = resumenComprobantes(arrayCabecera, arrayItems).then((ra) => {
            finaldeanulacion(arrayCabecera, arrayItems, r, modoanula)
            return ra
        })
        return a
    }

}

function finaldeanulacion(arrayCabecera, arrayItems, correlativo, modoanula) {
    grabaAnulacionreferecia(arrayItems[0].serie + '-' + arrayItems[0].correlativo, 'anulado', 'Anulado mediante baja Comprobante: ' + correlativo)

    grabaCabeceraRA(modoanula + correlativo, arrayCabecera)
    grabaDetalleRA(modoanula + correlativo, arrayItems).then((r) => {
        if (modoanula == "RA") {
            var nomcontador = "ordenresumen"
        }
        else {
            var nomcontador = "ordenresumen"
        }
        sumaContador(nomcontador, (parseInt(correlativo) + 1).toString().padStart(4, 0))

    })
}