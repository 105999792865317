<template>
    <div mb-5 class="pa-3">
        <v-row>
            <v-col cols="6">
                <v-btn color="success mb-1" small block @click.prevent="crear_tie()">Crear Tienda</v-btn>
            </v-col>
            <v-col cols="6">

            </v-col>
        </v-row>

        <v-simple-table class="elevation-1 mt-2" fixed-header height="60vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            TIENDA
                        </th>
                        <th class="text-left">
                            RAZON SOCIAL
                        </th>
                        <th class="text-left">
                            DIRECCION
                        </th>
                        <th class="text-left">
                            B.Datos
                        </th>
                        <th class="text-left">
                            ACCION
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in desserts" :key="item.token">
                        <td>{{ item.nom_tienda }}</td>
                        <td>{{ item.razon_tienda }}</td>
                        <td>{{ item.direccion }}</td>
                        <td>{{ item.bd }}</td>
                        <td>
                            <v-icon @click="abre_configura(item)" color="red">mdi-cog</v-icon>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dialog" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dialog = !dialog" color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large @click="crear_tienda()" color="green">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="mx-auto pa-3" color="white">
                <v-row class="mt-n4">
                    <v-col cols="12">
                        <v-text-field outlined dense v-model="nom_tienda" label="Nombre"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field outlined type="number" dense v-model="documento" label="RUC" append-icon="mdi-magnify"
                            @click:append="BuscarDocumento()" @keyup.enter="BuscarDocumento()"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field outlined type="number" dense v-model="telefono" label="Telefono"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea outlined dense v-model="nombre" auto-grow filled label="RAZON SOCIAL"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="departamento" label="DEPARTAMENTO"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="provincia" label="PROVINCIA"></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="distrito" label="DISTRITO"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="ubigeo" label="UBIGEO"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n7" dense>
                    <v-col cols="12">
                        <v-textarea outlined dense v-model="direccion" auto-grow filled label="Direccion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n7" dense>
                    <v-col cols="12">
                        <v-text-field outlined dense v-model="bd" label="BASE DE DATOS"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import {
    nueva_tienda,
    all_tienda,
} from '../../db'
import store from '@/store/index'
import axios from "axios"
import {
    v1 as uuidv1
} from 'uuid';
export default {

    data() {
        return {
            dialog: false,
            dialog_configura: false,
            desserts: [],
            permisosususario: [],
            departamento: '',
            provincia: '',
            distrito: '',
            direccion: '',
            ubigeo: '',
            nombre: '',
            tipodoc: 'RUC',
            documento: '',
            telefono: '',
            nom_tienda: '',
            tienda_selecta: [],
            bd: ''

        }
    },
    mounted() {
        all_tienda().on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_tienda().off("value", this.onDataChange);
    },
    methods: {
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },
        crear_tie() {
            this.nom_tienda = ''
            this.documento = ''
            this.nombre = ''
            this.departamento = ''
            this.provincia = ''
            this.distrito = ''
            this.ubigeo = ''
            this.direccion = ''
            this.telefono = ''
            this.bd = ''
            this.tienda_selecta = []
            this.dialog = true
        },
        crear_tienda() {
            store.commit("dialogoprogress", 2)
            var id = this.desserts.length
            if (this.tienda_selecta != '') {
                id = this.tienda_selecta.id_tienda
            }
            var array = {
                nom_tienda: this.nom_tienda,
                id_tienda: id,
                ruc_tienda: this.documento,
                razon_tienda: this.nombre,
                departamento: this.departamento,
                provincia: this.provincia,
                distrito: this.distrito,
                ubigeo: this.ubigeo,
                direccion: this.direccion.trim(),
                telefono: this.telefono,
                bd: this.bd
            }
            console.log(array)
            nueva_tienda(array.id_tienda, array).then(() => {
                store.commit("dialogoprogress", 2)
                this.dialog = false
            })
        },
        abre_configura(data) {
            this.tienda_selecta = data
            this.nom_tienda = data.nom_tienda
            this.documento = data.ruc_tienda
            this.nombre = data.razon_tienda
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.ubigeo = data.ubigeo
            this.direccion = data.direccion
            this.telefono = data.telefono
            this.bd = data.bd
            this.dialog = true
        },
        BuscarDocumento() {
            if (this.documento != '' &&
                this.documento.length == 8 || this.documento.length == 11) {
                if (Number(this.documento.length) == 8) {
                    this.tipodoc = "DNI"
                }
                if (Number(this.documento.length) == 11) {
                    this.tipodoc = "RUC"
                }

                store.commit("dialogoprogress", 1)
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/' + this.tipodoc.toLowerCase() + '/' + this.documento, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        store.commit("dialogoprogress",),
                        self.llenardatos(response.data.data)
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress", 1),
                            self.snack('Documento Invalido')
                    })
            } else {
                this.snack("Ingrese Documento")
            }
        },
        llenardatos(data) {
            this.nombre = data.nombre_o_razon_social
            this.direccion = data.direccion
            this.departamento = data.departamento
            this.provincia = data.provincia
            this.distrito = data.distrito
            this.ubigeo = data.ubigeo_sunat
        },
    }

}
</script>
