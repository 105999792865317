import jspdf from 'jspdf'
import 'jspdf-autotable'
import store from '@/store/index'
import QR from 'qrcode-base64'
import moment from 'moment'
import { NumerosALetras } from 'numero-a-letras'
export const pdfGenera = (cabecera, receta, parametro, array_tienda) => {
    console.log(array_tienda)
    var data = cabecera
    var linea = parseInt(store.state.configImpresora.msuperior)
    var Ruc = 'Ruc: ' + store.state.baseDatos.ruc
    var Direccion = store.state.baseDatos.direccion + '-' + store.state.baseDatos.distrito + '-' + store.state.baseDatos.provincia + '-' + store.state.baseDatos.departamento
    var imagen = store.state.logoempresa
    var separacion = "---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------"
    var lMargin = 3.5; //left margin in mm
    var rMargin = 2; //right margin in mm
    var pdfInMM = 150;  // width of A4 in mm
    var imagen_agua = store.state.logoempresa_agua
    var cabecera_espacio = store.state.configImpresora.cabecera;
    var piepagina = store.state.configImpresora.piepagina;
    var telefono = store.state.configImpresora.telefono;
    var pageCenter = pdfInMM / 2;

    var fechaImpresion = moment(String(new Date)).format('YYYY-MM-DD')
    const doc = new jspdf({
        orientation: "portrait",
        unit: "mm",
        format: [250, pdfInMM]
    })
    doc.setTextColor(7);
    doc.text('.', 0, linea)
    linea = linea + 3
    doc.addImage("data:image/png;base64," + imagen_agua, 'png', 12, 15, 130, 130, '', 'FAST');
    doc.setFontSize(10)
    doc.setFont('Helvetica', 'Bold');
    var texto = doc.splitTextToSize('EMPRESA : ' + array_tienda.ruc_tienda + ' - ' + array_tienda.razon_tienda, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left'); //EMPRESA
    linea = linea + (5 * texto.length)

    var texto = doc.splitTextToSize('FECHA : ' + fechaImpresion, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left'); //EMPRESA
    linea = linea + (5 * texto.length)

    var texto = doc.splitTextToSize('N° Pedido   : ' + cabecera.id, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (5 * texto.length)

    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(8)
    doc.text(separacion, pageCenter, linea, 'center');
    linea = linea + 5

    doc.setFont('Helvetica', '');
    doc.setFontSize(9)



    var texto = doc.splitTextToSize('RECETA  : ', (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3.5 * texto.length)

    var nuevoArray = new Array(1);
    nuevoArray[0] = new Array(7);
    nuevoArray[0][0] = 'OD';
    nuevoArray[0][1] = receta.esfera_od;
    nuevoArray[0][2] = receta.cilindro_od;
    nuevoArray[0][3] = receta.eje_od;
    nuevoArray[0][4] = receta.add;
    nuevoArray[0][5] = parametro.naso_pu_od;
    nuevoArray[0][6] = parametro.altura;
    nuevoArray[1] = new Array(7);
    nuevoArray[1][0] = 'OI';
    nuevoArray[1][1] = receta.esfera_oi;
    nuevoArray[1][2] = receta.cilindro_oi;
    nuevoArray[1][3] = receta.eje_oi;
    nuevoArray[1][4] = receta.add;
    nuevoArray[1][5] = parametro.naso_pu_oi;
    nuevoArray[1][6] = parametro.altura;

    doc.autoTable({
        margin: { top: linea, left: 10 },
        styles: { fontSize: 9, cellPadding: 1, valign: 'middle', halign: 'center' },
        headStyles: { lineWidth: 0.1, minCellHeight: 9 },
        columnStyles: {
            0: { columnWidth: 15, halign: 'center', lineWidth: 0.1 },
            1: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
            2: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
            3: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
            4: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
            5: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
            6: { columnWidth: 19, halign: 'center', lineWidth: 0.1 },
        },
        theme: ['plain'],
        head: [['/', 'ESFERA', 'CILINDRO', 'EJE', 'ADICION', 'DNP/DIP', 'ALTURA']],
        body: nuevoArray
    })

    let finalY = doc.previousAutoTable.finalY
    linea = finalY + 5

    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(9)
    var texto = doc.splitTextToSize('ADD CERCA   : ' + receta.add, 50);
    doc.text(texto, lMargin, linea, 'left');
    if (receta.add_int != undefined) {
        var texto = doc.splitTextToSize('ADD INTERMEDIA   : ' + receta.add_int, 50);
        doc.text(texto, lMargin + 40, linea, 'left');
    }
    linea = linea + 5

    var texto = doc.splitTextToSize('PRODUCTO   : ' + cabecera.producto.nombre + ' IR:' + cabecera.indice_r, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (5 * texto.length)



    if (parametro != '') {


        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(9)
        var texto = doc.splitTextToSize('PARÁMETROS DE PERSONALIZACION   : ', 50);
        doc.text(texto, lMargin, linea, 'left');
        linea = linea + (5 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- DISTANCIA AL VERTICE : ', 30);
        doc.text(texto, 20, linea, 'center');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.d_verice, 30);
        doc.text(texto, 40, linea + 2, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- ANGULO PANTOSCOPICO : ', 30);
        doc.text(texto, 9, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.a_pantos, 30);
        doc.text(texto, 40, linea + 2, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- ANGULO PANORAMICO : ', 30);
        doc.text(texto, 9, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.a_panora, 30);
        doc.text(texto, 40, linea + 2, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- CORREDOR : ', 30);
        doc.text(texto, 9, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.corredor, 30);
        doc.text(texto, 40, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- REDUCCION : ', 30);
        doc.text(texto, 9, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.diametro, 80);
        doc.text(texto, 55, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + 9

        linea = linea - 55
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(9)
        var texto = doc.splitTextToSize('PARÁMETROS DE MONTURAS   : ', 40);
        doc.text(texto, lMargin + 80, linea, 'left');
        linea = linea + (5 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- HORIZONTAL : ', 30);
        doc.text(texto, 90, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.horizontal, 30);
        doc.text(texto, 120, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- VERTICAL : ', 30);
        doc.text(texto, 90, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.vertical, 30);
        doc.text(texto, 120, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- DIAGONAL : ', 30);
        doc.text(texto, 90, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.diagonal, 30);
        doc.text(texto, 120, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (9 * texto.length)

        doc.setFont('Helvetica', '');
        var texto = doc.splitTextToSize('- PUENTE : ', 30);
        doc.text(texto, 90, linea, 'left');
        doc.setFont('Helvetica', 'bold');
        var texto = doc.splitTextToSize(parametro.puente, 30);
        doc.text(texto, 120, linea, 'center');
        doc.setFont('Helvetica', '');
        linea = linea + (11 * texto.length)

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(8)
        doc.text(separacion, pageCenter, linea, 'center');
        linea = linea + 5

        doc.setFont('Helvetica', '');
        doc.setFontSize(9)

        var texto = doc.splitTextToSize('MODELO MONTURA   : ' + parametro.montura, (pdfInMM - lMargin - rMargin));
        doc.text(texto, lMargin, linea, 'left');
        linea = linea + (5 * texto.length)
    }
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(9)
    doc.text(separacion, pageCenter, linea, 'center');
    linea = linea + 5
    window.open(doc.output('bloburi'))
    //doc.save('PEDIDO N°' + data.id + '.pdf')
}