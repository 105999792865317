<template>
    <div id="app">
        <div class="video-container">
            <div v-if="loading" class="loader">Loading...</div>
            <video v-show="!loading" ref="video" class="video-rounded" autoplay muted></video>
            <canvas ref="canvas"></canvas>
            <svg width="0" height="0">
                <defs>
                    <clipPath id="maskPath" clipPathUnits="objectBoundingBox">
                        <path
                            d="M0.5,0 C0.75,0 1,0.25 1,0.5 C1,0.75 0.75,1 0.5,1 C0.25,1 0,0.75 0,0.5 C0,0.25 0.25,0 0.5,0 Z">
                        </path>
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
import * as faceapi from 'face-api.js';
import store from '@/store/index';

export default {
    props: {
        faceMatcher: null,
    },
    data() {
        return {
            video: null,
            labeledDescriptors: [],
            //  faceMatcher: null,
            loading: true,
            stream: null,
            // modelsLoaded: false,
            modelsLoaded: true,
        };
    },
    created() {


    },
    async mounted() {
        this.startVideo(); // Iniciar la cámara primero
        // this.loadModels(); // Cargar modelos en segundo plano
        //await this.loadLabeledDescriptors();
    },
    beforeDestroy() {
        this.stopVideo();
    },
    methods: {
        /*  async loadModels() {
              await faceapi.nets.tinyFaceDetector.loadFromUri('/models');
              await faceapi.nets.faceLandmark68Net.loadFromUri('/models');
              await faceapi.nets.faceRecognitionNet.loadFromUri('/models');
              this.modelsLoaded = true; // Indicar que los modelos se han cargado
          },*/
        /*async loadLabeledDescriptors() {
            const labeledImages = store.state.empleados.map(emp => emp);
            this.labeledDescriptors = await Promise.all(
                labeledImages.map(async (item) => {
                    const img = await faceapi.fetchImage(item.foto);
                    const detections = await faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
                    if (detections == undefined) {
                        alert('modificar foto ' + item.nombre)
                        return
                    }
                    console.log(item, detections)
                    return new faceapi.LabeledFaceDescriptors(item.id, [detections.descriptor]);
                })
            );
            console.log(this.labeledDescriptors)
            this.faceMatcher = new faceapi.FaceMatcher(this.labeledDescriptors);
        },*/
        async startVideo() {
            try {
                const video = this.$refs.video;
                this.stream = await navigator.mediaDevices.getUserMedia({ video: {} });
                video.srcObject = this.stream;
                video.addEventListener('loadeddata', () => {
                    this.loading = false;
                    this.onPlay();
                });
            } catch (err) {
                console.error(err);
            }
        },
        stopVideo() {
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
            }
        },
        async onPlay() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            const displaySize = {
                width: video.videoWidth,
                height: video.videoHeight
            };

            faceapi.matchDimensions(canvas, displaySize);

            setInterval(async () => {
                let detectedNames = [];
                if (!this.modelsLoaded) return; // Esperar hasta que los modelos se carguen

                const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.70 })).withFaceLandmarks().withFaceDescriptors();
                const resizedDetections = faceapi.resizeResults(detections, displaySize);

                const results = resizedDetections.map((d) => this.faceMatcher.findBestMatch(d.descriptor));
                console.log(results)
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                faceapi.draw.drawDetections(canvas, resizedDetections);
                faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

                results.forEach((result, i) => {
                    if (result.label != 'unknown') {
                        this.stopVideo();
                        this.modelsLoaded = false
                        detectedNames = result.label
                        //  this.$emit('nombre', result.label);
                    }
                    const box = resizedDetections[i].detection.box;
                    const drawBox = new faceapi.draw.DrawBox(box, {
                        label: result.toString()
                    });
                    drawBox.draw(canvas);
                });
                if (detectedNames.length > 0) {
                    this.$emit('nombre', detectedNames);
                }
            }, 500);
        }
    }
};
</script>

<style scoped>
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: calc(45vh - 90px);
    /* Subtract some space for the header */
    overflow: hidden;
}

video {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    clip-path: url(#maskPath);
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loader {
    font-size: 2em;
    color: #000;
}
</style>
