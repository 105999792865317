<template>
    <div class="pa-4">
        <v-card>

            <div class="d-flex align-content-start flex-wrap mb-n6">
                <v-row class="mt-n3 mb-1 pa-2">
                    <v-col cols="4">
                        <v-card @click.prevent="abre_nuevo()">
                            <v-container>
                                <v-img class="mx-auto mt-n2" height="30" width="30" src="/proforma.png"></v-img>
                                <h6 block class="text-center mb-n2">NUEVA PROFORMA</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field outlined dense v-model="busca_p" label="Busca"
                            append-icon="mdi-magnify"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date2" label="Desde"></v-text-field>
                    </v-col>
                </v-row>
            </div>

            <v-card>
                <v-simple-table dense fixed-header height="70vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Cliente
                                </th>
                                <th class="text-left">
                                    Vendedor
                                </th>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id">
                                <td style="font-size:85%;">{{ item.cliente_dni }} - {{ item.cliente_nombre }}</td>
                                <td style="font-size:85%;">{{ item.vendedor }}</td>
                                <td style="font-size:85%;">{{ conviertefecha2(item.fecha) }}
                                    <v-icon color="#FFB200" v-if="item.estado == 'PENDIENTE'">mdi-circle</v-icon>
                                    <v-icon color="#46FF00" v-if="item.estado == 'ACEPTADO'">mdi-circle</v-icon>
                                </td>
                                <td style="font-size:85%;">S/{{ item.total }}</td>
                                <td style="font-size:85%;">
                                    <v-row dense>
                                        <v-col cols="4">
                                            <v-icon color="green" @click.prevent="abre_proforma(item)">mdi-pencil</v-icon>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-icon color="red" @click.prevent="pasa_caja(item)">mdi-cash-register</v-icon>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-icon color="orange"
                                                @click.prevent="abre_comparte(item)">mdi-share-variant-outline</v-icon>
                                        </v-col>
                                    </v-row>

                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>
        </v-card>
        <v-dialog v-model="dial_comparte" max-width="550">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_comparte = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-radio-group class="mt-n3 mb-n4" v-model="medida_proforma" row dense>
                    <v-radio label="A4" value="A4"></v-radio>
                    <v-radio label="80mm" value="80"></v-radio>
                </v-radio-group>
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="envia_w()">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/what.png"></v-img>
                                <h5 block class="text-center pa-1">WHATSAPP</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="imprime_pdf(item_selecto)">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/pdf.png"></v-img>
                                <h5 block class="text-center pa-1">DESCARGA</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial" max-width="1050px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="" small @click="dial_serie = true">{{ serie }} Serie</v-btn>
                    <v-spacer></v-spacer>
                    <v-icon color="success" large @click="guarda_proforma()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3" style="overflow-y: scroll;overflow-x: hidden; max-height:70vh">
                <v-card class="mt-n2 pa-2">
                    <v-row class="mt-n2">
                        <v-col cols="3">
                            <v-select disabled style="font-size:85%;" outlined dense v-model="documento" :items="documentos"
                                menu-props="auto" hide-details label="Tipo Doc"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field style="font-size:85%;" outlined type="number" dense v-model="numero"
                                label="Numero" append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                                @keyup.enter="BuscarDocumento()"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field style="font-size:85%;" outlined dense v-model="nombreCompleto" label="Nombre"
                                append-icon="mdi-eye" @click:append="abre_cliente()"></v-text-field>
                        </v-col>

                    </v-row>

                    <v-row class="mt-n9 mb-n8">
                        <v-col cols="3">
                            <v-select style="font-size:75%;" :items="$store.state.array_empleados" label="VENDEDOR" dense
                                outlined v-model="vendedor"></v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field style="font-size:85%;" outlined dense v-model="telefono" type="number"
                                label="Telefono"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-textarea style="font-size:85%;" outlined dense v-model="observacion" auto-grow filled
                                label="Comentario" rows="1"></v-textarea>
                        </v-col>
                    </v-row>
                </v-card>

                <v-row class="mt-1" dense>
                    <v-col cols="3">
                        <v-row class="mt-1" dense>
                            <v-col cols="12">
                                <v-card @click.prevent="dial_monturas = true">
                                    <v-container>
                                        <v-img class="mx-auto mt-n2" height="30" width="30" src="/monturas.png"></v-img>
                                        <h6 block class="text-center mb-n2">MONTURAS</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card @click.prevent="dial_productos = true">
                                    <v-container>
                                        <v-img class="mx-auto mt-n2" height="30" width="30" src="/producto.png"></v-img>
                                        <h6 block class="text-center mb-n2">PRODUCTOS</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <v-text-field type="date" outlined dense v-model="date_vence"
                                    label="Vencimiento"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="mt-n6">
                                <v-card class="pa-3">
                                    <h4 class="red--text text-center">TOTAL : S/.{{ total }}</h4>
                                </v-card>
                            </v-col>
                            <v-col cols="12">
                                <v-card @click.prevent="guarda_proforma()">
                                    <v-container>
                                        <v-img class="mx-auto mt-n2" height="30" width="30" src="/save.png"></v-img>
                                        <h6 block class="text-center mb-n2">GUARDAR</h6>
                                    </v-container>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="9">
                        <v-card>
                            <v-simple-table dense fixed-header height="290px" class="mt-2 ">
                                <template v-slot:default>

                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Descripcion
                                            </th>
                                            <th class="text-left">
                                                Precio
                                            </th>
                                            <th class="text-left">
                                                Cantidad
                                            </th>
                                            <th class="text-left">
                                                Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in listaproductos" :key="item.uuid"
                                            @click.prevent="editaProducto(item)">
                                            <td style="font-size:85%;" v-if="item.operacion == 'GRATUITA'">{{ item.nombre }}
                                                -
                                                <strong class="red--text">OBSEQUIO</strong>
                                                <strong class="red--text" v-if="item.od && !item.oi"> - OD</strong>
                                                <strong class="red--text" v-if="item.oi && !item.od"> - OI</strong>
                                            </td>
                                            <td style="font-size:85%;" v-else>{{ item.nombre }}
                                                <strong class="red--text" v-if="item.od && !item.oi"> - OD</strong>
                                                <strong class="red--text" v-if="item.oi && !item.od"> - OI</strong>
                                            </td>
                                            <td style="font-size:85%;">S/.{{ item.precio }}</td>
                                            <td style="font-size:85%;">{{ item.cantidad }}</td>
                                            <td style="font-size:85%;">S/{{ item.total }}</td>
                                        </tr>
                                    </tbody>

                                </template>

                            </v-simple-table>
                        </v-card>
                    </v-col>

                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialo_edita" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_edita = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto mt-4 text-center" dense v-if="false">
                    <v-col cols="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field dense @keyup.enter="grabaEdita()" type="number" outlined v-model="cantidadEdita"
                            label="Cantidad"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>
                </v-row>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12">
                        <v-text-field disabled dense @keyup.enter="grabaEdita()" type="number" class="pa-3"
                            v-model="precioedita" label="Precio"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n6">
                    <v-btn color="red darken-1" text @click="eliminaedita()">
                        Elimina
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_serie" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_serie = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12">
                        <v-select style="font-size:75%;" :items="array_series" label="SERIE" dense outlined
                            v-model="serie"></v-select>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n6">
                    <v-btn color="success" block @click="dial_serie = false">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <lista_monturas v-if="dial_monturas" @array="agrega_monturas($event)" @cierra="dial_monturas = false" />
        <lista_productos v-if="dial_productos" :dial="dial_productos" @array="agrega_productos($event)"
            @cierra="dial_productos = false" :edicion="true" :serie="serie" />
        <nuevo_cli v-if="dial_paciente" :num_cliente="numero" :dial_cliente="dial_paciente" @cierra="dial_paciente = $event"
            @cliente="dial_paciente = false" />
        <clientes :dial_L="dial_clientes" @array="agregacliente($event)" @cierra="dial_clientes = false" />
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    buscaCliente,
    allProformas,
    nuevoProformas,
    editaProformas
} from '../../db'
import lista_monturas from '@/components/almacen/lista_monturas'
import lista_productos from '@/components/almacen/lista_productos'
import axios from "axios"
import nuevo_cli from '@/views/optometria/busca_cliente'
import clientes from '@/components/dialogos/dialogoClientes'
import {
    generaproforma
} from '../../pdf_proforma'
export default {
    components: {
        lista_monturas,
        lista_productos,
        nuevo_cli,
        clientes
    },
    data() {
        return {
            dial_comparte: false,
            busca_p: '',
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: [],
            lista_venta: [],
            dial: false,
            dial_monturas: false,
            dial_productos: false,
            dial_paciente: false,
            dial_clientes: false,
            dialo_edita: false,
            documento: 'DNI',
            documentos: [
                'DNI',
                'RUC',
                'Pasaporte',
                'Carnet de Extranjeria'
            ],
            numero: '',
            nombreCompleto: '',
            telefono: '',
            observacion: '',
            total: 0,
            codigoedita: '',
            cantidadEdita: '',
            precioedita: '',
            item_edita: '',
            vendedor: '',
            edita_proforma: false,
            item_selecto: [],
            date_vence: moment(String(new Date)).format('YYYY-MM-DD'),
            medida_proforma: '80',
            dial_serie: false,
            serie: '1ERA',
            array_series: ['1ERA', '2DA', '3ERA', 'FABRICACION']
        }
    },
    created() {
        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
    },

    computed: {
        listaproductos() {
            var suma = 0
            for (var i = 0; i < this.lista_venta.length; i++) {
                suma = suma + (this.lista_venta[i].cantidad * this.lista_venta[i].precio)
            }
            this.total = suma
            return this.lista_venta
        },
        listafiltrada() {
            allProformas().orderByChild('fecha')
                .startAt(moment(String(this.date1)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);

            return this.desserts.filter(item => (item.cliente_dni + item.cliente_nombre)
                .toLowerCase().includes(this.busca_p.toLowerCase())).reverse()
        },
    },
    mounted() {
        allProformas().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    destroyed() {
        allProformas().off("value");
    },
    methods: {
        envia_w() {

            var data = this.item_selecto
            console.log(data)
            if (data.cliente_telf == '') {
                alert('NO HA INGRESADO TELEFONO DE CLIENTE')
                return
            }
            var message = 'Descargue su proforma en el siguiente link \n' +
                'https://opticentervision.pe/visualiza_proforma/' + store.state.baseDatos.bd + '/' + data.id
            if (store.state.esmovil) {
                var url = "whatsapp://send?text=" + encodeURIComponent(message) + "&phone=" + encodeURIComponent('+51' + data.cliente_telf)
            } else {
                var url = "https://web.whatsapp.com/send?text=" + encodeURIComponent(message) + "&phone=" + encodeURIComponent('+51' + data.cliente_telf)
            }
            window.open(url);

            this.dial_comparte = false
        },
        abre_comparte(data) {
            console.log(data)
            this.item_selecto = data
            this.dial_comparte = true
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                data.id = item.key
                if (data.estado == 'PENDIENTE') {
                    data.color = 'orange'
                }
                if (data.estado == 'ANULADO') {
                    data.color = 'red'
                }
                if (data.estado == 'ACEPTADO') {
                    data.color = 'green'
                }
                array.push(data);
            });
            this.desserts = array;
        },
        abre_nuevo() {
            this.item_selecto = ''
            this.edita_proforma = false
            this.numero = ''
            this.nombreCompleto = ''
            this.telefono = ''
            this.observacion = ''
            this.lista_venta = []
            this.vendedor = ''
            this.date_vence = moment(String(new Date)).add(7, 'd').format('YYYY-MM-DD')
            this.dial = true
        },
        abre_cliente() {
            this.dial_paciente = true
        },
        conviertefecha2(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        editaProducto(id) {
            var i = this.listaproductos.map(e => e.uuid).indexOf(id.uuid)
            this.codigoedita = i
            this.cantidadEdita = this.listaproductos[i].cantidad
            this.precioedita = this.listaproductos[i].precio
            this.item_edita = this.listaproductos[i]
            this.dialo_edita = true
        },
        grabaEdita() {
            this.listaproductos[this.codigoedita].precio = this.precioedita
            this.listaproductos[this.codigoedita].total = this.precioedita * this.cantidadEdita
            this.dialo_edita = false
        },
        eliminaedita() {
            this.listaproductos.splice(this.codigoedita, 1)
            this.dialo_edita = false
        },
        async guarda_proforma() {
            if (this.numero == '' || this.nombreCompleto == '' || this.listaproductos == '') {
                alert('COMPLETE DATOS')
            } else {
                store.commit("dialogoprogress", 1)
                var array = {
                    estado: 'PENDIENTE',
                    cliente_doc: this.documento,
                    cliente_dni: this.numero,
                    cliente_nombre: this.nombreCompleto,
                    cliente_telf: this.telefono,
                    observacion: this.observacion,
                    total: this.total,
                    data: this.listaproductos,
                    date_vence: moment(String(this.date_vence)) / 1000,
                    fecha: moment().unix(),
                    vendedor: this.vendedor
                }
                if (this.edita_proforma) {
                    array.id = this.item_selecto.id
                    await editaProformas(array.id, array)
                    this.edita_proforma = false
                } else {
                    await nuevoProformas(array)
                }
                // this.imprime_pdf(array)
                this.dial = false
                store.commit("dialogoprogress", 1)
            }
        },
        imprime_pdf(item) {
            generaproforma(item, this.medida_proforma)
            this.dial_comparte = false
        },
        BuscarDocumento() {
            if (this.numero.length == 11) {
                this.documento = "RUC"
            } else {
                this.documento = "DNI"
            }
            if (this.numero != '' && this.documento == "RUC" || this.documento == "DNI" &&
                this.numero.length == 8 || this.numero.length == 11) {
                store.commit("dialogoprogress", 1)

                buscaCliente(this.numero)
                    .once("value")
                    .then((snapshot) => {
                        if (snapshot.exists()) {
                            this.nombreCompleto = snapshot.val().nombre
                            this.direccion = snapshot.val().direccion
                            this.telefono = snapshot.val().telefono
                            store.commit("dialogoprogress", 1)
                        } else {
                            this.consultaApiPeru()
                        }
                    })
            } else {
                this.dial_clientes = true
            }
        },
        consultaApiPeru() {
            var self = this,
                token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
            axios
                .get('https://apiperu.dev/api/' + this.documento.toLowerCase() + '/' + this.numero, {
                    headers: {
                        Content_Type: 'application/json',
                        authorization: ' Bearer ' + token
                    }
                })
                .then(response => (this.info = response.data,
                    store.commit("dialogoprogress", 1),
                    self.llenardatos(response.data.data)
                ))
                .catch(error => {
                    store.commit("dialogoprogress", 1),
                        store.commit('dialogosnackbar', 'Documento Invalido')
                })
        },
        llenardatos(data) {
            if (this.documento == 'DNI') {
                this.nombreCompleto = data.nombre_completo
                this.direccion = ''
                this.telfcliente = ''
                this.refcliente = ''
            }
            if (this.documento == 'RUC') {
                this.nombreCompleto = data.nombre_o_razon_social
                this.direccion = data.direccion_completa
                this.telfcliente = ''
                this.refcliente = ''
            }
        },
        agregacliente(item) {
            this.numero = item.documento
            this.nombreCompleto = item.nombre
            this.direccion = item.direccion
            this.documento = item.tipodoc
            this.telefono = item.telefono
            this.dial_clientes = false
        },
        agrega_monturas(item) {
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                nombre: item.marca + ' ' + item.modelo,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                tipo: 'M'
            })
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
        },
        agrega_productos(item) {
            store.commit("dialogoprogress", 1)
            this.lista_venta.push({
                uuid: this.create_UUID().substring(29),
                id: item.id,
                nombre: item.marca + ' ' + item.descripcion,
                cantidad: item.cantidad,
                precio: item.precio,
                total: item.precio * item.cantidad,
                operacion: item.operacion,
                tipo_focal: item.tipo_focal,
                tipoproducto: item.tipoproducto,
                medida: 'UNIDAD',
                od: item.od,
                oi: item.oi,
                tipo: 'P',
                dias_entrega: item.dias_entrega
            })
            setTimeout(() => store.commit("dialogoprogress", 1), 500);
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        abre_proforma(item) {
            this.item_selecto = item
            this.edita_proforma = true
            this.documento = item.cliente_doc
            this.numero = item.cliente_dni
            this.nombreCompleto = item.cliente_nombre
            this.telefono = item.cliente_telf
            this.observacion = item.observacion
            this.lista_venta = item.data
            this.vendedor = item.vendedor
            this.date_vence = moment.unix(item.date_vence).format('YYYY-MM-DD')
            this.dial = true
        },
        pasa_caja(item) {
            if (confirm("DESEA PASAR A COBRAR ESTA PROFORMA?")) {
                store.commit('array_proforma', item)
                this.$router.push({
                    name: 'ventas'
                })
            }
        }

    },

}
</script>
