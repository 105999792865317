<template>
    <div class="pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n6">
                <v-row class="mt-n3 mb-1 pa-2">

                    <v-col cols="4">
                        <h4>RECEPCION DE MONTURAS</h4>
                        <v-card v-if="false" elevation="12" class="mb-3" color="white" @click="dial_pendiente = true">
                            <v-container class="mb-n12 ml-1">
                                <v-badge :content="num_pendientes" :value="num_pendientes" color="green" overlap
                                    class="ml-10">
                                </v-badge>
                            </v-container>
                            <v-container>
                                <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/time.png"></v-img>
                                <p class="text-center mb-n2 mt-2">Pendientes</p>
                            </v-container>

                        </v-card>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date2" label="Desde"></v-text-field>
                    </v-col>
                </v-row>
            </div>

            <v-card>
                <v-simple-table dense fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Enviados
                                </th>
                                <th class="text-left">
                                    Faltantes
                                </th>
                                <th class="text-left">
                                    Observacion
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.origen }} --> {{ item.destino }}</td>
                                <td style="font-size:85%;">{{ item.cantidad }}</td>
                                <td style="font-size:85%;" class="red--text text-left">{{ item.faltas
                                }}</td>
                                <td style="font-size:85%;">{{ item.observacion }}</td>
                                <td width="100">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-icon color="green" @click.prevent="abrir_lista_(item)">mdi-pencil</v-icon>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>

        </v-card>

        <v-dialog v-model="dial_pendiente" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial_pendiente = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h3>Seleccionar : </h3>
                <v-simple-table dark dense fixed-header height="52vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Observacion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.uuid" @click="abrir_lista_(item)">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.origen }} --> {{ item.destino }}</td>
                                <td style="font-size:85%;">{{ item.cantidad }}</td>
                                <td style="font-size:85%;">{{ item.observacion }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-icon v-if="true" color="success" large @click="guarda_final()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mt-n2" dense>
                    <v-col cols="6">
                        <v-select disabled :items="array_origen" label="ORIGEN" dense outlined v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="DESTINO" dense outlined
                            v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n6" dense>
                    <v-col cols="12">
                        <v-text-field prefix="M" outlined dense rounded id="cod" v-model="codigo" label="Codigo"
                            append-icon="mdi-barcode-scan" @click:append="buscaProducto()" @keyup.enter="buscaProducto()"
                            :autofocus="!$store.state.esmovil"></v-text-field>
                    </v-col>
                </v-row>

                <v-simple-table dark dense fixed-header height="52vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    MONTURA
                                </th>
                                <th class="text-left">
                                    MODELO
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in lista_monturas" :key="item.uuid">
                                <td>{{ item.id }}-{{ item.marca }}</td>
                                <td>{{ item.modelo }}</td>
                                <td>
                                    <v-icon color="red" v-if="item.estado == 'PENDIENTE'">mdi-help</v-icon>
                                    <v-icon color="green" v-if="item.estado != 'PENDIENTE'">mdi-check</v-icon>
                                </td>
                            </tr>
                        </tbody>

                    </template>

                </v-simple-table>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    allMonturas,
    editaMontura,
    nueva_envios,
    all_envios,
    edita_envios
} from '../../db'
export default {

    data() {
        return {
            dial_pendiente: false,
            array_monturas: [],
            dial: false,
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: [],
            lista_monturas: [],
            codigo: '',
            origen: 'almacen',
            array_origen: ['almacen'],
            destino: '',
            obs: '',
            item_selecto: [],
            num_pendientes: 0,
            array_enviados: [],
            tienda_activa: store.state.permisos.tienda,
        }
    },
    created() {
        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment(String(new Date)).format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
    },
    mounted() {
        all_envios().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    destroyed() {
        all_envios().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_envios().orderByChild('fecha')
                .startAt(moment(String(this.date1)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            if (store.state.permisos.master) {
                return this.desserts
            } else {
                return this.desserts.filter(item => item.origen == this.tienda_activa || item.destino == this.tienda_activa)
            }
        },
        lista_repecion() {
            return true
        }
    },
    methods: {
        async guarda_final() {
            store.commit("dialogoprogress", 1)
            var cant = this.lista_monturas.filter(item => item.estado == 'PENDIENTE')
            console.log(cant)
            edita_envios(this.item_selecto.id + '/faltas', cant.length)
            this.dial = false
            store.commit("dialogoprogress", 1)
        },
        abrir_lista_(data) {
            console.log(data)
            this.item_selecto = data
            this.lista_monturas = data.data
            this.origen = data.origen
            this.destino = data.destino
            this.dial_pendiente = false
            this.dial = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.estado == 'PENDIENTE') {
                    array.push(data);
                }
            });
            array.sort(function (a, b) {
                if (a.fecha < b.fecha) {
                    return 1;
                }
                if (a.fecha > b.fecha) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            this.num_pendientes = array.length
            this.desserts = array;
        },

        async buscaProducto() {
            if (this.codigo[0] == 'M') {
                var cod = this.codigo
            } else {
                var cod = 'M' + this.codigo.toString().padStart(6, 0)
            }
            if (this.lista_monturas.find(id => id.id == cod).estado == 'ACEPTADO') {
                alert('PRODUCTO YA RECEPCIONADO')
                this.codigo = ''
                return
            }
            if (this.lista_monturas.find(id => id.id == cod)) {
                store.commit("dialogoprogress", 1)
                var a = this.lista_monturas.find(id => id.id == cod)
                var pos = this.lista_monturas.map(e => e.id).indexOf(cod)
                a.estado = 'ACEPTADO'
                this.lista_monturas[pos] = a
                console.log(cod + '/ubicacion', this.destino)
                await editaMontura(cod + '/ubicacion', this.destino)
                await edita_envios(this.item_selecto.id + '/data', this.lista_monturas)
                store.commit("dialogoprogress", 1)
            } else {
                alert('PRODUCTO NO EXISTE')
            }
            this.codigo = ''
        },

    },

}
</script>
