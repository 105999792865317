<template>
<v-container grid-list-xs class="mt-6">
    <v-row>
        <v-col cols="6">
            <v-btn block color="success" @click="crearNuevoCampo()">crear Campo</v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn color="success" block @click="mensajeactualiza()">Actualiza sesiones</v-btn>
        </v-col>
    </v-row>
    <v-text-field v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
    <v-data-table :headers="headers" :items="desserts" sort-by="calories" class="elevation-1" :search="buscar">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>EMPRESAS</v-toolbar-title>
                {{empresas}}
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="1000px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            Nueva empresa
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.name" label="Empresa"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.namecomercial" label="Nombre Comercial"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.ruc" type="number" append-icon="mdi-magnify" @click:append="BuscarDocumento()" @keyup.enter="BuscarDocumento()" label="Ruc"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.departamento" label="Departamento"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.provincia" label="Provincia"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.distrito" label="Distrito"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.direccion" label="Direccion"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.ubigeo" label="UBIGEO"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.anexo" label="ANEXO (0000)"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.usuario" label="Usuario"></v-text-field>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.clave" label="Clave"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.usuariosol" label="USUARIO SOL"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.clavesol" label="CLAVE SOL"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.nombrefirma" label="Nombre firma"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.passfirma" label="PASS FIRMA"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.PersonaID" label="PersonaID"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.Token" label="Token"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="editedItem.bd" label="BD"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-checkbox label="Modulo Restaurante" v-model="editedItem.restaurante"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-checkbox label="PRUEBA" v-model="editedItem.pruebas"></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-checkbox label="Caja V2" v-model="editedItem.caja2"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="irA(item)" color="green">
                mdi-arrow-right-circle
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
    </v-data-table>

</v-container>
</template>

<script>
import {
    allEmpresas,
    nuevaEmpresa,
    eliminaEmpresa,
    actualizaEmpresa,
    crearCampo,
    allProductoOtraBase,
    nuevoUsuario,
    allCategorias,
    crearCampoconfiguracion,
    crearCampoCATEGORIAS,
    grabaactualizadialogo,
    allComprobantesCabeceraOtraBase,
    nuevoComprobantesCabeceraSerie
} from '../db'
import axios from "axios"
import store from '@/store/index'
export default {
    data: () => ({
        buscar: '',
        empresas: '',
        dialog: false,
        dialogDelete: false,
        headers: [{
                text: 'RUC',
                value: 'ruc'
            },
            {
                text: 'Nombre Empresa',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            {
                text: 'USUARIO',
                value: 'usuario'
            },
            {
                text: 'modo',
                value: 'pruebas'
            },
            {
                text: 'BD',
                value: 'bd'
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
        ],
        desserts: [],
        editedIndex: -1,
        editedItem: {
            name: '',
            namecomercial: '',
            ruc: '',
            departamento: '',
            provincia: '',
            distrito: '',
            direccion: '',
            ubigeo: '',
            usuario: '',
            anexo: '0000',
            clave: 'admin1234',
            usuariosol: 'DOMOTICA',
            clavesol: 'Domotica1234',
            nombrefirma: '.p12',
            passfirma: 'Domotica1234',
            PersonaID: '',
            Token: '',
            bd: '',
            restaurante: false,
            pruebas: false,
            caja2: false
        },
        defaultItem: {
            name: '',
            namecomercial: '',
            ruc: '',
            departamento: '',
            provincia: '',
            distrito: '',
            direccion: '',
            ubigeo: '',
            anexo: '',
            usuario: '',
            clave: '',
            usuariosol: '',
            clavesol: '',
            nombrefirma: '',
            passfirma: '',
            PersonaID: '',
            Token: '',
            bd: '',
            restaurante: false,
            pruebas: false,
            caja2: false
        },
    }),
    mounted() {
        allEmpresas().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allEmpresas().off("value", this.onDataChange);
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        onDataChange(items) {
            let array = [];

            items.forEach((item) => {
                let key = item.key;
                let data = item.val();
                array.push({
                    key: key,
                    name: data.name,
                    namecomercial: data.namecomercial,
                    ruc: data.ruc,
                    departamento: data.departamento,
                    provincia: data.provincia,
                    distrito: data.distrito,
                    direccion: data.direccion,
                    ubigeo: data.ubigeo,
                    anexo: data.anexo,
                    usuario: data.usuario,
                    clave: data.clave,
                    usuariosol: data.usuariosol,
                    clavesol: data.clavesol,
                    nombrefirma: data.nombrefirma,
                    passfirma: data.passfirma,
                    PersonaID: data.PersonaID,
                    Token: data.Token,
                    bd: data.bd,
                    restaurante: data.restaurante,
                    pruebas: data.pruebas,
                    caja2: data.caja2

                });
            });

            this.desserts = array;
        },

        initialize() {
            this.desserts = [{
                name: 'Domotica',
                ruc: '10477666715',
                direccion: 'Urb. San andres v etapa mz d lt 17',
                usuario: 'ivanac1992@domotica.com',
                clave: '12345678',
                bd: 'BASE1'
            }]
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            eliminaEmpresa(this.editedItem.name)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                //Object.assign(this.desserts[this.editedIndex], this.editedItem)
                actualizaEmpresa(this.editedItem.bd, this.editedItem)
            } else {
                nuevaEmpresa(this.editedItem.bd, this.editedItem)
                this.crearUsuario()
            }
            this.close()
        },

        crearNuevoCampo() {
            //  crearCampoconfiguracion('BD1','contadores','orden_cierre','0001')
            for (var i = 0; i < this.desserts.length; i++) {
                //  console.log(i)   
                console.log(this.desserts[i].bd)
                //crearCampoconfiguracion(this.desserts[i].bd,'contadores','orden_cierre','0001')
                //crearCampoconfiguracion(this.desserts[i].bd,'configuracion','cargoxconsumo',0)
                // crearCampoconfiguracion(this.desserts[i].bd,'impresora','refcliente',false)
                // this.categorias(this.desserts[i].bd)
                ///crearCampoconfiguracion(this.desserts[i].bd,'configuracion','monitorbarra',true)
                // this.actualizacomprobante(this.desserts[i].bd)
                // this.creacampo2(this.desserts[i].bd)
                //crearCampoconfiguracion(this.desserts[i].bd,'configuracion','controlplatos',false)     
            }

            // this.creacampo2('BD5')
        },
        /* categorias(bd){
           allCategorias(bd).once("value").then((snapshot) => {
           snapshot.forEach((item) => {
               console.log(item.val().id)
               crearCampoCATEGORIAS(bd,'categorias',item.val().id,'icono','')
            })
           })   
         },*/
        creacampo2(bd) {
            allProductoOtraBase(bd).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    console.log(item.val().id)
                    // crearCampo(bd,item.val().id,"cargoxconsumo",false)
                    //crearCampo(bd,item.val().id,"papartirde",0) //solo crear campos de productos todas las empresas       
                })
            })
        },
        actualizacomprobante(bd) {
            /*  allComprobantesCabeceraOtraBase(bd).once("value").then((snapshot) => {
              snapshot.forEach((item) => {
                  console.log(item.val().numeracion.charAt(0))   
                  if(item.val().numeracion.charAt(0)=='T'){
                    var serie = 'T001'
                  }
                  if(item.val().numeracion.charAt(0)=='F'){
                    var serie = 'F001'
                  }
                  if(item.val().numeracion.charAt(0)=='B'){
                    var serie = 'B001'
                  }
                  this.creaserie(bd,item.val().numeracion,serie)

              })        
            })    */
        },
        creaserie(bd, id, serie) {
            //    nuevoComprobantesCabeceraSerie(bd,id,serie)
        },
        crearUsuario() {

            var token = this.create_UUID()
            var array = {
                token: token,
                nombre: this.editedItem.namecomercial,
                correo: this.editedItem.usuario,
                pass: this.editedItem.clave,
                bd: this.editedItem.bd,
                moduloempresa: true,
                modulocaja: true,
                moduloproductos: true,
                modulomesas: true,
                modulococina: true,
                modulocomprobantes: true,
                modulosunat: true,
                moduloreportes: true,
                moduloclientes: true
            }
            nuevoUsuario(token, array)
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid.substring(20);
        },
        irA(item) {
            store.commit("empresaselecta", item)
            this.$router.push({
                path: "/gestionEmpresas/" + item.bd
            })
        },
        mensajeactualiza() {
            for (var i = 0; i < this.desserts.length; i++) {
                grabaactualizadialogo(this.desserts[i].bd, true, "dialogoactualiza")
            }
        },
        BuscarDocumento() {
            if (this.editedItem.ruc.length == 11) {
                store.commit("dialogoprogress")
                var self = this,
                    token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d'
                axios
                    .get('https://apiperu.dev/api/ruc/' + this.editedItem.ruc, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: ' Bearer ' + token
                        }
                    })
                    .then(response => (this.info = response.data,
                        self.llenardatos(response.data.data),
                        store.commit("dialogoprogress")
                    ))
                    .catch(error => {
                        store.commit("dialogoprogress"),
                            store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
                    })
            } else {
                store.commit('dialogosnackbar', "DOCUMENTO INVALIDO")
            }
        },
        llenardatos(data) {
            //console.log(data)
            this.editedItem.name = data.nombre_o_razon_social
            this.editedItem.direccion = data.direccion
            this.editedItem.departamento = data.departamento
            this.editedItem.provincia = data.provincia
            this.editedItem.distrito = data.distrito
            this.editedItem.ubigeo = data.ubigeo_sunat

        },
    },
}
</script>
