<template>
<v-container grid-list-xs class="mb-6">
    <v-card>
        <v-card-subtitle>
            <v-row no-gutters>
                <v-col cols="12">
                    <span class="black--text"> EMPRESA:</span> <span> {{ $store.state.baseDatos.name }}</span>
                </v-col>
                <v-col cols="12">
                    <span class="black--text">RUC:</span> <span> {{ $store.state.baseDatos.ruc }}</span>
                </v-col>
                <v-col cols="12">
                    <span class="black--text">DIRECCION:</span> <span> {{ $store.state.baseDatos.direccion }} -
                        {{ $store.state.baseDatos.distrito }} - {{ $store.state.baseDatos.provincia }} -
                        {{ $store.state.baseDatos.departamento }}</span>
                </v-col>
            </v-row>
        </v-card-subtitle>
    </v-card>

    <v-row no-gutters class="mt-2">
        <v-col cols="6" class="pa-1">
            <v-btn small block elevation="12" rounded @click="activa_logo = true" color="primary">
                Logo Empresa
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small block elevation="12" rounded @click="$store.commit('dialogomedidas')" color="primary">
                Und de Medida
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small block elevation="12" rounded class="mt-3" @click="dial_modo_pagos = true" color="primary">
                Modo Pagos
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_contador = true" color="primary">
                Contadores
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_series = true" color="primary">
                Series Doc.
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_configuracion = true" color="primary">
                Configuracion
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_impresoras = true" color="primary">
                Impresoras
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="gestionUsuarios()" color="primary">
                Usuarios
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="ir_tiendas()" color="primary">
                Tiendas
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_proveedor = true" color="primary">
                Proveedores
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_laboratorio = true" color="primary">
                Laboratorios
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_vendedores = true" color="primary">
                Vendedores
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_especialistas = true" color="primary">
                Especialistas
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block @click="activa_tecnicos = true" color="primary">
                TECNICOS
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block color="primary" @click="activa_colores = true">
                Colores
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block color="primary" @click="activa_productos = true">
                MARCAS
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block color="primary" @click="ir_excel()">
                <v-icon dark left>
                    mdi-microsoft-excel
                </v-icon> Subir Data
            </v-btn>
        </v-col>
        <v-col cols="6" class="pa-1">
            <v-btn small elevation="12" rounded class="mt-3" block color="primary" @click="dial_ip=true">
                <v-icon dark left>
                    mdi-microsoft-excel
                </v-icon> Lista IP
            </v-btn>
        </v-col>
    </v-row>
    <undMedida />
    <Configuracion v-if="activa_configuracion" @cierra="activa_configuracion = false" />
    <logoEmpresa v-if="activa_logo" @cierra="activa_logo = false" />
    <Impresoras v-if="activa_impresoras" @cierra="activa_impresoras = false" />
    <serieDocumentos v-if="activa_series" @cierra="activa_series = false" />
    <mdopago v-if="dial_modo_pagos" @cierra="dial_modo_pagos = false" />
    <t_proveedor v-if="activa_proveedor" :edicion="true" @cierra="activa_proveedor = false" />
    <t_laboratorio :dial_L="activa_laboratorio" :edicion="true" @cierra="activa_laboratorio = false" />
    <t_vendedores :dial_L="activa_vendedores" :edicion="true" @cierra="activa_vendedores = false" />
    <t_especialistas v-if="activa_especialistas" :edicion="true" @cierra="activa_especialistas = false" />
    <t_tecnicos v-if="activa_tecnicos" :edicion="true" @cierra="activa_tecnicos = false" />
    <t_colores :dial="activa_colores" :edicion="true" @cierra="activa_colores = false" />
    <t_productos :filtro="false" :dial_L="activa_productos" :edicion="true" @cierra="activa_productos = false" />
    <contadores v-if="activa_contador" @cierra="activa_contador = false" />
     <tabla_ip v-if="dial_ip" @cierra="dial_ip = false" />
</v-container>
</template>

<script>
import undMedida from '@/components/configEmpresa/undMedida'
import contadores from '@/components/configEmpresa/contadores'
import Configuracion from '@/components/configEmpresa/configuracion'
import logoEmpresa from '@/components/configEmpresa/logoEmpresa'
import Impresoras from '@/components/configEmpresa/impresoras'
import serieDocumentos from '@/components/configEmpresa/serieDocumentos'
import mdopago from '@/components/configEmpresa/mdopago'
import t_proveedor from '@/components/configEmpresa/tabla_proveedor'
import t_laboratorio from '@/components/configEmpresa/tabla_laboratorio'
import t_vendedores from '@/components/configEmpresa/tabla_vendedores'
import t_colores from '@/components/configEmpresa/tabla_colores'
import t_productos from '@/components/configEmpresa/tabla_productos'
import t_especialistas from '@/components/configEmpresa/tabla_especialistas'
import t_tecnicos from '@/components/configEmpresa/tabla_tecnicos'
import tabla_ip from '@/components/configEmpresa/tabla_ip'
export default {
    name: 'empresa',

    components: {
        tabla_ip,
        undMedida,
        contadores,
        Configuracion,
        logoEmpresa,
        Impresoras,
        serieDocumentos,
        mdopago,
        t_proveedor,
        t_laboratorio,
        t_vendedores,
        t_colores,
        t_productos,
        t_especialistas,
        t_tecnicos

    },

    data() {
        return {
            dial_ip:false,
            activa_logo: false,
            activa_configuracion: false,
            activa_impresoras: false,
            activa_proveedor: false,
            activa_laboratorio: false,
            activa_vendedores: false,
            activa_colores: false,
            activa_contador: false,
            activa_productos: false,
            activa_especialistas: false,
            activa_tecnicos: false,
            dial_modo_pagos: false,
            activa_series: false,
        }
    },
    methods: {
        gestionUsuarios() {
            this.$router.push({
                name: "gestionUsuarios"
            })
        },
        ir_tiendas() {
            this.$router.push({
                name: "gestiontiendas"
            })
        },
        ir_excel() {
            this.$router.push({
                name: "ImportaExporta"
            })
        }
    }

}
</script>
