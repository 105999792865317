<template>
    <div class="mb-6 pa-3">
        <v-row class="mb-n5 mt-n2" dense>
            <v-col cols="7">
                <h4>OPTOMETRIA</h4>
                <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n3' : 'mb-4 mt-n4'">
                    <v-col cols="12" md="3" xs="12">
                        <v-btn color="success" class="btn mt-2 mb-1" block small @click="dial_cliente = true">
                            <v-icon dark left>
                                mdi-glasses
                            </v-icon> Nuevo
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" xs="12">
                        <v-btn color="info" :class="$vuetify.breakpoint.smAndDown ? 'mt-n1 mb-1' : 'btn mt-2'" block
                            small @click="dial_prox_cita = true">
                            <v-icon dark left>
                                mdi-eye
                            </v-icon> Citas
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" xs="12">
                        <v-btn v-if="filtro_cliente == ''" color="error"
                            :class="$vuetify.breakpoint.smAndDown ? 'mt-n1 mb-1' : 'btn mt-2'" block small
                            @click="abre_dial_historial()">
                            <v-icon dark left>
                                mdi-chart-bell-curve
                            </v-icon> Historial
                        </v-btn>
                        <v-btn v-if="filtro_cliente != ''" color="error" class=""
                            :class="$vuetify.breakpoint.smAndDown ? 'mt-n1' : 'btn mt-2'" block small
                            @click="elimina_filtro()">
                            <v-icon dark left>
                                mdi-chart-bell-curve
                            </v-icon> Elimina Filtro
                        </v-btn>
                    </v-col>
                </v-row>
                <v-card :class="$vuetify.breakpoint.smAndDown ? 'mt-3 mb-4' : 'pa-3 mb-4 mt-n3'"
                    v-if="array_ultima_cita != ''">
                    <h5>El Paciente tiene cita programada para el {{ conviertefecha(array_ultima_cita.inicio) }}

                        <v-icon dark left color="red" class="ml-3" @click="atender_rapido()">
                            mdi-medical-bag
                        </v-icon>
                    </h5>

                </v-card>
            </v-col>
            <v-col cols="5">
                <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mb-n7 mt-1 text-center'">
                    <v-col cols="12" md="6" xs="12">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date1"
                            label="INICIO"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" xs="12" :class="$vuetify.breakpoint.smAndDown ? 'mt-n4' : ''">
                        <v-text-field type="date" class="redondeado" outlined dense v-model="date2"
                            label="FIN"></v-text-field>
                    </v-col>

                </v-row>
                <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n4 mb-2' : 'mb-2'">
                    <v-col cols="6">

                    </v-col>
                    <v-col cols="6">
                        <v-btn x-small color="warning" class="mt-n2" block @click="filtrar()">
                            Filtrar
                        </v-btn>
                    </v-col>

                </v-row>
            </v-col>
        </v-row>
        <v-simple-table class="elevation-1 mt-2" fixed-header height="65vh" dense>
            <template v-slot:default>
                <thead>

                    <tr>
                        <th class="text-left">
                            Paciente
                        </th>
                        <th class="text-left">
                            Diagnostico
                        </th>
                        <th class="text-left" v-if="$store.state.permisos.master">
                            Ubica
                        </th>
                        <th class="text-left">
                            Fecha Atencion
                        </th>
                        <th class="text-left">
                            Estado
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td>{{ item.nom_cliente }}</td>
                        <td>{{ item.diagnostico }}</td>
                        <td v-if="$store.state.permisos.master">{{ extrae_texto(item.tienda, 4) }}</td>
                        <td>{{ conviertefecha(item.fecha) }}</td>
                        <td>
                            <v-row>
                                <v-col cols="12">
                                    <v-icon color="#FFB200" v-if="item.estado == 'PENDIENTE'">mdi-circle</v-icon>
                                    <v-icon color="#46FF00" v-if="item.estado == 'ATENDIDO'">mdi-circle</v-icon>
                                    <v-icon color="info" v-if="item.estado == 'CONTROL'">mdi-circle</v-icon>
                                    <strong class="red--text" v-if="item.editado">Edit.</strong>
                                </v-col>
                            </v-row>
                        </td>
                        <td>
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click='visualizar(item)'>
                                        <v-list-item-icon>
                                            <v-icon color="error">mdi-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Visualizar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="abre_edita(item)">
                                        <v-list-item-icon>
                                            <v-icon color="green">mdi-lead-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Editar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.estado == 'PENDIENTE' && !item.editado"
                                        @click="refresca_hora(item)">
                                        <v-list-item-icon>
                                            <v-icon color="red">mdi-clock-time-nine-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Refresca Hora</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click.prevent="abre_comparte(item)">
                                        <v-list-item-icon>
                                            <v-icon color="orange">mdi-share-variant-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>Compartir</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-dialog v-model="dial_garantia" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_garantia = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>GENERA GARANTIA</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <p><strong>ESTA SEGURO DE EDITAR ESTA RECETA?</strong></p>
                <v-select style="font-size:75%;" :items="array_optometra" label="Optometra Responsable de Edicion" dense
                    outlined v-model="especialista"></v-select>
                <v-row class="mt-n4" dense>
                    <v-col cols="12">
                        <v-btn block class="text-center" color="success" @click="abre_recetas()"
                            :disabled="especialista == ''">CONTINUAR</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_historial" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_historial = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4></h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-autocomplete outlined dense autofocus label="Busca Cliente" auto-select-first v-model="busca_p"
                    :items="arra_clientes" append-icon="mdi-magnify" @click:append="filtra()"
                    @keyup.enter="filtra()"></v-autocomplete>
                <v-card @click='filtra()'>
                    <v-container>
                        <v-img class="mt-n2 mx-auto" height="30" width="30" src="/filtro.png"></v-img>
                        <h5 block class="mb-n2 text-center">Filtro</h5>
                    </v-container>
                </v-card>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_citas" max-width="500">
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_citas = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4></h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4>El Paciente tiene una Cita programada para el
                    <p>{{ conviertefecha(array_cita.inicio) }}</p>
                </h4>
                <v-row class="mt-1" dense>
                    <v-col cols="6">
                        <v-card @click.prevent="carga_receta(cliente_)">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/reloj.png"></v-img>
                                <h6 block class="text-center">ATENDER HOY</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_comparte" max-width="550">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_comparte = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="6">
                        <v-card @click.prevent="imprime_qr('interno')">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/lector_qr.png"></v-img>
                                <h5 block class="text-center pa-1">QR INTERNO</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click.prevent="imprime_qr('externo')">
                            <v-container>
                                <v-img class="mx-auto" height="35" width="35" src="/lector_qr.png"></v-img>
                                <h5 block class="text-center pa-1">QR EXTERNO</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <nueva_med v-if="dial_medicion" @cambiar="cambiar(2)" :data="item_selecto" :client="cliente_"
            :ultima_med="ultima_med" @cierra=" dial_medicion = $event, filtrar()" @termina="dial_medicion = false" />
        <nueva_med_v2 v-if="dial_medicion_v2" @cambiar="cambiar(1)" :data="item_selecto" :client="cliente_"
            :ultima_med="ultima_med" @cierra=" dial_medicion_v2 = $event, filtrar()"
            @termina="dial_medicion_v2 = false" />
        <prox_cita v-if="dial_prox_cita" :mes=0 @cierra="dial_prox_cita = false" @atender="atender_cita($event)" />
        <nuevo_cli v-if="dial_cliente" :dial_cliente="dial_cliente" @cierra="dial_cliente = $event, filtro_cliente = ''"
            @cliente="selecciona_cliente($event)" />
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false"
            @recomienda="recomendacion($event)" />
    </div>
</template>

<script>
import nueva_med from '@/views/optometria/nueva_medicion'
import nueva_med_v2 from '@/views/optometria/nueva_medicion_v2'
import store from '@/store/index'
import nuevo_cli from '@/views/optometria/busca_cliente'
import {
    all_mediciones,
    elimina_medicion,
    busca_tabla,
    allcitas,
    edita_cita,
    buscaCliente,
    edita_medicion
} from '../../db'
import {
    pdfGenera
} from '../../imprime_qr'
import prox_cita from '@/components/dialogos/dialog_prox_cita'
import moment from 'moment'
import receta from '@/components/dialogos/dialog_receta'
export default {
    components: {
        nueva_med,
        nueva_med_v2,
        prox_cita,
        nuevo_cli,
        receta
    },
    data: () => ({
        dial_historial: false,
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        dial_medicion: false,
        dial_medicion_v2: false,
        dial_prox_cita: false,
        dial_cliente: false,
        desserts: [],
        item_selecto: [],
        cliente_: [],
        ultima_med: [],
        array_optometra: [],
        dial_garantia: false,
        especialista: '',
        dial_citas: false,
        array_cita: [],
        arra_clientes: [],
        filtro_cliente: '',
        busca_p: '',
        dial_receta: false,
        data_receta: [],
        array_ultima_cita: [],
        dial_comparte: false,
    }),

    async created() {
        await this.inicio()
        this.filtrar()
    },

    beforeDestroy() {
        all_mediciones().off();
    },
    computed: {
        listafiltrada() {

            return this.desserts
            /*else {
                var dni_cli = this.busca_p.split('/')[0].trim()
                all_mediciones().orderByChild('dni_cliente').equalTo(dni_cli)
                    .on("value", this.onDataChange);
                return this.desserts.filter(item => item.dni_cliente == this.filtro_cliente)
            }*/

        }
    },

    methods: {
        cambiar(val) {
            console.log(val)
            if (val == 2) {
                this.dial_medicion = false
                this.dial_medicion_v2 = true
            } else {
                this.dial_medicion = true
                this.dial_medicion_v2 = false
            }
        },
        abre_medicion() {
            //  this.dial_cliente = true
            this.dial_cliente = true
        },
        elimina_filtro() {
            all_mediciones().off()
            this.filtro_cliente = ''
            this.array_ultima_cita = []
            this.filtrar()
        },
        filtrar() {
            this.array_ultima_cita = ''
            this.filtro_cliente = ''
            all_mediciones().orderByChild('fecha')
                .startAt(moment(String(this.date1)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000)
                .once('value', (snapshot) => {
                    this.desserts = []
                    let array = [];
                    snapshot.forEach((item) => {
                        let data = item.val();

                        if (this.comparacion(data.fecha) && data.estado == 'PENDIENTE') {
                            //  elimina_medicion(data.id)
                            console.log(data.id, 'elimnar')
                        }

                        array.push(data);
                    });
                    array.sort(function (a, b) {
                        if (a.fecha > b.fecha) {
                            return 1;
                        }
                        if (a.fecha < b.fecha) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                    });
                    console.log(array)
                    this.desserts = array.reverse();
                })
        },
        extrae_texto(data, cantidad) {
            return data.substr(0, cantidad)
        },
        comparacion(fecha) {
            var hoy = moment().unix()
            var receta = moment.unix(fecha).add(24, 'h').unix()
            if (receta > hoy) {
                return false
            } else {
                return true
            }
        },
        abre_comparte(data) {
            console.log(data)
            this.item_selecto = data
            this.dial_comparte = true
        },
        imprime_qr(modo) {
            if (modo == 'interno') {
                var data = '.' + this.item_selecto.id
                pdfGenera(data)
            } else {
                var data = 'https://opticentervision.pe/recetas_externas/' + this.item_selecto.id
                pdfGenera(data)
            }
        },
        visualizar(item) {
            this.data_receta = item
            this.dial_receta = true
        },
        abre_dial_historial() {
            var array = store.state.clientes
            for (var i = 0; i < array.length; i++) {
                this.arra_clientes[this.arra_clientes.length] = array[i].documento + ' / ' + array[i].nombre
            }
            this.busca_p = ''
            this.dial_historial = true

        },
        async filtra() {
            var array = []
            var dni_cli = this.busca_p.split('/')[0].trim()
            var snap_cita = await allcitas().orderByChild('dni').equalTo(dni_cli).once("value")
            snap_cita.forEach((item) => {
                if (item.val().estado != 'ATENDIDO') {
                    array = item.val()
                    array.id_key = item.key
                }
            })
            var snapo = await all_mediciones().orderByChild('dni_cliente').equalTo(dni_cli).once("value")
            if (snapo.exists()) {
                this.desserts = []
                snapo.forEach((item) => {
                    let data = item.val();
                    this.desserts.push(data)
                })

            } else {
                alert('sin informacion')
                return
            }
            this.array_ultima_cita = array
            this.filtro_cliente = dni_cli
            this.dial_historial = false
        },
        async inicio() {
            var dia = moment(String(new Date)).format('DD')
            // this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
            this.date1 = moment(String(new Date)).format('YYYY-MM-DD')
            this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
        },

        abre_creador_documento() {
            this.item_selecto = []
            this.abre_medicion()
        },
        async abre_edita(item) {
            if (item.estado == "ATENDIDO") {
                this.ultima_med = []
                item.primer_especialista = item.especialista
                if (!Boolean(item.array_recom)) {
                    item.array_recom = item.array_compra
                }
                this.item_selecto = item
                var snapshot = await busca_tabla('especialistas').once("value")
                snapshot.forEach((item) => {
                    let data = item.val();
                    this.array_optometra[this.array_optometra.length] = data.nombre
                });

                this.dial_garantia = true
            } else {
                if (item.estado == 'CONTROL') {
                    this.cliente_ = {
                        control: true,
                        receta: false
                    }
                } else {
                    this.cliente_ = {
                        control: false,
                        receta: true
                    }
                }
                if (!Boolean(item.array_recom)) {
                    item.array_recom = item.array_compra
                }
                this.especialista = ''
                this.ultima_med = []
                this.item_selecto = item
                this.abre_medicion()
            }
        },
        abre_recetas() {
            this.cliente_ = {
                control: false,
                receta: true
            }
            this.item_selecto.especialista = this.especialista
            this.dial_garantia = false
            this.abre_medicion()
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY hh:mm A')
        },
        async selecciona_cliente(cliente) {
            var citas = []
            store.commit("dialogoprogress", 1)
            var snap_cita = await allcitas().orderByChild('dni').equalTo(cliente.id).once("value")
            if (snap_cita.exists()) {
                snap_cita.forEach((item) => {
                    var data = item.val()
                    var key = item.key
                    if (data.estado == 'PENDIENTE') {
                        data.key = key
                        citas.push(data)
                    }
                })
            }
            // await new Promise(resolve => setTimeout(resolve, 4000))
            if (Boolean(citas[0])) {
                this.array_cita = citas[0]
                cliente.control = true
                cliente.receta = true
                this.cliente_ = cliente
                this.dial_citas = true
            } else {

                cliente.control = false
                cliente.receta = true
                this.carga_receta(cliente)
            }
            store.commit("dialogoprogress", 1)

        },
        async carga_receta(cliente) {
            this.ultima_med = []
            var snap = await all_mediciones().orderByChild('dni_cliente').equalTo(cliente.id).once("value")
            if (snap.exists()) {
                let array = [];
                snap.forEach((item) => {
                    let data = item.val();
                    array.push(data);
                });
                this.ultima_med = array
            } else {
                this.ultima_med = []
            }
            if (!Boolean(this.dial_cliente)) {
                edita_cita(this.array_cita.key + '/estado/', 'ATENDIDO')
            }
            this.item_selecto = []
            this.cliente_ = cliente
            this.dial_cliente = false
            this.abre_medicion()
            this.dial_citas = false
        },
        abre_medicion() {
            if (store.state.permisos.opto_v2) {
                this.dial_medicion_v2 = true
            } else {
                this.dial_medicion = true
            }

        },
        async atender_cita(data) {
            var snap = await buscaCliente(data.dni).once("value")
            this.selecciona_cliente(snap.val())
            this.dial_prox_cita = false
        },
        async refresca_hora(item) {
            if (confirm('SEGURO DE ACTUALIZAR LA HORA DE LA RECETA?')) {
                store.commit("dialogoprogress", 1)
                var fecha = moment().unix()
                await edita_medicion(item.id, 'fecha', fecha)
                this.filtrar()
                store.commit("dialogoprogress", 1)
            }

        },
        async atender_rapido() {
            if (confirm('SEGURO DE PROCEDER CON LA ATENCION DEL CLIENTE?')) {
                await edita_cita(this.array_ultima_cita.id_key + '/estado/', 'ATENDIDO')
                var data = this.array_ultima_cita
                var snap = await buscaCliente(data.dni).once("value")
                var cliente = snap.val()
                cliente.control = true
                cliente.receta = true
                this.carga_receta(cliente)
            }
        }
    }
}
</script>
