import store from '@/store/index'
import { nuevo_historial, nuevo_pedido, obtenContador_externo, actualizaContador_externo, nuevo_pendiente, nueva_cabecera_parametro, busca_medicion, busca_receta_externa } from './db'
import moment from 'moment'

export const genera_pedidos = async (cabecera, items) => {
    var ids = moment().unix()
    await Promise.all([genera_pendiente(ids, cabecera, items), genera_historial(cabecera)])
    var arra = items
    var a_cue = 0
    for (var i = 0; i < arra.length; i++) {
        var data = arra[i]
        data.uuid = i
        if (data.tipo == 'P') {
            var contador = await obtenContador_externo().once("value")
            var id = contador.val().orden_pedidos
            var produc = store.state.productos.find((item) => (item.id == data.id))
            var tt = items.filter((item) => (item.tipo == 'P'))
            if (tt.length == 1) {
                a_cue = cabecera.a_cuenta
            }
            var array_cabecera = {
                id: id,
                fecha: cabecera.fecha,
                estado: 'PENDIENTE',
                tienda: cabecera.tienda,
                bd: cabecera.bd,
                doc_venta: cabecera.serie + '-' + cabecera.correlativoDocEmitido,
                comprobante: cabecera.numeracion,
                total: data.precio,
                costo: produc.costo,
                a_cuenta: a_cue,
                modo_receta: cabecera.modo_receta,
                id_receta: cabecera.id_receta,
                id_compra: '',
                comentario: cabecera.observacion,
                vendedor: cabecera.vendedor,
                producto: data,
                cliente_dni: cabecera.dni,
                cliente_nombre: cabecera.cliente,
                id_a_cuenta: ids,
                garantia_reclamos: false
            }
            await nuevo_pedido(array_cabecera.id, array_cabecera)
            if (data.tipo_focal.includes('DIGITAL')) {
                reporte_parametro(array_cabecera)
            }
            var valor = (parseInt(array_cabecera.id) + 1).toString().padStart(8, 0)
            await actualizaContador_externo('orden_pedidos', valor)
        }
    }
}
async function genera_historial(cabecera) {
    var array = {
        bd: cabecera.bd,
        cliente_dni: cabecera.dni,
        cliente_nombre: cabecera.cliente,
        doc_venta: cabecera.serie + '-' + cabecera.correlativoDocEmitido,
        fecha: cabecera.fecha,
    }
    await nuevo_historial(array.cliente_dni, array)
    return true
}
async function genera_pendiente(id, cabecera, items) {
    if (items.filter(item => item.tipo == 'P').length > 0) {
        var array_acuenta = []
        array_acuenta.push({
            id: 0,
            monto: cabecera.a_cuenta,
            observacion: 'Inicial',
            fecha: cabecera.fecha,
            estado: 'ACEPTADO'
        })
        var saldo = 0
        if (items.length != 1) {
            saldo = cabecera.a_cuenta
        }
        var array_cabecera = {
            id: id,
            fecha: cabecera.fecha,
            estado: 'PENDIENTE',
            tienda: cabecera.tienda,
            bd: cabecera.bd,
            doc_venta: cabecera.serie + '-' + cabecera.correlativoDocEmitido,
            comprobante: cabecera.numeracion,
            total: cabecera.total,
            detalle_a_cuenta: array_acuenta,
            total_a_cuenta: cabecera.a_cuenta,
            modo_receta: cabecera.modo_receta,
            id_receta: cabecera.id_receta,
            comentario: cabecera.observacion,
            vendedor: cabecera.vendedor,
            producto: items,
            cliente_dni: cabecera.dni,
            cliente_nombre: cabecera.cliente,
            telefono: cabecera.telefono,
            saldo: saldo,
        }
        await nuevo_pendiente(array_cabecera.id, array_cabecera)
    } else {
        var tt = parseFloat(cabecera.total) - parseFloat(cabecera.a_cuenta)
        if (tt > 0) {
            var array_acuenta = []
            array_acuenta.push({
                id: 0,
                monto: cabecera.a_cuenta,
                observacion: 'Inicial',
                fecha: cabecera.fecha,
                estado: 'ACEPTADO'
            })
            var saldo = 0
            if (items.length != 1) {
                saldo = cabecera.a_cuenta
            }
            var array_cabecera = {
                id: id,
                fecha: cabecera.fecha,
                estado: 'PENDIENTE',
                tienda: cabecera.tienda,
                bd: cabecera.bd,
                doc_venta: cabecera.serie + '-' + cabecera.correlativoDocEmitido,
                comprobante: cabecera.numeracion,
                total: cabecera.total,
                detalle_a_cuenta: array_acuenta,
                total_a_cuenta: cabecera.a_cuenta,
                modo_receta: cabecera.modo_receta,
                id_receta: cabecera.id_receta,
                comentario: cabecera.observacion,
                vendedor: cabecera.vendedor,
                producto: items,
                cliente_dni: cabecera.dni,
                cliente_nombre: cabecera.cliente,
                telefono: cabecera.telefono,
                saldo: saldo,
            }
            await nuevo_pendiente(array_cabecera.id, array_cabecera)
        }
    }
    return true
}
export const reporte_parametro = async (cabecera) => {

    if (cabecera.modo_receta == 'INTERNA') {
        var snap = await busca_medicion(cabecera.id_receta).once("value")
        var opto = snap.val().especialista
    } else {
        var snap = await busca_receta_externa(cabecera.cliente_dni, cabecera.id_receta).once("value")
        var opto = 'RECETA EXTERNA'
    }
    var parametro = ''
    var estado = 'PENDIENTE'
    if (Boolean(cabecera.producto.id_parametro)) {
        parametro = cabecera.producto.id_parametro
        estado = 'COMPLETO'
    }
    var array = {
        id: cabecera.id,
        fecha: cabecera.fecha,
        cliente_dni: cabecera.cliente_dni,
        cliente_nombre: cabecera.cliente_nombre,
        id_parametro: parametro,
        id_pedido: cabecera.id,
        id_receta: cabecera.id_receta,
        modo_receta: cabecera.modo_receta,
        id_producto: cabecera.producto.id,
        nom_producto: cabecera.producto.nombre,
        producto: cabecera.producto,
        estado: estado,
        vendedor: cabecera.vendedor,
        doc_venta: cabecera.doc_venta,
        comprobante: cabecera.comprobante,
        bd: cabecera.bd,
        tienda: cabecera.tienda,
        optometra: opto
    }
    nueva_cabecera_parametro(array.cliente_dni, array.id, array)
}