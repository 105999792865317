<template>
    <v-dialog v-model="dial_compras" max-width="1200px">
        <div>
            <v-system-bar window dark>
                <v-icon color="red" large @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="warning" @click="abre_modifica()">mdi-pencil</v-icon>
                <v-icon large color="info" @click="abre_lista_pedido()">mdi-magnify</v-icon>
                <v-icon color="red" large @click="(dial_anula = true)">mdi-delete</v-icon>
                <v-icon color="green" large @click="dialogo_genera = true">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-card-text>
                <v-row class="mb-n5" dense>
                    <v-col cols="6">
                        <h4>FECHA DE EMISION: {{ conviertefecha(arra_cabe_doC.fecha_emision) }}</h4>
                        <h4>FECHA DE INGRESO: {{ conviertefecha(arra_cabe_doC.fecha_ingreso) }}</h4>
                        <h4>DOCUMENTO: {{ arra_cabe_doC.tipodocumento }} /
                            {{ arra_cabe_doC.sreferencia }}-{{ arra_cabe_doC.creferencia }}</h4>
                        <h4>Pago: {{ arra_cabe_doC.modo_pago }}</h4>
                    </v-col>
                    <v-col cols="6">
                        <h4>RUC : {{ arra_cabe_doC.num_doc }}</h4>
                        <h4>RAZON SOCIAL : {{ arra_cabe_doC.nom_proveedor }}</h4>
                        <h4>Observacion : {{ arra_cabe_doC.observacion }}</h4>
                        <div class="mt-2 mb-n5" v-if="!$store.state.esmovil">
                            <v-text-field outlined dense v-model="num_pedido" label="NUMERO DE PEDIDO"
                                append-icon="mdi-magnify" @click:append="busca_pedido()"
                                @keyup.enter="busca_pedido()"></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-simple-table dark fixed-header height="55vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Descripcion
                            </th>
                            <th class="text-left">
                                Medida
                            </th>
                            <th class="text-left">
                                Cost Unit
                            </th>
                            <th class="text-left">
                                Cantidad
                            </th>
                            <th class="text-left">
                                Importe
                            </th>
                            <th class="text-left">
                                Receta
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in lista_productos" :key="item.key">
                            <td width="380">
                                <v-icon color="green" @click="editaProducto(item)" small
                                    class="mt-n1">mdi-pencil</v-icon>
                                {{ item.nombre }} - {{ item.id_pedido }}
                            </td>
                            <td width="35">{{ item.medida }}</td>
                            <td width="35">{{ redondear(item.costo_nuevo) }}</td>
                            <td width="35">{{ item.cantidad }}</td>
                            <td width="35" v-if="item.operacion == 'GRAVADA'">S/.{{ redondear(item.cantidad *
        item.costo_nuevo) }}</td>
                            <td width="35" v-if="item.operacion == 'GRATUITA'">S/.0.00</td>
                            <td width="35">
                                <v-icon @click.prevent="abre_receta(item)"
                                    :disabled="item.id_receta == undefined || item.id_receta == ''" right
                                    color="success">
                                    mdi-eye </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-spacer></v-spacer>
            <v-row dense>
                <v-col cols="6">

                </v-col>
                <v-col cols="6" class="text-right">
                    <h5> Op.Gravada:<span class="red--text"> S/.{{ tot_base_imp }}</span></h5>
                    <h5> Op.Gratuita: <span class="red--text"> S/.{{ tot_gratuita }}</span></h5>
                    <h5> IGV {{ igv }}%: <span class="red--text"> S/.{{ tot_igv }}</span></h5>
                    <h5> TOTAL: <span class="red--text"> S/.{{ totaliza }}</span></h5>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="dialogo_genera" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_genera = !dialogo_genera">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-3">
                <v-card-text>
                    <h2 class="text-center">SEGURO DE CONTINUAR??</h2>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" block @click="genera_compra(true)">SI</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogoProducto" max-width="390">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogoProducto = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-select :items="arrayOperacion" label="Operacion" dense outlined v-model="operacion_edita"></v-select>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="6" class="mb-n4 mt-n1">
                        <v-text-field dense @keyup.enter="grabaEdita()" class="pa-3" v-model="costo_edita"
                            label="Costo Unitario"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mx-auto text-center" dense>
                    <v-col cols="12" class="mb-n4 mt-n1">
                        <v-text-field dense @keyup.enter="grabaEdita()" class="pa-3" v-model="nombreEdita"
                            label="Nombre"></v-text-field>
                    </v-col>
                </v-row>
                <v-card-actions class="mt-n6">
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="eliminar()">
                        Elimina
                    </v-btn>
                    <v-btn color="green darken-1" text @click="grabaEdita()">
                        Graba
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialo_cantidad" max-width="400px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialo_cantidad = !dialo_cantidad">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-4">

                <v-card-title>
                    <v-select :items="arrayOperacion" label="Operacion" dense outlined
                        v-model="operacion_edita"></v-select>
                    <v-row class="mx-auto text-center" dense>
                        <v-col cols="6" class="mb-n4 mt-n1">
                            <v-text-field dense @focus="(foco_igv = false)" class="pa-3" v-model="sin_igv"
                                label="PRECIO SIN IGV"></v-text-field>
                        </v-col>
                        <v-col cols="6" class="mb-n4 mt-n1">
                            <v-text-field dense @focus="(foco_igv = true)" class="pa-3" v-model="con_igv"
                                label="PRECIO CON IGV"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD"
                        @focus="$event.target.select()" @keyup.enter="agregaCatalogo()"></v-text-field>
                </v-card-title>
                <v-btn class="mt-n6" color="red" @click="agregaCatalogo()" block>OK</v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_modifica" max-width="800px" persistent>

            <div>
                <v-system-bar window dark>
                    <v-icon @click="(dial_modifica = !dial_modifica)">mdi-close</v-icon>
                    <h5 class="text-center">REGISTRO DE COMPRAS</h5>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="pa-1" dense>
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="date" label="Emision"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="date" outlined dense v-model="date_ingreso"
                            label="Ingreso Producto"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pa-1 mt-n8" dense>
                    <v-col cols="6">
                        <v-text-field readonly outlined dense v-model="num_doc" label="N° DOC PROVEE."
                            append-icon="mdi-magnify"
                            @click:append="$store.commit('dialogo_tabla_proveedores', false)"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field readonly outlined dense v-model="nom_proveedor"
                            label="NOMBRE PROVEEDOR"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pa-1 mt-n8" dense>
                    <v-col cols="4">
                        <v-select :items="arraydocumento" label="Tipo" dense outlined
                            v-model="tipodocumento"></v-select>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field :disabled="(num_doc == '')" type="text" outlined dense v-model="sreferencia"
                            label="Serie Referencia" placeholder="F001"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field :disabled="(sreferencia == '')" type="number" outlined dense v-model="creferencia"
                            label="Correlativo Referencia" placeholder="1234"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="pa-1 mt-n8" dense>
                    <v-col cols="3">
                        <v-select :items="arraymodo" label="Modo Pago" dense outlined v-model="modo_pago"></v-select>
                    </v-col>
                    <v-col cols="9">
                        <v-textarea outlined dense v-model="observacion" auto-grow filled label="OBSERVACION"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="pa-1 mt-n2" dense>
                    <v-col cols="12">
                        <v-btn block class="" @click="guarda_moficacion()" color="success">GUARDA MODIFICACION</v-btn>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="progress" max-width="250">
            <v-card class="pa-12">
                <v-progress-linear indeterminate color="blue-grey" height="25">
                </v-progress-linear>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_lista_pedidos" max-width="850px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="(dial_lista_pedidos = !dial_lista_pedidos)">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table fixed-header height="70vh" dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    PRODUCTO
                                </th>
                                <th class="text-left">
                                    COMENTARIO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_pedidos" :key="item.id" @click="selecciona_pedido(item)">
                                <td style="font-size:75%;">{{ item.id }}-{{ item.producto.nombre }}</td>
                                <td style="font-size:75%;">{{ item.comentario }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_anula" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(dial_anula = !dial_anula)">mdi-close</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-3">
                <v-card-text>
                    <h2 class="text-center">SEGURO DE QUE DESEA ANULAR??</h2>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="anula()">SI</v-btn>
                    <v-btn color="success" @click="dial_anula = false">NO</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false" />
    </v-dialog>
</template>

<script>
import cateogrias from '@/components/dialogos/dialogocatalogo'
import clientes from '@/components/dialogos/dialogoClientes'
import moment from 'moment'
import store from '@/store/index'
import catalogo from '@/components/dialogos/dialogocatalogo'
import receta from '@/components/dialogos/dialog_receta'
import {
    busca_medicion,
    busca_receta_externa,
    nuevoMovimiento,
    busca_pedido,
    edita_pedido,
    allpedidos,
    edita_Movimiento
} from '../../db'
export default {
    name: 'caja',

    components: {
        receta,
        clientes,
        cateogrias,
        catalogo,
    },
    props: {
        data: [],
    },
    data() {
        return {
            dial_confirma: false,
            dial_lista_pedidos: false,
            dial_compras: false,
            dial_lista: false,
            dial_anula: false,
            progress: false,
            dial_modifica: false,
            dialo_cantidad: false,
            dialogo_genera: false,
            dial_configura: false,
            dialogoProducto: false,
            arra_cabe_doC: [],
            array_productos: [],
            lista_productos: [],
            arrayOperacion: [
                'GRAVADA',
                'GRATUITA'
            ],
            operacion_edita: 'GRAVADA',
            tot_igv: 0.00,
            tot_base_imp: 0.00,
            tot_gratuita: 0,
            tot_exonerada: 0,
            igv: 18,
            busca_p: '',
            btn: false,
            modo: '',
            cantidad: 1,
            cantidad_und: 1,
            selecto: '',
            con_igv: '',
            sin_igv: '',
            foco_igv: false,
            incluye_igv: true,
            costo_edita: '',
            nombreEdita: '',
            producto_ingresado: [],
            date: moment(String(new Date)).format('YYYY-MM-DD'),
            date_ingreso: moment(String(new Date)).format('YYYY-MM-DD'),
            arraymodo: ['CONTADO', 'CREDITO'],
            modo_pago: 'CONTADO',
            arraydocumento: ['FACTURA', 'BOLETA'],
            tipodocumento: 'FACTURA',
            edita_app: false,
            sreferencia: '',
            creferencia: '',
            observacion: '',
            num_doc: '',
            nom_proveedor: '',
            num_pedido: '',
            array_pedidos: [],
            id_compra1: '',
            id_compra2: '',
            modo_receta: '',
            id_receta: '',
            data_receta: [],
            dial_receta: false,
        }
    },
    created() {
        this.dial_compras = true
        this.inicio()
    },
    computed: {
        totaliza() {
            if (this.foco_igv) {
                this.sin_igv = (this.con_igv / 1.18).toFixed(4)
            } else {
                this.con_igv = (this.sin_igv * 1.18).toFixed(4)
            }

            var suma_gravada = 0
            var suma_exo = 0
            var suma_grati = 0
            var suma = 0
            if (this.lista_productos != undefined) {
                for (var i = 0; i < this.lista_productos.length; i++) {
                    var data = this.lista_productos[i]
                    if (data.operacion == 'GRAVADA') {
                        suma_gravada = suma_gravada + (data.cantidad * data.costo_nuevo)
                    }
                    if (data.operacion == 'EXONERADA') {
                        suma_exo = suma_exo + (data.cantidad * data.costo_nuevo)
                    }
                    if (data.operacion == 'GRATUITA') {
                        suma_grati = suma_grati + (data.cantidad * data.costo_nuevo)
                    }

                }
                if (this.incluye_igv) {
                    this.tot_base_imp = (suma_gravada / (1 + (this.igv) / 100)).toFixed(2)
                    this.tot_igv = (suma_gravada / (1 + (this.igv) / 100) * (this.igv) / 100).toFixed(2)
                } else {
                    this.tot_base_imp = (suma_gravada).toFixed(2)
                    this.tot_igv = ((suma_gravada) * (this.igv) / 100).toFixed(2)
                }
                this.tot_gratuita = (suma_grati / (1 + (this.igv) / 100)).toFixed(2)
                this.tot_exonerada = suma_exo
                suma = parseFloat(this.tot_base_imp) + parseFloat(this.tot_igv) + suma_exo
                return suma.toFixed(2)
            }
        },

    },
    methods: {
        async inicio() {
            this.arra_cabe_doC = this.data
            if (this.data.data == undefined) {
                this.data.data = []
            }
            this.lista_productos = this.data.data
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY')
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        editaProducto(val) {
            for (var i = 0; i < this.lista_productos.length; i++) {
                if (this.lista_productos[i].uuid == val.uuid) {
                    this.codigoedita = i
                    this.selecto = this.lista_productos[i]
                    this.nombreEdita = this.lista_productos[i].nombre
                    this.operacion_edita = this.lista_productos[i].operacion
                    this.costo_edita = this.lista_productos[i].costo_nuevo
                }
            }
            this.dialogoProducto = true
        },
        eliminar() {
            this.lista_productos.splice(this.codigoedita, 1)
            console.log(this.selecto)
            edita_pedido(this.selecto.id_pedido, 'id_compra', '')
            this.dialogoProducto = false
        },
        grabaEdita() {
            this.lista_productos[this.codigoedita].operacion = this.operacion_edita.toString().trim()
            this.lista_productos[this.codigoedita].nombre = this.nombreEdita.toString().trim()
            this.lista_productos[this.codigoedita].costo_nuevo = this.costo_edita
            this.genera_compra(false)
            this.dialogoProducto = false
        },
        async agregaCatalogo() {
            store.commit("dialogoprogress", 1)
            this.busca_p = ''
            var value = this.producto_ingresado
            value.costo = this.con_igv
            value.operacion = this.operacion_edita
            value.cantidad = parseFloat(this.cantidad)
            var array_producto = []
            array_producto = {
                uuid: this.create_UUID().substring(29),
                id: value.id,
                id_pedido: this.selecto.id,
                pedido: this.selecto,
                cantidad: value.cantidad,
                nombre: value.nombre,
                medida: value.medida,
                operacion: value.operacion,
                costo: value.costo,
                costo_nuevo: value.costo,
                modo_receta: this.modo_receta,
                id_receta: this.id_receta
            }
            this.dialo_cantidad = false
            this.lista_productos.push(array_producto)
            if (this.id_compra1 == '' && this.id_compra2 == '') {
                edita_pedido(this.selecto.id, 'id_compra', this.arra_cabe_doC.id)
            }
            if (this.id_compra1 != '' && this.id_compra2 == '') {
                edita_pedido(this.selecto.id, 'id_compra2', this.arra_cabe_doC.id)
            }
            this.genera_compra(false)
            store.commit("dialogoprogress", 1)
        },
        async genera_compra(cierra) {
            var array = this.arra_cabe_doC
            array.baseimponible = this.tot_base_imp
            array.igv = this.tot_igv
            array.porc_igv = this.igv
            array.tot_gratuita = this.tot_gratuita
            array.tot_exonerada = this.tot_exonerada
            array.total = this.totaliza
            array.data = this.lista_productos

            await nuevoMovimiento(array.id, array)
            if (cierra) {
                this.$emit('cierra_compra', false)
                this.dialogo_genera = false
            }
        },
        create_UUID() {
            var dt = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0;
                dt = Math.floor(dt / 16);
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
            return uuid;
        },
        conviertefecha_unix(date) {
            return moment(String(date)) / 1000
        },
        abre_modifica() {
            console.log(this.arra_cabe_doC)
            this.date_ingreso = moment.unix(this.arra_cabe_doC.fecha_ingreso).format('YYYY-MM-DD')
            this.date = moment.unix(this.arra_cabe_doC.fecha_emision).format('YYYY-MM-DD')
            this.nom_proveedor = this.arra_cabe_doC.nom_proveedor
            this.num_doc = this.arra_cabe_doC.num_doc
            this.tipodocumento = this.arra_cabe_doC.tipodocumento
            this.creferencia = this.arra_cabe_doC.creferencia
            this.sreferencia = this.arra_cabe_doC.sreferencia
            this.operacion = this.arra_cabe_doC.operacion
            this.modo_pago = this.arra_cabe_doC.modo_pago
            this.dial_modifica = true
        },
        async guarda_moficacion() {
            this.progress = true
            this.arra_cabe_doC.fecha_ingreso = this.conviertefecha_unix(this.date_ingreso)
            this.arra_cabe_doC.fecha_emision = this.conviertefecha_unix(this.date)
            this.arra_cabe_doC.nom_proveedor = this.nom_proveedor
            this.arra_cabe_doC.num_doc = this.num_doc
            this.arra_cabe_doC.tipodocumento = this.tipodocumento
            this.arra_cabe_doC.creferencia = this.creferencia
            this.arra_cabe_doC.sreferencia = this.sreferencia
            this.arra_cabe_doC.operacion = this.operacion
            this.arra_cabe_doC.modo_pago = this.modo_pago
            await nuevoMovimiento(this.arra_cabe_doC.id, this.arra_cabe_doC)
            await this.genera_compra(false)
            this.progress = false
            this.dial_modifica = false
        },
        selec_proveedor(data) {
            this.num_doc = data.codigo
            this.nom_proveedor = data.rsocial
            this.dialogoproveedor = false
        },
        cierra() {
            this.$emit('cierra_compra', false)
        },

        async busca_pedido() {
            var valor = (parseInt(this.num_pedido).toString().padStart(8, 0))
            var snap = await busca_pedido(valor).once("value")
            console.log(snap.val())
            if (snap.exists()) {
                var item = snap.val()
                if (Boolean(item.id_compra)) {
                    this.id_compra1 = item.id_compra
                    alert("NUMERO DE PEDIDO YA FUE REGISTRADO EN EL MOVIMIENTO N° " + item.id_compra)
                    if (Boolean(item.id_compra2)) {
                        this.id_compra2 = item.id_compra2
                        alert("NUMERO DE PEDIDO YA FUE REGISTRADO EN EL MOVIMIENTO N° " + item.id_compra2)

                    } else {
                        this.modo_receta = item.modo_receta
                        this.id_receta = item.id_receta
                        this.producto_ingresado = item.producto
                        this.operacion_edita = 'GRAVADA'
                        this.con_igv = item.costo
                        this.sin_igv = (this.con_igv / 1.18).toFixed(4)
                        this.cantidad = 1
                        this.modo = false
                        this.selecto = item
                        this.dialo_cantidad = true

                    }
                } else {
                    this.modo_receta = item.modo_receta
                    this.id_receta = item.id_receta
                    this.producto_ingresado = item.producto
                    this.operacion_edita = 'GRAVADA'
                    this.con_igv = item.costo
                    this.sin_igv = (this.con_igv / 1.18).toFixed(4)
                    this.cantidad = 1
                    this.modo = false
                    this.selecto = item
                    this.dialo_cantidad = true
                }


            } else {
                alert("NUMERO DE PEDIDO NO EXISTE EN EL REGISTRO")
            }
        },
        async abre_lista_pedido() {
            this.array_pedidos = []
            var snap = await allpedidos().orderByChild('id_compra').equalTo('').once('value')
            console.log(snap.val())
            if (snap.exists()) {
                snap.forEach((item) => {
                    this.array_pedidos.push(item.val())
                })
                this.dial_lista_pedidos = true
            } else {
                alert('SIN INFORMACION')
            }
        },
        async anula() {
            store.commit("dialogoprogress", 1)
            await edita_Movimiento(this.arra_cabe_doC.id, 'estado', 'anulado')
            this.dial_anula = false
            this.$emit('cierra_compra', false)
            store.commit("dialogoprogress", 1)
        },
        selecciona_pedido(item) {
            this.producto_ingresado = item.producto
            this.operacion_edita = 'GRAVADA'
            this.con_igv = item.costo
            this.sin_igv = (this.con_igv / 1.18).toFixed(4)
            this.cantidad = 1
            this.modo = false
            this.selecto = item
            this.dialo_cantidad = true
            this.dial_lista_pedidos = false
        },
        async abre_receta(item) {
            console.log(item)
            store.commit("dialogoprogress", 1)
            if (item.modo_receta == 'INTERNA') {
                var snap = await busca_medicion(item.id_receta).once("value")
                var array = snap.val()
            } else {
                var snap = await busca_receta_externa(item.pedido.cliente_dni, item.pedido.id_receta).once("value")
                var array = snap.val()
                array.especialista = 'RECETA EXTERNA'
            }
            store.commit("dialogoprogress", 1)
            this.data_receta = array
            this.dial_receta = true

        },
    },

}
</script>
