<template>
    <div class="mb-6 pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n4">
                <v-row class="mt-n2 pa-1">
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete outlined dense autofocus label="Busca Cliente" auto-select-first v-model="busca_p"
                            :items="arra_clientes" append-icon="mdi-magnify" @click:append="busca()"
                            @keyup.enter="busca()"></v-autocomplete>
                    </v-col>
                </v-row>
            </div>

            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Especialista
                            </th>
                            <th class="text-left">
                                Diagnostico
                            </th>
                            <th class="text-left">
                                OD
                            </th>
                            <th class="text-left">
                                OI
                            </th>
                            <th class="text-left">
                                Comentarios
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                            <td style="font-size:75%;">{{ item.especialista }}</td>
                            <td style="font-size:75%;">{{ item.diagnostico }}</td>
                            <td style="font-size:75%;">{{ serie(item.cilindro_od) }}</td>
                            <td style="font-size:75%;">{{ serie(item.cilindro_oi) }}</td>
                            <td style="font-size:75%;">
                                <v-icon color="success" @click='visualizar(item)'>mdi-eye</v-icon>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false" @recomienda="recomendacion($event)" />
    </div>
</template>

<script>
import {
    all_mediciones,
} from '../../db'

import store from '@/store/index'
import moment from 'moment'
import receta from '@/components/dialogos/dialog_receta'
export default {

    components: {
        receta,
    },
    data: () => ({
        cliente_: [],
        desserts: [],
        dialog: false,
        arra_clientes: [],
        busca_p: '',
        num_cliente: '',
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        dial_receta: false,
        data_receta: [],
        ultima_med: [],
        item_selecto: [],
        array_optometra: [],
        dial_garantia: false,
        especialista: '',
        dial_medicion: false
    }),
    created() {
        this.carga_clientes()
    },
    computed: {
        listafiltrada() {
            return this.desserts
        }
    },

    methods: {

        carga_clientes() {
            var array = store.state.clientes
            for (var i = 0; i < array.length; i++) {
                this.arra_clientes[this.arra_clientes.length] = array[i].documento + ' / ' + array[i].nombre
            }
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },
        busca() {
            var array = []
            this.desserts = []
            this.num_cliente = this.busca_p.split('/')[0].trim()
            if (this.num_cliente != '') {
                console.log(this.num_cliente)
                all_mediciones().orderByChild("dni_cliente")
                    .equalTo(this.num_cliente)
                    .once("value").then((snapshot) => {
                        if (snapshot.exists()) {
                            snapshot.forEach((item) => {
                                var data = item.val()
                                data.ultimo = false
                                array.push(data)
                            })
                            array.sort(function (a, b) {
                                if (a.fecha < b.fecha) {
                                    return 1;
                                }
                                if (a.fecha > b.fecha) {
                                    return -1;
                                }
                                // a must be equal to b
                                return 0;
                            });
                            array[0].ultimo = true
                            this.desserts = array
                        } else {
                            store.commit('dialogosnackbar', 'SIN INFORMACION')
                        }
                    })

            }
        },
        visualizar(item) {
            this.data_receta = item
            this.dial_receta = true
        },
        async editar(item) {
            console.log(item)
            if (item.estado == "ATENDIDO") {
                this.ultima_med = []
                item.primer_especialista = item.especialista
                this.item_selecto = item
                var snapshot = await busca_tabla('especialistas').once("value")
                snapshot.forEach((item) => {
                    let data = item.val();
                    this.array_optometra[this.array_optometra.length] = data.nombre
                });
                this.dial_garantia = true
            } else {
                this.especialista = ''
                this.ultima_med = []
                this.item_selecto = item
                this.dial_medicion = true
            }
        },
        serie(item) {
            if (item >= -2) {
                return '1° SERIE'
            }
            if (item < -2 && item >= -4) {
                return '2° SERIE'
            }
            if (item < -4) {
                return '3° SERIE'
            }
        }

    }
}
</script>

<style></style>
