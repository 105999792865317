<template>
<v-container grid-list-xs class="mb-6">
        <v-btn color="success" rounded elevation="15" block  @click="dialogoNuevo=!dialogoNuevo,$store.commit('iconoselecto','') " >NUEVO</v-btn>
        <v-row class="mt-3" dense>
                    <v-col
                        v-for="item in desserts"
                        :key="item.id"
                        cols="6"                          
                        md="3"
                        sm="4"                        
                        xs="4">
                        <v-card @click="abreedita(item),itemselecto = item"
                         height="100" style="text-align:center">                          
                       
                           <v-card class="mx-auto" max-width="50" max-height="50" style="text-align:center">
                            <v-icon v-if="item.icono==''" x-large>mdi-clipboard-text-search-outline</v-icon>
                            <v-img    
                            v-if="item.icono!=''"                    
                            class="mx-auto"
                            :src="item.icono"
                            height="50"
                            width="50"
                          ></v-img>         
                          </v-card>  
                           <v-card-text >
                          <p class="black--text mt-n3" style="font-size:12px">{{item.nombre}}</p> 
                          
                        </v-card-text>                     
                      </v-card>
                    </v-col>                   
        </v-row>   

        <v-dialog
            v-model="dialogocategoria"
            max-width="460px"
            >
            <div>
            <v-system-bar window dark>
                <v-icon @click="dialogocategoria=!dialogocategoria">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon color="red" large @click="dialogoElimina=!dialogoElimina">mdi-delete</v-icon>
            </v-system-bar>
            </div>
            <v-card class="pa-6">
                <v-card class="mx-auto" max-width="70" style="text-align:center" @click="$store.commit('dialogoiconos',false)">
                  <v-icon v-if="$store.state.iconoselecto==''" x-large>mdi-clipboard-text-search-outline</v-icon>
                  <v-img    
                  v-if="$store.state.iconoselecto!=''"                    
                  class="mx-auto"
                  :src="$store.state.iconoselecto"
                  height="50"
                  width="50"
                ></v-img>         
                </v-card>    
                <v-col cols="12">
                <v-text-field
                  type="number"
                  dense
                  outlined
                  rounded
                  v-model="posicion"
                  label="posicion"       
                ></v-text-field>
                </v-col>
              <v-btn class="mt-2" color="success" block @click="grabaedita()">GRABA</v-btn>
                
            </v-card>

        </v-dialog>

          <v-dialog
            v-model="dialogoElimina"
            max-width="460px"
            >
            <div>
            <v-system-bar window dark>
                <v-icon @click="dialogoElimina=!dialogoElimina">mdi-close</v-icon>
            </v-system-bar>
            </div>
            <v-card class="pa-6">
              <v-btn color="error" block @click="elimina()">ESTA SEGURO</v-btn>
            </v-card>
        </v-dialog>
        
          <v-dialog
            v-model="dialogoNuevo"
            max-width="460px"
            >
            <div>
            <v-system-bar window dark>
                <v-icon @click="dialogoNuevo=!dialogoNuevo">mdi-close</v-icon>
            </v-system-bar>
            </div>
            <v-card
              class="mx-auto pa-2"              
            >              
               <v-row dense no-gutters>
                    <v-col cols="12">
                           <v-container>    
                             <v-card class="mx-auto" max-width="70" style="text-align:center" @click="$store.commit('dialogoiconos',false)">
                                <v-icon  v-if="$store.state.iconoselecto==''"   x-large>mdi-clipboard-text-search-outline</v-icon>
                               <v-img    
                                v-if="$store.state.iconoselecto!=''"                    
                                class="mx-auto"
                                :src="$store.state.iconoselecto"       
                                height="50"
                                width="50"
                              ></v-img>         
                             </v-card>          
                        </v-container>
                    </v-col>       
                    <v-col cols="12">
                        <v-text-field
                          dense
                          outlined
                          rounded
                          v-model="nombre"
                          label="NOMBRE"       
                        ></v-text-field>
                    </v-col>
                      <v-col cols="12">
                        <v-text-field
                          type="number"
                          dense
                          outlined
                          rounded
                          v-model="posicion"
                          label="posicion"       
                        ></v-text-field>
                    </v-col>

        </v-row>   

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="success" @click="grabar()"                                 
                >
                 GRABAR
                </v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>

    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        top
      >
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
      <dialogoiconos/>
</v-container>
</template>

<script>
import { allCategorias,eliminaCategoria,nuevoCategoria,editaCategoria } from '../db'
import dialogoiconos from '@/components/dialogos/iconos'
import store from '@/store/index'

  export default {
    components: {
     dialogoiconos
    },

    data: () => ({
      dialogocategoria:false,
      itemselecto:'',
      dialogoElimina:false,
      dialogoNuevo:false,
      url:'',
      nombre:'',
      posicion:0,
      codigonuevo:1,
      desserts:[],
      snackbar: false,
      text: '',
      timeout: 2000,
    }),
    mounted() {
      allCategorias().on("value", this.onDataChange);
      },
    beforeDestroy() {
      allCategorias().off("value", this.onDataChange);
    },
 
    methods: {
      initialize(){
        allCategorias().on("value", this.onDataChange);
      },
      onDataChange(items) {
      let array = [];
      items.forEach((item) => {
        let key = item.key;
        let data = item.val();
        array.push({
          id: key,
          nombre: data.nombre,
          posicion:data.posicion,
          icono: data.icono
        });
      });
      this.codigonuevo = parseInt(array[array.length-1].id) + 1
      array.sort(function (a, b) {
        if (a.posicion > b.posicion) {
          return 1;
        }
        if (a.posicion < b.posicion) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      this.desserts = array;
      
    },
  
      grabar(){
        if(this.nombre!='' && this.posicion!=''){
            var id = this.codigonuevo
              var array= ({
                id:id,
                nombre:this.nombre,
                posicion:parseInt(this.posicion),
                icono: store.state.iconoselecto
              })
            nuevoCategoria(id,array)
            this.dialogoNuevo=false
            this.nombre=''   
            store.commit('iconoselecto',"")         
        }
        else{
            this.snack("Posicion debe ser mayor a 0")
        }
      },
      elimina(){
        eliminaCategoria(this.itemselecto.id) 
        this.dialogoElimina=false
        this.dialogocategoria=false
      },
      irProductos(){
          this.$router.push({name:"productos"})
      },
      abreedita(item){
        this.posicion = item.posicion
        store.commit('iconoselecto',item.icono)
        this.dialogocategoria=true
      
      },
      grabaedita(){
          editaCategoria(this.itemselecto.id,"icono",store.state.iconoselecto)
          editaCategoria(this.itemselecto.id,"posicion",parseInt(this.posicion))
          store.commit('iconoselecto',"")   
          this.posicion=''
          this.dialogocategoria = false
      },
      snack(text){
        this.snackbar=true
        this.text = text
    },
    },
  }
</script>