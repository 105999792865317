<template>
    <div>
        <v-dialog v-model="dial" max-width="500px" persistent>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>Periodo {{ periodo }}</h4>
                <v-spacer></v-spacer>
                <v-icon @click="dial_config = true" large color="green"
                    :disabled="!$store.state.permisos.master">mdi-cog</v-icon>
            </v-system-bar>
            <v-card class="pa-6">
                <h3 class="text-center red--text mt-n3">META DE VENTA</h3>
                <h3 class="text-center red--text">{{ $store.state.permisos.tienda }}</h3>
                <v-row>
                    <v-col cols="6">
                        <v-progress-circular :rotate="360" :size="150" :width="15" :value="value" color="teal">
                            {{ ((suma_total / meta_actual) * 100).toFixed(2) }}%
                        </v-progress-circular>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="mt-6"> Venta total : S/.{{ suma_total }}</h3>
                        <h3 class=""> A cuenta : S/.{{ a_cuenta }}</h3>
                        <h3 class=""> Meta : S/.{{ meta_actual }}</h3>

                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_config" max-width="500px" persistent>
            <v-system-bar window dark>
                <v-icon large color="red" @click="dial_config = false">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>Periodo {{ periodo }}<v-icon color="green" @click="activa_guardar = false">mdi-pencil</v-icon></h4>
                <v-spacer></v-spacer>
                <v-icon large color="info" @click="guardar()" v-if="activa_guardar">mdi-content-save</v-icon>
            </v-system-bar>
            <v-card class="pa-6">
                <v-row class="mt-n4 pa-4" v-if="!activa_guardar">
                    <v-col cols="8">
                        <v-select :items="array_periodos" label="PERIODO" outlined v-model="periodo" dense></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-icon large color="green" @click="carga_periodo()">mdi-refresh</v-icon>
                    </v-col>
                    <v-col cols="2">

                    </v-col>
                </v-row>
                <v-row v-if="activa_guardar" class="mt-n4" dense v-for="item in array_metas" :key="item.tienda">
                    <v-col cols="12">
                        <v-row dense no-gutters>
                            <v-col cols="12">
                                <v-text-field class="" prefix="S/." outlined dense v-model="item.monto" type="number"
                                    :label="item.tienda"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    nueva_meta,
    ver_meta,
    allCabecera
} from '../../db'
export default {

    data() {
        return {
            dial_config: false,
            dial: false,
            interval: {},
            value: 0,
            array_periodos: ['09-2023', '10-2023', '11-2023', '12-2023', '01-2024'],
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            periodo: moment(String(new Date)).format('MM-YYYY'),
            array_metas: [],
            activa_guardar: false,
            meta_actual: 0,
            suma_total: 0,
            a_cuenta: 0
        }
    },
    created() {
        this.dial = true
        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
        this.carga_periodo()
    },

    beforeDestroy() {
        clearInterval(this.interval)
    },
    mounted() {
        this.interval = setInterval(() => {
            if (this.value === 100) {
                return (this.value = 0)
            }
            this.value += 1
        }, 50)
    },
    computed: {
        listafiltrada() {
            return this.desserts
        },
    },
    methods: {
        async carga_periodo() {
            var suma = 0
            var a_cuenta = 0
            var snap = await ver_meta(this.periodo).once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    var da = item.val()
                    if (da.tienda == store.state.permisos.tienda) {
                        this.meta_actual = da.monto
                    }
                })
                var snapshot = await allCabecera().orderByChild('fecha')
                    .startAt(moment(String(this.date1)) / 1000)
                    .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000)
                    .once("value")
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        var d = item.val()
                        if (d.estado != 'ANULADO') {
                            a_cuenta = a_cuenta + parseFloat(d.a_cuenta)
                            suma = suma + parseFloat(d.total)
                        }
                    })

                }
                this.a_cuenta = a_cuenta
                this.suma_total = suma
                this.array_metas = snap.val()
                this.activa_guardar = true
                return
            }
            var array = store.state.array_tiendas
            var metas = []
            for (var i = 0; i < array.length; i++) {
                var data = array[i]

                metas.push({
                    tienda: data.nom_tienda,
                    monto: ''
                })
            }

            this.activa_guardar = true
            this.array_metas = metas
        },

        async guardar() {
            store.commit("dialogoprogress", 1)
            await nueva_meta(this.periodo, this.array_metas)
            this.cierra()
            store.commit("dialogoprogress", 1)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
