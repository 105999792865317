<template>
    <div class="pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n6">
                <v-row class="mt-n3 mb-1 pa-2">

                    <v-col cols="4">
                        <h5>Traslado Monturas</h5>
                        <v-btn color="success" class="" block small @click="dial_crea = true">Nuevo
                            Traslado</v-btn>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date2" label="Desde"></v-text-field>
                    </v-col>
                </v-row>
            </div>

            <v-card>
                <v-simple-table dense fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Resp.Traslado
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.vendedor }}</td>
                                <td style="font-size:85%;">{{ item.origen }} --> {{ item.destino }}</td>
                                <td style="font-size:85%;">{{ item.unidades }}</td>
                                <td width="100">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-icon v-if="$store.state.permisos.tienda == item.origen" color="green"
                                                @click.prevent="abre_edita(item)">mdi-pencil</v-icon>
                                            <v-icon v-if="$store.state.permisos.tienda != item.origen" color="green"
                                                @click.prevent="abre_acepta(item)">mdi-eye</v-icon>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>

        </v-card>

        <v-dialog v-model="dial" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-icon v-if="true" color="success" large @click="guarda_final()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mt-n2" dense>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="ORIGEN" dense outlined
                            v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="DESTINO" dense outlined
                            v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n6" dense>
                    <v-col cols="12">
                        <v-text-field prefix="M" :disabled="!edita" outlined dense rounded id="cod" v-model="codigo"
                            label="Codigo" append-icon="mdi-barcode-scan" @click:append="buscaProducto()"
                            @keyup.enter="buscaProducto()" autofocus></v-text-field>
                    </v-col>
                </v-row>

                <v-simple-table dark dense fixed-header height="52vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Und.
                                </th>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in lista_monturas" :key="item.uuid">
                                <td>{{ item.id }}-{{ item.marca }}</td>
                                <td>{{ item.modelo }}</td>
                                <td>
                                    <v-icon color="red" v-if="item.estado != 'ACEPTADO'" :disabled="!edita"
                                        @click="elimina(item)">mdi-delete</v-icon>
                                    <v-icon color="green" v-if="item.estado == 'ACEPTADO'">mdi-check</v-icon>

                                </td>
                            </tr>
                        </tbody>

                    </template>

                </v-simple-table>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_acepta" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial_acepta = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-icon v-if="true" color="success" large @click="guarda_final_e()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mt-n2" dense>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="ORIGEN" dense outlined
                            v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="DESTINO" dense outlined
                            v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n6" dense>
                    <v-col cols="12">
                        <v-text-field prefix="M" outlined dense rounded id="cod" v-model="codigo" label="Codigo"
                            append-icon="mdi-barcode-scan" @click:append="acepta_producto()"
                            @keyup.enter="acepta_producto()" autofocus></v-text-field>
                    </v-col>
                </v-row>
                <v-simple-table dense fixed-header height="52vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Und.
                                </th>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in lista_montur" :key="item.id">
                                <td>{{ item.id }}-{{ item.marca }}</td>
                                <td>{{ item.estado }}</td>
                                <td>
                                    <v-icon color="green" v-if="item.estado == 'ACEPTADO'">mdi-check</v-icon>
                                    <v-icon color="red" v-else>mdi-cancel</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_crea" max-width="450px">
            <v-card class="pa-6 mx-auto text-center">
                <v-row dense>
                    <v-col cols="6">
                        <v-select :disabled='!$store.state.permisos.master' :items="$store.state.tiendas" label="ORIGEN"
                            dense outlined v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="$store.state.tiendas" label="DESTINO" dense outlined v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-select style="font-size:75%;" :items="$store.state.array_empleados" label="VENDEDOR" dense outlined
                    v-model="vendedor"></v-select>
                <v-btn :disabled="destino == ''" color="green darken-1" block dark @click="abre_nuevo()">
                    Graba
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    allMonturas,
    editaMontura,
    nueva_traslado,
    all_traslados,
    edita_traslado
} from '../../db'
export default {

    data() {
        return {
            dial_crea: false,
            array_monturas: [],
            dial: false,
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: [],
            lista_monturas: [],
            lista_montur: [],
            codigo: '',
            origen: store.state.permisos.tienda,
            tienda_activa: store.state.permisos.tienda,
            destino: '',
            obs: '',
            item_selecto: [],
            vendedor: '',
            edita: false,
            dial_acepta: false
        }
    },
    created() {
        this.date1 = moment(String(new Date)).format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
    },
    mounted() {
        all_traslados().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    destroyed() {
        all_traslados().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_traslados().orderByChild('fecha')
                .startAt(moment(String(this.date1)) / 1000)
                .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
            if (store.state.permisos.master) {
                var a = this.desserts
            } else {
                var a = this.desserts.filter(item => item.origen == this.tienda_activa || item.destino == this.tienda_activa)
            }
            return a.reverse()
        },
        lista() {
            return this.lista_montur
        }
    },
    methods: {
        async guarda_final() {
            console.log(this.item_selecto)
            store.commit("dialogoprogress", 1)
            this.item_selecto.unidades = this.lista_monturas.length
            this.item_selecto.data = this.lista_monturas
            await nueva_traslado(this.item_selecto.id, this.item_selecto)
            this.dial = false
            store.commit("dialogoprogress", 1)
        },
        guarda_final_e() {
            this.dial_acepta = false
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },
        async abre_nuevo() {
            this.edita = true
            this.lista_monturas = []
            this.origen = store.state.permisos.tienda
            if (this.origen == this.destino) {
                alert('NO PUEDE SER IGUAL A DESTINO')
                return
            }
            this.array_monturas = []
            store.commit("dialogoprogress", 1)
            console.log(store.state.array_tiendas.find(item => item.nom_tienda == this.origen).bd)
            var snap = await allMonturas().orderByChild('ubicacion').equalTo(this.origen).once('value')
            snap.forEach((item) => {
                let data = item.val();
                this.array_monturas.push(data)
            });
            var fecha = moment().unix()
            var array = {
                id: fecha,
                fecha: fecha,
                origen: this.origen,
                destino: this.destino,
                data: [],
                vendedor: this.vendedor
            }
            this.item_selecto = array
            // await nueva_traslado(array.id, array)
            store.commit("dialogoprogress", 1)
            this.dial_crea = false
            this.dial = true
        },
        async abre_edita(data) {
            this.edita = false
            console.log(data)
            var snap = await allMonturas().orderByChild('ubicacion').equalTo(data.origen).once('value')
            snap.forEach((item) => {
                let data = item.val();
                this.array_monturas.push(data)
            });
            this.item_selecto = data
            this.origen = data.origen
            this.destino = data.destino
            if (data.data != undefined) {
                this.lista_monturas = data.data
            }

            this.dial = true
        },
        abre_acepta(data) {
            this.item_selecto = data
            this.origen = data.origen
            this.destino = data.destino
            if (data.data != undefined) {
                this.lista_montur = data.data
            }
            this.dial_acepta = true
        },
        async acepta_producto() {
            if (this.codigo[0] == 'M') {
                var cod = this.codigo
            } else {
                var cod = 'M' + this.codigo.toString().padStart(6, 0)
            }
            var a = this.lista_montur.find(id => id.id == cod)
            const pos = this.lista_montur.map(e => e.id).indexOf(cod);
            if (a.estado == 'ACEPTADO') {
                alert('PRODUCTO YA ESCANEADO')
                this.codigo = ''
                return
            }
            if (a == undefined) {
                alert('PRODUCTO NO EXISTE EN ORIGEN')
                this.codigo = ''
                return
            }
            store.commit("dialogoprogress", 1)
            a.estado = 'ACEPTADO'
            this.lista_montur[pos] = a
            await editaMontura(cod + '/ubicacion', this.destino)
            await edita_traslado(this.item_selecto.id + '/data', this.lista_montur)
            console.log(this.lista_montur[pos])
            store.commit("dialogoprogress", 1)
            this.codigo = ''
        },
        buscaProducto() {
            var cod = 'M' + this.codigo.toString().padStart(6, 0)
            if (this.destino == '') {
                alert('SELECCIONE DESTINO VALIDO')
                return
            }
            if (this.lista_monturas.find(id => id.id == cod)) {
                alert('PRODUCTO YA ESCANEADO')
                this.codigo = ''
            } else {
                store.commit("dialogoprogress", 1)
                var a = this.array_monturas.find(id => id.id == cod)
                if (a == undefined) {
                    alert('PRODUCTO NO EXISTE EN ORIGEN')
                    this.codigo = ''
                    return
                }
                a.estado = 'PENDIENTE'
                this.lista_monturas.push(a)
                store.commit("dialogoprogress", 1)
                this.codigo = ''
            }

        },
        async elimina(item) {
            var i = this.lista_monturas.map(e => e.id).indexOf(item.id)
            this.lista_monturas.splice(i, 1)
        }
    },

}
</script>
