<template>
<v-dialog v-model="$store.state.dialogoproductos" max-width="700px" persistent @keydown.esc="activaproductos=false">
    <div>
        <v-system-bar window dark>
            <v-icon @click="activaproductos=false,$store.commit('dialogoproductos',false)">mdi-close</v-icon>
            <v-spacer></v-spacer>
            <v-icon v-if="activaproductos" large color="red" @click="activaproductos=false">mdi-keyboard-return</v-icon>
        </v-system-bar>
    </div>
    <v-card>
        <v-simple-table v-if="!activaproductos && $store.state.configuracion.ordencategorias" fixed-header max-width="600px" class="pa-1">
            <template v-slot:default>
                <thead>
                </thead>
                <tbody>

                    <v-row class="mt-1 mb-4 mx-auto" style="text-align:center" dense>
                        <v-col v-for="item in arrayiconos" :key="item.id" cols="6" class="pa-1" md="4" sm="4" xs="4">
                            <v-card height="80" @click.prevent="iraproductos(item)">
                                <v-card-text class="red--text" style="font-size:medium">
                                    <span class="black--text">{{item.nombre}}</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                </tbody>
            </template>
        </v-simple-table>

        <div v-if="activaproductos || !$store.state.configuracion.ordencategorias">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field class="mb-n1 mt-n2" outlined dense v-model="buscar" append-icon="mdi-magnify" label="Buscar" single-line hide-details :autofocus='!$store.state.esmovil'></v-text-field>
            </v-card-title>

            <v-simple-table fixed-header height="65vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Nombre
                            </th>
                            <th class="text-left">
                                Stock
                            </th>
                            <th class="text-left">
                                Precio
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id" @click="abre_cantidad(item)">
                            <td style="font-family:verdana;font-size:80%;">{{ item.nombre }}</td>
                            <td style="font-family:verdana;font-size:80%;">{{ item.stock }}</td>
                            <td style="font-family:verdana;font-size:80%;">{{ item.precio }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
    </v-card>
    <v-dialog v-model="dialo_cantidad" max-width="200px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dialo_cantidad=!dialo_cantidad">mdi-close</v-icon>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card>

            <v-card-title>
                <v-text-field type="number" autofocus outlined dense v-model="cantidad" label="CANTIDAD" @focus="$event.target.select()" @keyup.enter="agregaCatalogo()"></v-text-field>
            </v-card-title>

            <v-btn class="mt-n6" color="red" @click="agregaCatalogo()" block>OK</v-btn>

        </v-card>
    </v-dialog>

    <v-dialog persistent v-model="progress" max-width="200">
        <v-card class="pa-12">
            <v-progress-circular :rotate="90" :size="100" :width="15" color="primary" indeterminate></v-progress-circular>
        </v-card>
    </v-dialog>

</v-dialog>
</template>

<script>
import store from '@/store/index'

export default {

    data() {
        return {
            arrayiconos: [],
            tamañoarray: 0,
            categoriaselecta: '',
            activaproductos: false,
            buscar: '',
            progress: false,
            dialo_cantidad: false,
            cantidad: 1,
            selecto: '',
        }
    },
    created() {

    },
    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            //this.activaproductos=false
            this.arrayiconos = store.state.categorias
        },
        iraproductos(item) {
            this.buscar = ''
            this.categoriaselecta = item.nombre
            if (this.listafiltrada != '') {
                this.activaproductos = true
            }

        },
        abre_cantidad(item) {
            this.cantidad = 1
            this.selecto = item
            this.dialo_cantidad = true
        },
        agregaCatalogo() {
            var item = this.selecto
            var cant = parseFloat(this.cantidad)
            item.cantidad = cant
            this.dialo_cantidad = false
            if (store.state.configuracion.persistencia_catalogo) {
                this.dialog_progress()

            } else {
                store.commit('dialogoproductos', false)
                this.activaproductos = false
                this.buscar = ''
            }

            this.$emit('array', item)
        },
        dialog_progress() {
            this.progress = true
            setTimeout(() =>
                this.progress = false, 400);
            this.buscar = ''
        }
    },
    computed: {
        listafiltrada() {
            var invent = store.state.productos
            if (store.state.configuracion.ordencategorias) {
                return invent.filter((item) =>
                        (item.activo) == true)
                    .filter((item) => (item.categoria)
                        .toLowerCase().includes(this.categoriaselecta.toLowerCase()))
                    .filter((item) => (item.nombre)
                        .toLowerCase().includes(this.buscar.toLowerCase()))
            } else {
                return invent.filter((item) =>
                        (item.activo) == true)
                    .filter((item) => (item.codbarra + item.nombre)
                        .toLowerCase().includes(this.buscar.toLowerCase()))
            }

        }
    }
}
</script>
