<template>
  <v-dialog v-model="dial" max-width="460px">
    <div>
      <v-system-bar window dark>
        <v-icon @click="cierra()">mdi-close</v-icon>
        <v-spacer></v-spacer>
      </v-system-bar>
    </div>

    <v-card class="text-center pa-5">
      <croppa :placeholder-font-size="19" canvas-color="transparent" placeholder-color="#000" :width="300" :height="300"
        :initial-image="imagenURL" v-model="myCroppa">
      </croppa>
      <v-btn color="success" @click="uploadCroppedImage()" block>SUBIR LOGO</v-btn>
    </v-card>

    <v-dialog v-model="progress" max-width="200">
      <v-card>
        <v-progress-circular :rotate="90" :size="200" :width="15" :value="Uploadvalue" color="red">
          {{ Uploadvalue }}
        </v-progress-circular>
      </v-card>

    </v-dialog>

  </v-dialog>
</template>

<script>
import {
  guardaArchivo,
  guardaRutaArchibo,
  consultaArchivo
} from '../../db'
import store from '@/store/index'
export default {

  data() {
    return {
      dial: false,
      myCroppa: {},
      value: '',
      progress: false,
      selectedFile: null,
      Uploadvalue: 0,
      imagenURL: ''
    }
  },
  created() {
    this.dial = true
  },
  mounted() {
    consultaArchivo().on("value", this.onDataChange);
  },
  beforeDestroy() {
    consultaArchivo().off("value", this.onDataChange);
  },
  methods: {
    onDataChange(items) {
      this.imagenURL = items.val().logoEmpresa

    },
    uploadCroppedImage() {

      this.myCroppa.generateBlob((blob) => {
        if (blob == null) {
          guardaRutaArchibo('', "logoEmpresa")
          store.commit('dialogoLogoEmpresa')
        } else {
          this.progress = true
          var task = guardaArchivo(store.state.baseDatos.bd, blob, 'logoEmpresa')
          task.on('state_changed', snapshot => {
            let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.Uploadvalue = porcentaje
          }, error => {
            console.log(error.message)
          },
            () => {
              this.Uploadvalue = 100;
              task.snapshot.ref.getDownloadURL().then((url) => {
                console.log("exito")
                guardaRutaArchibo(url, "logoEmpresa")
                this.progress = false
              })
            }
          )
        }
      }, 'image/webp', 0.8) // 80% compressed jpeg file

    },
    cierra() {
      this.$emit('cierra', false)
    }

  }

}
</script>

<style>
.croppa-container:hover {
  opacity: 1;
  background-color: #efed8a;
}
</style>
