<template>
    <div class="pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n6">
                <v-row class="mt-n3 mb-1 pa-2">

                    <v-col cols="4">
                        <h5>Control de inventario</h5>
                        <v-btn color="success" class="" block small @click="abre_crea()">Nuevo
                            Cuadre</v-btn>
                    </v-col>
                    <v-col cols="1">

                    </v-col>
                    <v-col cols="2">
                        <v-select :disabled="!$store.state.permisos.master" :items="array_ubica" label="Ubicacion" dense
                            outlined v-model="ubicacion"></v-select>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date2" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-btn color="info" class="" block small @click="busca_cuadres()">filtra</v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-card>

                <v-simple-table dense fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Stock Sistema
                                </th>
                                <th class="text-left">
                                    Stock Fisico
                                </th>
                                <th class="text-left">
                                    Faltantes
                                </th>
                                <th class="text-left">
                                    Retirados
                                </th>
                                <th class="text-left">
                                    Resp. Inventario
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.total_monturas }}</td>
                                <td style="font-size:85%;">{{ item.total_fisico }}</td>
                                <td style="font-size:85%;" class="red--text">{{ item.total_monturas - item.total_fisico
                                    }}
                                </td>
                                <td style="font-size:85%;" class="red--text">{{ item.total_eliminado }}
                                </td>
                                <td style="font-size:85%;">{{ item.observacion }}</td>
                                <td width="100">
                                    <v-row dense>
                                        <v-col cols="6">
                                            <v-icon color="green" @click.prevent="abre_edita(item)">mdi-pencil</v-icon>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-icon color="red" @click.prevent="abre_edita_config(item)"
                                                :disabled="!$store.state.permisos.master">mdi-cog</v-icon>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>

        </v-card>

        <v-dialog v-model="dial" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-icon v-if="true" color="success" large @click="guarda_final()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row class="mt-1" dense>
                    <v-col cols="12">
                        <v-text-field outlined dense rounded id="cod" v-model="codigo" label="Codigo"
                            append-icon="mdi-barcode-scan" @click:append="buscaProducto()"
                            @keyup.enter="buscaProducto()" :autofocus="!$store.state.esmovil"></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table id="daa" height="52vh" dense :headers="headers" :items="list_mom" mobile-breakpoint="1">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.id }}-{{ item.marca }}</td>
                            <td>{{ item.modelo }}</td>
                            <td>
                                <v-icon color="#FFB200" v-if="item.estado == 'PENDIENTE'">mdi-circle</v-icon>
                                <v-icon color="#46FF00" v-if="item.estado == 'ACEPTADO'">mdi-circle</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_crea" max-width="450px">
            <v-card class="pa-6 mx-auto text-center">
                <v-row dense>
                    <p>Tiene a la fecha un total de <span class="red--text">{{ total_monturas }} </span>monturas</p>
                </v-row>
                <v-textarea style="font-size:85%;" outlined dense v-model="obs" auto-grow filled
                    label="Persona responsable de inventario" rows="1"></v-textarea>
                <v-btn color="green darken-1" block dark @click="abre_nuevo()">
                    Empezar
                </v-btn>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_config" max-width="450px">
            <v-card class="pa-6 mx-auto text-center">
                <v-row dense>
                    <p>Tiene a la fecha un total de <span class="red--text">{{ item_selecto.total_monturas -
                            item_selecto.total_fisico
                            }}</span> monturas faltantes deseas quitarlas?</p>
                </v-row>
                <v-textarea style="font-size:85%;" outlined dense v-model="obs" auto-grow filled
                    label="Persona responsable de inventario" rows="1"></v-textarea>
                <v-btn color="green darken-1" block dark @click="quitar_inventario()">
                    Quitar de Inventario
                </v-btn>
                <v-btn class="mt-2" color="error" block dark @click="reporte_inventario()">
                    REPORTE FALTANTES
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import XLSX from 'xlsx'
import {
    allMonturas,
    editaMontura,
    nuevacuadre,
    allcuadre,
    editacuadre,
    all_pp_cuadre
} from '../../db'
export default {

    data() {
        return {
            dial_config: false,
            dial_crea: false,
            array_monturas: [],
            dial: false,
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: [],
            lista_monturas: [],
            codigo: '',
            origen: 'almacen',
            obs: '',
            item_selecto: [],
            total_monturas: 0,
            ubicacion: 'almacen',
            array_ubica: [],
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'modelo',
                value: 'modelo',
            },
            {
                text: 'Accion',
                value: 'accion',
            },
            ],
        }
    },
    created() {
        this.array_ubica = store.state.tiendas
        this.array_ubica.unshift('almacen')
        this.ubicacion = store.state.permisos.tienda
        this.origen = store.state.permisos.tienda
        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
        this.busca_cuadres()
    },
    computed: {
        listafiltrada() {
            return this.desserts
        },
        list_mom() {
            store.state.dialogoprogress
            if (this.item_selecto.id != undefined) {
                // all_pp_cuadre(this.item_selecto.id).on("value", this.onDataChange_);
                //console.log(this.lista_monturas)
                return this.lista_monturas
            }

        },
    },
    methods: {
        onDataChange_(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();

                array.push(data);
            });
            //  console.log(array)
            this.lista_monturas = array;
        },
        async busca_cuadres() {
            store.commit("dialogoprogress", 1)
            var snap = await allcuadre().orderByChild('ubicacion').equalTo(this.ubicacion).once('value')
            if (snap.exists()) {
                this.desserts = snap.val()
            }
            store.commit("dialogoprogress", 1)
        },
        async quitar_inventario() {
            if (confirm('Esta seguro de querer quitar las monturas faltantes del inventario?')) {
                store.commit("dialogoprogress", 2)
                var array = this.item_selecto.invent_actual.filter(item => item.estado != 'ACEPTADO')
                for (var i = 0; i < array.length; i++) {
                    var data = array[i]
                    if (data.estado != 'ACEPTADO') {
                        await editaMontura(data.id + '/stock', 0)
                    }
                }
                await editacuadre(this.item_selecto.id + '/total_eliminado', array.length)
                store.commit("dialogoprogress", 2)
            }
        },
        async reporte_inventario() {
            var array = this.item_selecto.invent_actual
            var array_falt = []
            for (var i = 0; i < array.length; i++) {
                var data = array[i]
                console.log(data.estado)
                if (data.estado == undefined || data.estado == 'PENDIENTE') {

                    array_falt.push(data)
                }
            }
            this.exporta_excel(array_falt)
        },
        exporta_excel(va) {
            store.commit("dialogoprogress")
            var data = XLSX.utils.json_to_sheet(va)
            const workbook = XLSX.utils.book_new()
            const filename = 'rep'
            XLSX.utils.book_append_sheet(workbook, data, "faltantes")
            XLSX.writeFile(workbook, `${filename}.xlsx`)
            store.commit("dialogoprogress")
        },
        abre_edita_config(data) {
            this.item_selecto = data
            this.dial_config = true
        },
        async guarda_final() {
            var array = this.lista_monturas.filter(item => item.estado == 'ACEPTADO')
            editacuadre(this.item_selecto.id + '/total_fisico', array.length)
            this.dial = false
        },
        async abre_crea() {
            var array = []
            var snap = await allMonturas().orderByChild('ubicacion').equalTo(this.ubicacion).once('value')
            snap.forEach((item) => {
                let data = item.val();
                if (data.stock != 0) {
                    data.estado = 'PENDIENTE'
                    array.push(data)
                }
            });
            this.total_monturas = array.length
            this.array_monturas = array
            this.dial_crea = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        async abre_nuevo() {
            store.commit("dialogoprogress", 1)
            var fecha = moment().unix()
            var array = {
                id: fecha,
                fecha: fecha,
                ubicacion: this.ubicacion,
                data: [],
                observacion: this.obs,
                estado: 'PENDIENTE',
                invent_actual: this.array_monturas,
                total_monturas: this.total_monturas,
                total_fisico: 0
            }
            this.item_selecto = array
            await nuevacuadre(array.id, array)
            store.commit("dialogoprogress", 1)
            await this.busca_cuadres()
            this.dial_crea = false
            //this.dial = true
        },
        async abre_edita(data) {
            await this.busca_cuadres()
            this.item_selecto = data
            this.lista_monturas = data.invent_actual
            this.dial = true
        },
        async buscaProducto() {
            var array_selecto = []
            if (this.codigo[0] == 'M') {
                var cod = this.codigo
            } else {
                var cod = 'M' + this.codigo.toString().padStart(6, 0)
            }
            store.commit("dialogoprogress", 1)
            await new Promise(resolve => setTimeout(resolve, 100))
            var snap = await all_pp_cuadre(this.item_selecto.id).orderByChild('id').equalTo(cod).once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    let data = item.val();
                    var key = item.key
                    data.key = key
                    array_selecto = data
                });

                if (array_selecto.estado == 'ACEPTADO') {
                    await new Promise(resolve => setTimeout(resolve, 500))
                    store.commit("dialogoprogress", 1)
                    alert('MONTURA YA FUE ESCANEADO')
                    this.codigo = ''
                    document.getElementById('cod').focus();
                    return
                }
                array_selecto.estado = 'ACEPTADO'
                var i = this.lista_monturas.map(e => e.id).indexOf(cod)
                console.log(this.lista_monturas[i])
                this.lista_monturas[i].estado = 'ACEPTADO'
                await editacuadre(this.item_selecto.id + '/invent_actual/' + array_selecto.key + '/estado', 'ACEPTADO')
                this.codigo = ''
                await new Promise(resolve => setTimeout(resolve, 400))
                store.commit("dialogoprogress", 1)
                document.getElementById('cod').focus()
            } else {
                await new Promise(resolve => setTimeout(resolve, 400))
                store.commit("dialogoprogress", 1)
                alert('MONTURA NO EXISTE')
                this.codigo = ''
                document.getElementById('cod').focus();
                return
            }
        },
        async elimina(item) {
            var i = this.lista_monturas.map(e => e.id).indexOf(item.id)
            this.lista_monturas.splice(i, 1)
            editacuadre(this.item_selecto.id + '/data', this.lista_monturas)
            editacuadre(this.item_selecto.id + '/cantidad', this.lista_monturas.length)
        }
    },

}
</script>
