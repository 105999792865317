<template>
<v-container class="mt-8">
    <v-row class="mt-3" dense>
        <v-col cols="6" class="pa-3" md="4" sm="4" xs="6">
            <v-card @click.prevent="rawbt()">
                <v-container>
                    <v-img class="mx-auto" height="35" width="35" src="/cell.png"></v-img>
                    <h4 block class="text-center">RAW BT</h4>
                </v-container>
            </v-card>
        </v-col>

        <v-col cols="6" class="pa-3" md="4" sm="4" xs="6">
            <v-card @click.prevent="rawbt()">
                <v-container>
                    <v-img class="mx-auto" height="35" width="35" src="/disc.png"></v-img>
                    <h4 block class="text-center">Windows Print</h4>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    data() {
        return {

        }
    },

    methods: {
        rawbt() {
            window.open("https://firebasestorage.googleapis.com/v0/b/mitienda-f5ef8.appspot.com/o/catalogo%2FRawBT_v5.0.2.apk?alt=media&token=4755ba9c-c41a-4b5d-a4b0-9694087a1964")
        }
    }

}
</script>

<style>

</style>
