<template>
    <v-dialog v-model="dial_activo" max-width="1200" transition="dialog-top-transition" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-toolbar-title v-if="$refs.calendar" @click="value = ''">
                    HOY : {{ hoy }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
                    <v-icon large color="red">mdi-minus</v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-btn icon class="ma-2" @click="$refs.calendar.next()">
                    <v-icon large color="green">mdi-plus</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="ma-2" color="red" x-small @click="proximo_a()">
                    Proximo Año
                </v-btn>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <template>
                <v-row class="fill-height">
                    <v-col>
                        <v-sheet height="600">
                            <v-calendar ref="calendar" v-model="value" :weekdays="weekday" :type="type" :events="events"
                                locale="es" color="primary" @change="getEvents" @click:date="viewDay"
                                @click:more="viewmore" @click:event="showEvent"></v-calendar>
                        </v-sheet>
                    </v-col>
                </v-row>
            </template>
        </v-card>
        <v-dialog v-model="dial_agrega" max-width="600" transition="dialog-top-transition" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_agrega = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ value }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3 text-center">
                <v-row dense>
                    <v-time-picker :min="start" :max="end" v-model="time" full-width
                        :landscape="$vuetify.breakpoint.smAndUp">
                        <template>
                            <div>
                                <v-card @click="programar_hora()" :disabled="time == null">
                                    <v-container>
                                        <v-img class="mx-auto" height="40" width="40" src="/reloj.png"></v-img>
                                        <h6 block class="text-center">Programar</h6>
                                    </v-container>
                                </v-card>
                            </div>
                        </template>
                    </v-time-picker>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_evento" max-width="500" transition="dialog-top-transition" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_evento = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3 text-center">
                <v-card class="pa-3 text-center" elevation="10">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <h5><strong class="red--text">CITA PACIENTE :</strong> {{ item_selecto.name }}</h5>
                        </v-col>
                        <v-col cols="12">
                            <h5><strong class="red--text">FECHA / HORA :</strong> {{ item_selecto.start }}</h5>
                        </v-col>
                    </v-row>
                </v-card>
                <v-row class="mt-3">
                    <v-col cols="4">
                        <v-card @click="eliminar()">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/anular.png"></v-img>
                                <h6 block class="text-center">Anular</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card @click="reprogramar()">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/reloj.png"></v-img>
                                <h6 block class="text-center">Reprogramar</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card @click="atender()">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/doc.png"></v-img>
                                <h6 block class="text-center">Atender</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_more" max-width="800" transition="dialog-top-transition" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_more = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h4>CRONOGRAMA DE ATENCION DIARIA</h4>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3 text-center">
                <v-simple-table class="elevation-1" fixed-header height="60vh" dense>
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-left">
                                    Paciente
                                </th>
                                <th class="text-left">
                                    Inicio
                                </th>
                                <th class="text-left">
                                    Fin
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_more" :key="item.id" @click="selecciona_more(item)">
                                <td style="font-size:80%;" class="text-left">{{ item.name }}</td>
                                <td style="font-size:80%;" class="text-left">{{ item.start }}</td>
                                <td style="font-size:80%;" class="text-left">{{ item.end }}</td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import nuevo_cli from '@/views/optometria/busca_cliente'
import lista_productos from '@/components/almacen/lista_productos'
import moment from 'moment'
import store from '@/store/index'
import {
    nuevo_cita,
    allcitas,
    elimina_cita
} from '../../db'
export default {
    components: {
        nuevo_cli,
        lista_productos,
    },
    props: {
        data: '',
        mes: ''
    },
    name: 'caja',
    data() {
        return {
            dial_more: false,
            start: '09:00',
            end: '20:00',
            dial_evento: false,
            time: null,
            menu2: false,
            modal2: false,
            dial_agrega: false,
            type: 'month',
            mode: 'stack',
            weekday: [1, 2, 3, 4, 5, 6, 0],
            value: '',
            events: [],
            hoy: moment(String(new Date)).format('DD-MM'),
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            item_selecto: [],
            array_more: []
        }
    },
    created() {
        this.ini()
    },
    mounted() {
        this.$refs.calendar.checkChange()
        allcitas().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allcitas().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {

            allcitas().on("value", this.onDataChange);
            return this.desserts
        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                data.id = item.key
                data.start = moment.unix(data.inicio).format('YYYY-MM-DD hh:mm')
                data.end = moment.unix(data.fin).format('YYYY-MM-DD hh:mm')
                data.day = moment.unix(data.dia).format('YYYY-MM-DD')
                data.color = this.colors[this.rnd(0, this.colors.length - 1)]
                if (data.estado != 'ATENDIDO') {
                    array.push(data);
                }

            });
            array.sort(function (a, b) {
                if (a.inicio > b.inicio) {
                    return 1;
                }
                if (a.inicio < b.inicio) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            this.events = array;
        },
        async ini() {

            this.value = moment(String(new Date())).add(this.mes, 'M').format('YYYY-MM-DD')
            this.dial_activo = true

        },
        async programar_hora() {
            this.time = '01:00'
            var date = this.value + ' ' + this.time
            console.log(date)
            var inicios = moment(String(date)).add(1, 'm')
            var fins = moment(String(date)).add(30, 'm')
            var continua = true
            var array = this.events.filter(item => item.day == this.value)
            for (var i = 0; i < array.length; i++) {
                var dat = array[i]
                var uno = moment.unix(dat.inicio)
                var dos = moment.unix(dat.fin)
                //activar en caso no quire q se crucen las horas
                /* if (inicios.isBetween(uno, dos)) {
                     continua = false
                 }
                 if (fins.isBetween(uno, dos)) {
                     continua = false
                 }*/
            }
            if (continua) {
                store.commit("dialogoprogress", 1)
                var date = this.value + ' ' + this.time
                var inicio = moment(String(date)) / 1000
                var fin = moment(String(date)).add(30, 'm') / 1000
                var array = {
                    dni: this.data.documento,
                    name: this.data.nombre,
                    inicio: inicio,
                    fin: fin,
                    dia: inicio,
                    color: 'red',
                    timed: 1,
                    estado: 'PENDIENTE'
                }
                var result = await nuevo_cita(array)
                this.dial_agrega = false
                store.commit("dialogoprogress", 1)
                this.emite_id_cita(result.key)
            } else {
                alert('LA HORA PROGRAMADA SE CRUZA CON OTRA CITA! VERIFICAR')
            }

        },
        emite_id_cita(key) {
            this.$emit('id_cita', key)
        },
        async reprogramar() {
            if (confirm('SEGURO QUE DESEA REPROGRAMAR ESTA CITA?')) {
                this.data = {
                    documento: this.item_selecto.dni,
                    nombre: this.item_selecto.name
                }
                this.dial_evento = false
                await new Promise(resolve => setTimeout(resolve, 500))
                alert('SELECCIONE UNA NUEVA FECHA')
                await new Promise(resolve => setTimeout(elimina_cita(this.item_selecto.id), 500))
            }
        },
        async atender() {
            this.$emit('atender', this.item_selecto)
        },
        viewmore({
            date
        }) {
            this.array_more = this.events.filter(item => item.day == date)
            this.dial_more = true
        },
        viewDay({
            date
        }) {
            this.item_selecto = date
            console.log(moment(String(date)).day())
            if (moment(String(date)).day() == 0) {
                this.start = '10:00'
                this.end = '14:00'
            } else {
                this.start = '10:00'
                this.end = '20:00'
            }
            console.log(this.data)
            if (this.data != undefined) {
                this.programar_hora()
            }
        },
        showEvent({
            nativeEvent,
            event
        }) {
            console.log(event)
            this.item_selecto = event
            this.dial_evento = true
        },
        selecciona_more(value) {
            this.item_selecto = value
            this.dial_more = false
            this.dial_evento = true
        },
        getEvents({
            start,
            end
        }) {
            console.log(start, end)
        },
        proximo_a() {
            this.value = moment(String(new Date())).add(12, 'M').format('YYYY-MM-DD')
        },
        cierra() {
            this.$emit('cierra', false)
        },
        rnd(a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        eliminar() {
            if (confirm('SEGURO QUE DESEA ELIMINAR ESTA CITA?')) {
                elimina_cita(this.item_selecto.id)
            }
            this.dial_evento = false
        },
    },

}
</script>
