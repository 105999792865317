<template>
    <v-dialog v-model="dial_L" max-width="750">
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="busc_cliente" append-icon="mdi-magnify" label="Search" single-line hide-details
                    dense></v-text-field>
            </v-card-title>
            <v-data-table class="elevation-1 mt-1" fixed-header height='70vh' :headers="headers_cliente"
                :items="listafiltradaclientes" :items-per-page="15" hide-default-footer mobile-breakpoint="1" dense>
                <template v-slot:item="{ item }">
                    <tr @click="agregacliente(item)">
                        <td style="font-size:75%;">{{ item.documento }}</td>
                        <td style="font-size:75%;">{{ item.nombre }}</td>
                        <td style="font-size:75%;">{{ item.direccion1 }}</td>
                    </tr>

                </template>
            </v-data-table>

        </v-card>

    </v-dialog>
</template>

<script>
import store from '@/store/index'
export default {
    props: {
        dial_L: ''
    },
    data() {
        return {
            headers_cliente: [{
                text: 'N° Doc',
                value: 'vendedor'
            },
            {
                text: 'Nombre',
                value: 'nombre_cliente'
            },
            ],
            busc_cliente: '',
            clienteslista: []
        }
    },
    created() {
        this.initialize()
    },
    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.clienteslista = store.state.clientes
        },
        agregacliente(item) {
            this.$emit('array', item)
        },
        cierra() {
            this.$emit('cierra', false)
        }

    },
    computed: {
        listafiltradaclientes() {
            return this.clienteslista.filter((item) =>
                (item.documento + item.nombre)
                    .toLowerCase().includes(this.busc_cliente.toLowerCase()))
        },
    }
}
</script>
