<template>
    <div class="div-1 pa-3">
        <v-card shaped elevation="10" class="card-1 mx-auto mt-10" color="white" width="500px">
            <v-card-title primary-title class="text-center">

            </v-card-title>
            <div>
                <p><span class="black--text">Cliente:</span> {{ this.nom_cliente }}</p>
            </div>
            <v-card-text>
                <v-row dense class="text-center">
                    <v-col cols="12">
                        <v-card @click.prevent="imprime_pdf()">
                            <v-container>
                                <v-img class="mx-auto" height="100" width="100" src="/favicon.png"></v-img>
                                <h4 block class="text-center pa-1">Descargar Proforma</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-img src="/power-by-cloud.png" max-width="250" max-height="250" class="mx-auto"></v-img>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import {
    solicitaPermiso,
    ObtenerToken
} from '../../sendMessaging'
import {
    generaproforma
} from '../../pdf_proforma'
import receta from '@/components/dialogos/dialog_receta'
import {
    buscaProformas_ext,
} from '../../db'
export default {
    name: "App",
    components: {
        receta,
    },
    data: function () {
        return {
            dial_receta: false,
            id_proforma: '',
            proforma_selecta: '',
            bd: '',
            nom_cliente: ''
        };
    },
    created() {
        this.bd = this.$route.params.bd
        this.id_proforma = this.$route.params.id_proforma
        solicitaPermiso()
        this.inicia()
    },
    methods: {
        async inicia() {
            console.log(this.bd, this.id_proforma)
            var snap = await buscaProformas_ext(this.bd, this.id_proforma).once('value')
            if (snap.exists()) {
                console.log(snap.val())
                this.proforma_selecta = snap.val()
                this.nom_cliente = snap.val().cliente_nombre
            } else {
                alert('PROBLEMA INTERNO COMUNIQUESE CON ADMINISTRADOR')
            }
        },
        imprime_pdf() {
            generaproforma(this.proforma_selecta, 'A4')
        },
        GuardaToken() {

            ObtenerToken().getToken().then((currentToken) => {
                if (currentToken) {
                    console.log('token = ', currentToken);
                } else {
                    console.log('No Instance ID token available. Request permission to generate one.');
                    updateUIForPushPermissionRequired();
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        }
    },

};
</script>

<style scoped>
.div-1 {
    background-color: #333030;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    position: relative;
}

.card-1 {
    align-items: center;
}
</style>
