<template>
    <v-dialog v-model="dial" max-width="460px">
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="grabaContador()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-6">
            <v-col>
                <v-text-field dense v-model="ip_cocina" label="IP HOST IMPRESORA"></v-text-field>
            </v-col>
            <v-row class="mt-n5" dense>
                <v-col cols="6">
                    <v-checkbox dense v-model="guardadocumento" label="Guardar Auto"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="telfcliente" label="Telf Cliente"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="refcliente" label="Ref Cliente"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox dense v-model="placa_cliente" label="Placa Vehiculo"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-text-field dense v-model="tamano" label="Impresora Caja"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field dense v-model="tamano" label="Impresora Caja"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field type="number" dense v-model="mizquierdo" label="Margen Izquierdo"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field type="number" dense v-model="mderecho" label="Margen Derecho"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field type="number" dense v-model="msuperior" label="Margen Superior"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field type="number" dense v-model="minferiorgeneral" label="Margen Inferior"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field type="number" dense v-model="minferior" label="Margen logo inferior"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field dense v-model="telefono" label="Telefono"></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                    <v-textarea dense outlined auto-grow filled v-model="cabecera" label="Cabecera" rows="1"></v-textarea>
                </v-col>
                <v-col cols="12" class="mt-n6">
                    <v-textarea dense outlined auto-grow filled v-model="piepagina" label="Mensaje final"
                        rows="1"></v-textarea>
                </v-col>
            </v-row>

        </v-card>

    </v-dialog>
</template>

<script>
import {
    obtenerImpresoras,
    actualizaImpresoras
} from '../../db'
import store from '@/store/index'

export default {

    data() {
        return {
            dial: false,
            ip_cocina: "192.168.1.5",
            guardadocumento: false,
            impresoracocina: false,
            tamano: '58',
            mizquierdo: '5',
            mderecho: '3',
            piepagina: '',
            cabecera: '',
            telefono: '',
            msuperior: '5',
            minferior: '40',
            minferiorgeneral: '10',
            refcliente: false,
            telfcliente: false,
            placa_cliente: false

        }
    },
    created() {
        this.dial = true
        this.initialize()
    },
    mounted() {

    },
    methods: {
        initialize() {
            this.desserts = []
            obtenerImpresoras().once("value").then((snapshot) => {
                if (snapshot.exists) {
                    this.ip_cocina = snapshot.val().ip_cocina
                    this.guardadocumento = snapshot.val().guardadocumento
                    this.impresoracocina = snapshot.val().impresoracocina
                    this.tamano = snapshot.val().tamano
                    this.mizquierdo = snapshot.val().mizquierdo
                    this.mderecho = snapshot.val().mderecho
                    this.cabecera = snapshot.val().cabecera
                    this.piepagina = snapshot.val().piepagina
                    this.telefono = snapshot.val().telefono
                    this.msuperior = snapshot.val().msuperior
                    this.minferior = snapshot.val().minferior
                    this.minferiorgeneral = snapshot.val().minferiorgeneral
                    this.telfcliente = snapshot.val().telfcliente
                    this.refcliente = snapshot.val().refcliente
                    this.placa_cliente = snapshot.val().placa_cliente
                } else {
                    this.ip_cocina = "192.168.1.5"
                    this.guardadocumento = false
                    this.impresoracocina = false
                    this.tamano = '58'
                    this.mizquierdo = '5'
                    this.mderecho = '3'
                    this.cabecera = ''
                    this.piepagina = ''
                    this.telefono = ''
                    this.msuperior = '5'
                    this.minferior = '45'
                    this.minferiorgeneral = '10'
                    this.refcliente = false
                    this.telfcliente = false
                    this.placa_cliente = false
                }
            })
        },
        grabaContador() {
            var array = {}
            array = {
                ip_cocina: this.ip_cocina,
                guardadocumento: this.guardadocumento,
                impresoracocina: this.impresoracocina,
                pdfInMM: this.tamano,
                lMargin: this.mizquierdo,
                rMargin: this.mderecho,
                msuperior: this.msuperior,
                minferior: this.minferior,
                minferiorgeneral: this.minferiorgeneral,
                cabecera: this.cabecera,
                piepagina: this.piepagina,
                telefono: this.telefono,
                refcliente: this.refcliente,
                tefcliente: this.telfcliente,
                placa_cliente: this.placa_cliente

            }
            store.commit("configImpresora", array)
            actualizaImpresoras('ip_cocina', this.ip_cocina)
            actualizaImpresoras('guardadocumento', this.guardadocumento)
            actualizaImpresoras('impresoracocina', this.impresoracocina)
            actualizaImpresoras('tamano', this.tamano)
            actualizaImpresoras('mizquierdo', this.mizquierdo)
            actualizaImpresoras('mderecho', this.mderecho)
            actualizaImpresoras('msuperior', this.msuperior)
            actualizaImpresoras('minferior', this.minferior)
            actualizaImpresoras('cabecera', this.cabecera)
            actualizaImpresoras('piepagina', this.piepagina)
            actualizaImpresoras('minferiorgeneral', this.minferiorgeneral)
            actualizaImpresoras('telefono', this.telefono)
            actualizaImpresoras('refcliente', this.refcliente)
            actualizaImpresoras('telfcliente', this.telfcliente)
            actualizaImpresoras('placa_cliente', this.placa_cliente)
            this.cierra()
        },
        cierra() {
            this.$emit('cierra', false)
        }

    }

}
</script>
