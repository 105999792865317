<template>
    <div class="mb-6 pa-4 mt-3">
        <h4>MONTURAS {{ $store.state.permisos.tienda }}</h4>
        <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n2 mb-n3' : 'mb-n3 mt-n2'">
            <v-col cols="12" md="6" xs="12">
                <v-card elevation="12" class="mt-1 mb-4" color="white" @click="abre_invent()">
                    <v-container>
                        <v-img class="mx-auto mt-n2 mb-n2" height="35" width="35" src="/producto.png"></v-img>
                        <p class="text-center mb-n2 mt-2"> Control Inventario</p>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" xs="12">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="pa-3">

            <v-data-table :headers="headers" :search="buscar" :items="listafiltrada" dense :items-per-page="17"
                mobile-breakpoint="1">
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="12">
                            <v-icon color="green" @click="editar(item)"
                                :disabled="!$store.state.permisos.master">mdi-lead-pencil</v-icon>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="700px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agregar()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="dialogo_elimina = !dialogo_elimina">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-text-field disabled dense v-model="ubicacion" label="Ubicacion" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field readonly @click:append="activa_proveedor = true" append-icon="mdi-magnify" outlined
                            dense type="text" v-model="proveedor" label="Proveedor"> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense disabled v-model="id" label="ID" outlined></v-text-field>
                    </v-col>

                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="marca" auto-grow filled label="Marca" rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field dense v-model="modelo" label="Modelo" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="tipo" :items="array_tipo" menu-props="auto" hide-details outlined
                            label="Tipo"></v-select>
                    </v-col>

                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field dense v-model="color" label="Color" outlined>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="material" :items="$store.state.tipo_material_montura" menu-props="auto"
                            hide-details outlined label="MAterial"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="costo" label="costo"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio_min"
                            label="Precio minimo"></v-text-field>
                    </v-col>

                </v-row>
                <v-row class="mt-n8">

                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio" label="Precio venta"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_elimina" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_elimina = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Esta seguro de Eliminar?</h4>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block @click="eliminar()" color="orange">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <t_proveedor v-if="activa_proveedor" :edicion="false" @cierra="activa_proveedor = false"
            @array="selec_proveedor($event)" />
        <t_colores :dial="activa_colores" :edicion="false" @cierra="activa_colores = false" @array="selec_color($event)" />
    </div>
</template>

<script>
import t_proveedor from '@/components/configEmpresa/tabla_proveedor'
import t_colores from '@/components/configEmpresa/tabla_colores'
import axios from "axios"
import {
    allMonturas,
    nuevaMontura,
    eliminaMontura
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import store from '@/store/index'
export default {
    name: 'caja',
    components: {
        t_proveedor,
        t_colores
    },
    data() {
        return {
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Modelo',
                value: 'modelo',
            },
            {
                text: 'Tipo',
                value: 'tipo',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ],
            activa_proveedor: false,
            activa_colores: false,
            dialogo_agrega: false,
            dialogo_elimina: false,
            array_tabla: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            tipoproducto: "BIEN",
            operacion: "GRAVADA",
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA',
                'INAFECTA'
            ],
            array_tipo: [
                'AL AIRE',
                'SEMI AL AIRE',
                'ARO COMPLETO'
            ],
            edit: false,
            tipo: 'AL AIRE',
            id: '',
            marca: '',
            nombre: '',
            proveedor: '',
            modelo: '',
            color: '',
            costo: '',
            precio_min: '',
            precio: '',
            stock: 1,
            material: 'ACETATO',
            id_color: '',
            buscar: '',
            ubicacion: ''
        }
    },

    computed: {
        listafiltrada() {
            this.array_tabla = store.state.monturas
            return this.array_tabla
        }
    },

    methods: {
        selec_proveedor(data) {
            this.proveedor = data.rsocial
            this.activa_proveedor = false
        },
        selec_color(data) {
            this.color = data.nombre
            this.id_color = data.codigo
            this.activa_colores = false
        },
        async nuevo() {
            this.edit = false
            var a = await obten_contador_externo("orden_monturas")
            this.id = "M" + a
            this.tipo = 'AL AIRE'
            this.marca = ''
            this.modelo = ''
            this.proveedor = ''
            this.color = ''
            this.id_color = ''
            this.costo = ''
            this.precio_min = ''
            this.stock = 1
            this.precio = ''
            this.ubicacion = 'Almacen'
            this.dialogo_agrega = true
        },
        async agregar() {
            if (!this.edit) {
                var a = await obten_contador_externo("orden_monturas")
                this.id = "M" + a
            }
            store.commit("dialogoprogress", 1)
            var array = {
                tipoproducto: this.tipoproducto,
                operacion: this.operacion,
                id: this.id,
                tipo: this.tipo,
                marca: this.marca,
                modelo: this.modelo,
                proveedor: this.proveedor,
                color: this.color,
                id_color: this.id_color,
                material: this.material,
                costo: this.costo,
                precio_min: this.precio_min,
                stock: this.stock,
                precio: this.precio,
                ubicacion: this.ubicacion
            }
            await nuevaMontura(this.id, array)
            store.commit("dialogoprogress", 1)
            if (!this.edit) {
                await sumarCorrelativo_externo("orden_monturas", a)
                this.costo = ''
                this.modelo = ''
                this.stock = 1
                this.precio = ''
                this.precio_min = ''
                this.material = ''
            } else {
                this.dialogo_agrega = false
            }
        },
        editar(data) {
            this.edit = true
            this.tipoproducto = data.tipoproducto
            this.operacion = data.operacion
            this.id = data.id
            this.tipo = data.tipo
            this.marca = data.marca
            this.modelo = data.modelo
            this.proveedor = data.proveedor
            this.color = data.color
            this.id_color = data.id_color
            this.material = data.material
            this.costo = data.costo
            this.precio_min = data.precio_min
            this.stock = data.stock
            this.precio = data.precio
            this.ubicacion = data.ubicacion
            this.dialogo_agrega = true
        },
        eliminar() {
            eliminaMontura(this.id)
            this.dialogo_elimina = false
            this.dialogo_agrega = false
        },
        cierra() {
            this.$emit('cierra', false)
        },
        abre_invent() {
            this.$router.push({
                name: 'inventario_monturas'
            })
        },
    },

}
</script>
