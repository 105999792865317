<template>
    <v-dialog v-model="dial_L" max-width="460px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="grabaContador()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-4">
            <v-row dense>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_monturas" label="Orden Monturas"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_accesorios" label="Orden Accesorios"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_productos" label="Orden Productos"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_lentes_c" label="Orden Lentes Contacto"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_pacientes" label="Orden Pacientes"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenboleta" label="Orden Boletas"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenfactura" label="Orden Factura"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenticket" label="Orden Ticket"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenresumen" label="Orden ResumenBAja"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenncredito" label="Orden N.Credito B"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenncredito_f" label="Orden N.Credito F"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="ordenndebito" label="Orden N.Debito"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_pedidos" label="Orden Pedidos"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="orden_garantias" label="Orden Garantias"></v-text-field>
                </v-col>
            </v-row>
        </v-card>

    </v-dialog>
</template>

<script>
import {
    obtenContador,
    actualizaContador,
    obtenContador_externo,
    actualizaContador_externo
} from '../../db'
import store from '@/store/index'

export default {

    data() {
        return {
            dial_L: false,
            orden_accesorios: '000001',
            orden_monturas: '000001',
            orden_productos: '000001',
            orden_lentes_c: '000001',
            orden_pacientes: '00000001',
            ordenboleta: '00000001',
            ordenfactura: '00000001',
            ordenticket: '00000001',
            ordenresumen: '0001',
            ordenncredito: '0001',
            ordenncredito_f: '0001',
            ordenndebito: '0001',
            orden_pedidos: '0000001',
            orden_garantias: '0000001'
        }
    },
    mounted() {
        this.initialize()
    },
    created() {
        this.dial_L = true
    },

    methods: {
        initialize() {
            obtenContador().once("value").then((snapshot) => {
                var data = snapshot.val()
                this.orden_pacientes = data.orden_pacientes
                this.ordenboleta = data.ordenboleta
                this.ordenfactura = data.ordenfactura
                this.ordenticket = data.ordenticket
                this.ordenresumen = data.ordenresumen
                this.ordenncredito = data.ordenncredito
                this.ordenncredito_f = data.ordenncredito_f
                this.ordenndebito = data.ordenndebito
            })
            obtenContador_externo().once("value").then((snapshot) => {
                var data = snapshot.val()
                this.orden_pacientes = data.orden_pacientes
                this.orden_pedidos = data.orden_pedidos
                this.orden_garantias = data.orden_garantias
                this.orden_accesorios = data.orden_accesorios
                this.orden_monturas = data.orden_monturas
                this.orden_productos = data.orden_productos
                this.orden_lentes_c = data.orden_lentes_c
            })
        },
        grabaContador() {
            actualizaContador('ordenboleta', this.ordenboleta)
            actualizaContador('ordenfactura', this.ordenfactura)
            actualizaContador('ordenticket', this.ordenticket)
            actualizaContador('ordenresumen', this.ordenresumen)
            actualizaContador('ordenncredito', this.ordenncredito)
            actualizaContador('ordenncredito_f', this.ordenncredito_f)
            actualizaContador('ordenndebito', this.ordenndebito)
            actualizaContador_externo('orden_pacientes', this.orden_pacientes)
            actualizaContador_externo('orden_pedidos', this.orden_pedidos)
            actualizaContador_externo('orden_garantias', this.orden_garantias)
            actualizaContador_externo('orden_accesorios', this.orden_garantias)
            actualizaContador_externo('orden_monturas', this.orden_garantias)
            actualizaContador_externo('orden_productos', this.orden_garantias)
            actualizaContador_externo('orden_lentes_c', this.orden_lentes_c)
            this.cierra()
        },
        cierra() {
            this.$emit('cierra', false)
        }

    }

}
</script>
