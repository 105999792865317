<template>
<div class="mb-6 pa-4">
    <v-card>
        <div class="d-flex align-content-start flex-wrap mb-n4">
            <v-row class="mt-n2 pa-1">
                <v-col cols="8">

                    <v-autocomplete outlined dense autofocus label="Busca Cliente" auto-select-first v-model="busca_p" :items="arra_clientes" append-icon="mdi-magnify" @click:append="busca()" @keyup.enter="busca()"></v-autocomplete>
                </v-col>
                <v-col cols="4">
                    <v-card @click='busca()'>
                        <v-container>
                            <v-img class="mt-n2 mx-auto" height="30" width="30" src="/filtro.png"></v-img>
                            <h5 block class="mb-n2 text-center">Filtro</h5>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <v-simple-table fixed-header height='70vh' dense class="mt-2" v-if="filtro == 'DETALLADO'">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Fecha
                        </th>
                        <th class="text-left">
                            Doc_venta
                        </th>
                        <th class="text-left">
                            Producto
                        </th>
                        <th class="text-left">
                            Precio
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                        <td style="font-size:75%;">{{ item.doc_venta }} </td>
                        <td style="font-size:75%;">{{ item.nombre }} <strong v-if="item.operacion == 'GRATUITA'" class="red--text"> - OBSEQUIO</strong> </td>
                        <td style="font-size:75%;">S/.{{ item.precio }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-simple-table fixed-header height='70vh' dense class="mt-2" v-if="filtro == 'TOTAL'">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Fecha
                        </th>
                        <th class="text-left">
                            Doc_venta
                        </th>
                        <th class="text-left">
                            Tienda
                        </th>
                        <th class="text-left">
                            total
                        </th>
                        <th class="text-left">
                            Vendedor
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                        <td style="font-size:75%;">{{ item.numeracion }} </td>
                        <td style="font-size:75%;">{{ item.tienda }} </td>
                        <td style="font-size:75%;">S/.{{ item.total }} </td>
                        <td style="font-size:75%;">{{ item.vendedor }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
    <v-dialog v-model="dialog_filtro" max-width="460px">
        <div>
            <v-system-bar window dark>
                <v-icon @click="dialog_filtro = !dialog_filtro">mdi-close</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-card-text>
                <v-select :items="array_filtro" label="MODO" outlined v-model="filtro"></v-select>
            </v-card-text>
            <v-card @click='busca()'>
                <v-container>
                    <v-img class="mt-n2 mx-auto" height="30" width="30" src="/filtro.png"></v-img>
                    <h5 block class="mb-n2 text-center">Filtrar</h5>
                </v-container>
            </v-card>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    all_historial,
    buscadetalle,
    buscacabecera
} from '../../db'
import store from '@/store/index'
import moment from 'moment'
export default {

    data: () => ({
        array_filtro: ['DETALLADO', 'TOTAL'],
        filtro: 'DETALLADO',
        documento: '',
        desserts: [],
        dialog_filtro: false,
        arrayConsolidar: [],
        arra_clientes: [],
        busca_p: '',
        num_cliente: '',
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
    }),
    created() {
        this.carga_clientes()
    },
    computed: {
        listafiltrada() {
            return this.desserts
        }
    },

    methods: {

        carga_clientes() {
            var array = store.state.clientes
            for (var i = 0; i < array.length; i++) {
                this.arra_clientes[this.arra_clientes.length] = array[i].documento + ' / ' + array[i].nombre
            }
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },
        async busca() {
            store.commit("dialogoprogress", 1)
            var array = []
            this.desserts = []
            this.num_cliente = this.busca_p.split('/')[0].trim()
            if (this.num_cliente != '') {
                var snapshot = await all_historial(this.num_cliente).once("value")
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        if (this.filtro == 'DETALLADO') {
                            this.consolida_detallado(item.val())
                        } else {
                            this.consolida_total(item.val())
                        }
                    })
                } else {
                    store.commit('dialogosnackbar', 'SIN INFORMACION')
                }

            }
            this.dialog_filtro = false
            store.commit("dialogoprogress", 1)
        },
        async consolida_detallado(data) {
            var snap = await buscadetalle(data.bd, data.doc_venta).once('value')
            snap.forEach((item) => {
                var items = item.val()
                items.fecha = data.fecha
                items.doc_venta = data.doc_venta
                this.desserts.push(items)
            })
        },
        async consolida_total(data) {
            var snap = await buscacabecera(data.bd, data.doc_venta).once('value')
            this.desserts.push(snap.val())
        },

    }
}
</script>

<style></style>
