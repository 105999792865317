<template>
    <div class="mb-6 pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n4">
                <v-row class="mt-n2 pa-2">

                    <v-col cols="4">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field outlined dense v-model="busca_p" label="Busca" append-icon="mdi-magnify"
                            @click:append="busca()"> </v-text-field>

                    </v-col>
                </v-row>
            </div>

            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha
                            </th>
                            <th class="text-left">
                                Cliente
                            </th>
                            <th class="text-left">
                                Numero
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" item  in  listafiltrada " :key="item.id">
                            <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                            <td style="font-size:75%;">{{ item.especialista }}</td>
                            <td style="font-size:75%;">{{ item.diagnostico }}</td>
                            <td style="font-size:75%;">{{ item.comentarios }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

    </div>
</template>

<script>
import {
    all_mensajeria,
} from '../../db'
import store from '@/store/index'
import moment from 'moment'
export default {


    data: () => ({

        desserts: [],
        busca_p: '',
        date1: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
    }),
    created() {
        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment().subtract(parseFloat(dia) - 15, 'd').format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
    },
    mounted() {
        all_mensajeria().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).on("value", this.onDataChange);
    },
    beforeDestroy() {
        all_mensajeria().orderByChild('fecha')
            .startAt(moment(String(this.date1)) / 1000)
            .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000).off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            all_mensajeria().on("value", this.onDataChange);
            return this.desserts
        }
    },

    methods: {
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YY')
        },
        onDataChange(items) {
            this.desserts = []
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },

    }
}
</script>

<style></style>
