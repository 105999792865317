<template>
    <v-dialog v-model="dial" max-width="750" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h5>LISTA DE ACCESORIOS</h5>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card style="overflow-y: scroll;overflow-x: hidden; max-height:70vh">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="buscar" append-icon="mdi-magnify" label="Buscar" single-line hide-details
                    dense></v-text-field>
            </v-card-title>
            <v-data-table class="elevation-1 mt-1" fixed-eadher height='70vh' :headers="headers"
                :items="listafiltradaclientes" :items-per-page="15" hide-default-footer mobile-breakpoint="1" dense>
                <template v-slot:item="{ item }">
                    <tr @click="agrega_accesorio(item)">
                        <td style="font-size:75%;">{{ item.id }}</td>
                        <td style="font-size:75%;">{{ item.descripcion }}</td>
                        <td style="font-size:75%;">{{ item.medida }}</td>
                        <td style="font-size:75%;">S/.{{ item.precio }}</td>
                    </tr>

                </template>
            </v-data-table>

        </v-card>
        <v-dialog v-model="dialo_cantidad" max-width="350px">
            <v-card class="pa-3">
                <h5 class="mb-2">{{ item_selecto.descripcion }}</h5>
                <v-text-field type="number" autofocus outlined dense v-model="precio" label="PRECIO"
                    @focus="$event.target.select()" @keyup.enter="agregaCatalogo(2)"></v-text-field>

                <v-row class="mt-2 mb-6 text-center" dense>
                    <v-col cols="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="number" outlined dense v-model="cantidad" label="CANTIDAD"
                            @keyup.enter="agregaCatalogo(2)"></v-text-field>
                    </v-col>

                    <v-col cols="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>
                </v-row>
                <v-row class="mt-n2 mb-6 text-center" dense>
                    <v-col cols="6">
                        <v-card @click="agregaCatalogo(1)">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/gift.png"></v-img>
                                <h6 block class="text-center">OBSEQUIO</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card @click="agregaCatalogo(2)">
                            <v-container>
                                <v-img class="mx-auto" height="40" width="40" src="/cash.png"></v-img>
                                <h6 block class="text-center">COBRAR</h6>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import {
    allAccesorios,
} from '../../db'
export default {

    data() {
        return {
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Stock',
                value: 'stock',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            ],
            buscar: '',
            array_tabla: [],
            dialo_cantidad: false,
            cantidad: '',
            precio: '',
            item_selecto: [],
            dial: false,

        }
    },
    mounted() {
        allAccesorios().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allAccesorios().off("value", this.onDataChange);
    },
    computed: {
        listafiltradaclientes() {

            return this.array_tabla.filter((item) =>
                (item.id + item.marca + item.modelo)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
        },
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_tabla = array;
            this.dial = true
        },
        agrega_accesorio(item) {
            this.precio = item.precio
            this.cantidad = 1
            this.item_selecto = item
            this.dialo_cantidad = true
        },
        agregaCatalogo(modo) {
            var item = this.item_selecto
            var precio = this.precio
            var operacion = item.operacion
            if (precio == 0) {
                modo = 1
            }
            if (modo == 1) {
                operacion = 'GRATUITA'
                precio = 0
            }
            var array = {
                id: item.id,
                descripcion: item.descripcion,
                cantidad: this.cantidad,
                precio: precio,
                operacion: operacion,
                tipoproducto: item.tipoproducto,
            }

            this.$emit('array', array)
            this.dialo_cantidad = false
        },
        suma() {
            this.cantidad++
        },
        resta() {
            this.cantidad--
        },
        cierra() {
            this.$emit('cierra', false)
        }

    },

}
</script>
