<template>
    <v-dialog v-model="dial_L" max-width="1050px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="nuevo()">mdi-plus</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">

            <v-simple-table dark fixed-header height="70vh" dense>
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Tipo
                            </th>
                            <th class="text-left">
                                Productos
                            </th>
                            <th class="text-left">
                                estado
                            </th>
                            <th class="text-left" v-if="edicion">
                                Accion
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in listafiltrada" :key="item.id" @click="selecciona(item)">
                            <td>{{ item.tipo_focal }}</td>
                            <td>{{ item.nombre }}</td>
                            <td>{{ item.activo }}</td>
                            <td width="10" v-if="edicion">
                                <div class="d-flex pa-1">
                                    <v-icon color="green" medium @click="editar(item)">mdi-pencil</v-icon>
                                    <v-icon color="red" medium @click="eliminar(item)">mdi-delete</v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <v-card-text>

                    </v-card-text>

                </template>
            </v-simple-table>

        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="460px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon large color="green" @click="agregar()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <div>
                    <v-select :items="$store.state.tipo_focal" label="Tipo" dense outlined v-model="tipo_focal"></v-select>
                    <v-textarea dense outlined v-model="nombre" auto-grow filled label="Nombre Producto"
                        rows="1"></v-textarea>
                </div>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import {
    agrega_tabla,
    busca_tabla
} from '../../db'
import store from '@/store/index'
export default {
    name: 'caja',
    props: {
        dial_L: '',
        edicion: '',
        filtro: ''
    },
    data() {
        return {
            dialogo_agrega: false,
            nombre: '',
            array_tabla: [],
            edit: false,
            activo: true,
            tipo_focal: "MULTIFOCAL",
            item_selec: []
        }
    },
    mounted() {
        busca_tabla('productos').on("value", this.onDataChange);
    },
    beforeDestroy() {
        busca_tabla('productos').off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            busca_tabla('productos').on("value", this.onDataChange);
            if (this.filtro) {
                return this.array_tabla.filter((item) => (item.tipo_focal == this.filtro))
            } else {
                return this.array_tabla
            }

        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                data.key = item.key
                array.push(data);
            });
            this.array_tabla = array;
        },
        nuevo() {
            this.nombre = ''
            this.activo = true
            this.edit = false
            this.dialogo_agrega = true
        },
        async agregar() {
            store.commit("dialogoprogress", 1)
            if (this.edit) {
                var arr = {
                    nombre: this.nombre.toUpperCase().trim(),
                    tipo_focal: this.tipo_focal.toUpperCase().trim(),
                    activo: this.activo
                }
                this.edit = false
                await agrega_tabla("productos/" + this.item_selec.key, arr)
            } else {
                this.array_tabla.push({
                    nombre: this.nombre.toUpperCase().trim(),
                    tipo_focal: this.tipo_focal.toUpperCase().trim(),
                    activo: this.activo
                })
                await agrega_tabla("productos", this.array_tabla)
            }

            store.commit("dialogoprogress")
            this.dialogo_agrega = false


        },
        editar(date) {
            this.edit = true
            this.nombre = date.nombre
            this.tipo_focal = date.tipo_focal
            this.activo = date.activo
            this.item_selec = date
            this.dialogo_agrega = true
        },
        eliminar(item) {
            if (!confirm('seguro de eliminar')) {
                return
            }
            store.commit("dialogoprogress", 1)
            var i = this.array_tabla.findIndex(array => array.codigo === item.codigo)
            this.array_tabla.splice(i, 1)
            agrega_tabla("productos", this.array_tabla).then(() => {
                store.commit("dialogoprogress", 1)
                this.dialogo_agrega = false
            })
        },
        selecciona(item) {
            this.$emit('array', item)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
