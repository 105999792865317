<template>
    <v-dialog v-model="dial" max-width="450" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()" color="red">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon color="blue" large @click="nuevo()">mdi-plus</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-simple-table fixed-header height="400px" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                nombre
                            </th>
                            <th class="text-left">
                                Accion
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.nombre">
                            <td>{{ item.nombre }}</td>
                            <td> <v-row dense>
                                    <v-col cols="6">
                                        <v-icon color="green" @click.prevent="editar(item)">mdi-mouse</v-icon>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-icon color="error" @click.prevent="elimiar(item)"
                                            v-if="modo">mdi-delete</v-icon>
                                    </v-col>
                                </v-row></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

    </v-dialog>
</template>

<script>
import store from '@/store/index'
import {
    nuevo_grupo,
    elimina_grupo
} from '../../db'
export default {
    name: 'caja',
    props: {
        data: [],
        modo: '',
    },
    data() {
        return {
            dial: false,
        }
    },
    created() {
        this.dial = true
    },
    computed: {
        listafiltrada() {
            return store.state.grupos
        }
    },
    methods: {
        editar(item) {
            this.$emit('selecciona', item)
        },
        async elimiar(data) {
            if (confirm('Seguro que desea eliminar?')) {
                store.commit("dialogoprogress", 1)
                await elimina_grupo(data.id)
                store.commit("dialogoprogress", 1)
            }
        },
        async nuevo() {
            var val = prompt("Ingrese nombre Grupo");
            if (val == '' || val == null) {
                alert('Nombre invalido')
                return
            }
            store.commit("dialogoprogress", 1)
            var array = {
                nombre: val,
            }
            await nuevo_grupo(array)
            store.commit("dialogoprogress", 1)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
