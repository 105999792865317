<template>
    <div class="mb-6 mt-3 pa-4">
        <v-card class="pa-2">
            <v-row dense>
                <v-col cols="4">
                    <v-text-field type="date" class="mx-1" outlined dense v-model="date" label="Inicio"></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field class="mx-1" type="date" outlined dense v-model="date2" label="Fin"></v-text-field>
                </v-col>

                <v-col cols="4">
                    <v-select :items="array_empleados" label="Vendedores" dense outlined v-model="empleado"></v-select>
                </v-col>
            </v-row>

            <v-row class="mt-n8">
                <v-col cols="3">
                    <h4 class="text-center">V.General: S/.{{ sumaventas().venta }}</h4>
                </v-col>
                <v-col cols="3">
                    <h4 class="text-center red--text">A cuenta: S/.{{ sumaventas().a_cuenta }} </h4>
                </v-col>
                <v-col cols="2">
                    <h4 class="text-center">Comision: S/.{{ sumaventas().comsion }} </h4>
                </v-col>
                <v-col cols="2">
                    <v-btn color="warning" class="" block x-small @click="dial_comision = true">COMISION</v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn color="info" class="" block x-small @click="busca()">FILTRA</v-btn>
                </v-col>
            </v-row>
            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Vendedor
                            </th>
                            <th class="text-left">
                                Total
                            </th>
                            <th class="text-left">
                                A Cuenta
                            </th>
                            <th class="text-left">
                                Saldo
                            </th>
                            <th class="text-left">
                                {{ val_comision }}%
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td style="font-size:75%;">{{ item.codigo }} - {{ item.nombre }}</td>
                            <td style="font-size:75%;">S/.{{ item.total }}</td>
                            <td style="font-size:75%;" class="red--text">S/.{{ item.a_cuenta }}</td>
                            <td style="font-size:75%;">S/.{{ redondear(item.total) - redondear(item.a_cuenta) }}</td>
                            <td style="font-size:75%;" class="red--text">S/.{{ item.comision }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>
        <v-dialog v-model="dialog" max-width="850px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog = !dialog">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="12">

                    </v-col>
                </v-row>
                <v-simple-table dark fixed-header max-width="70vh" dense>
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Precio
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in arrayConsolidar" :key="item.id">
                                <td>{{ item.nombre }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>S/.{{ item.precioedita }} x {{ item.medida }}</td>
                                <td>S/.{{ redondear(item.precioedita * item.cantidad) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>

        <v-dialog v-model="dial_metodos" max-width="850px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_metodos = !dial_metodos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mt-n2">
                    <v-col cols="4">
                        <h4 class="text-center">EFECTIVO : </h4>
                    </v-col>
                    <v-col cols="4">
                        <h4 class="text-center">TARJETA : </h4>
                    </v-col>
                    <v-col cols="4">
                        <h4 class="text-center">YAPE : </h4>
                    </v-col>
                    <v-col cols="4">
                        <h4 class="text-center">PLIN : </h4>
                    </v-col>
                    <v-col cols="4">
                        <h4 class="text-center">TRASNF. : </h4>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_comision" max-width="250px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_comision = !dial_comision">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-text-field class="mx-1" type="number" outlined dense v-model="val_comision"
                    label="% COMISION"></v-text-field>
                <v-btn color="info" class="" block x-small @click="guarda_comsion()">GUARDAR</v-btn>
            </v-card>
        </v-dialog>
        <imprime v-if="genera_pdf" :data="seleccionado" @cierra="genera_pdf = $event" />


    </div>
</template>

<script>
import {
    allCabecera_general,
    consultaDetalle,
    grabaDatoC,
    consulta_Cabecera,
    consultaDetalle_bd,
    grabaConfigura
} from '../../db'
import store from '@/store/index'
import imprime from '@/components/dialogos/dialog_imprime'
import moment from 'moment'
export default {
    components: {
        imprime
    },

    data: () => ({
        dial_comision: false,
        array_empleados: [],
        genera_pdf: false,
        documento: '',
        desserts: [],
        dialog: false,
        arrayConsolidar: [],
        buscar: '',
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        seleccionado: '',
        num_doc: '',
        tipo_doc: 'T',
        numero: '',
        correo: '',
        array_ubica: [],
        empleado: 'todos',
        dial_metodos: false,
        val_comision: store.state.configuracion.comision
    }),

    computed: {
        listafiltrada() {
            if (this.empleado == 'todos') {
                return this.desserts
            } else {
                return this.desserts.filter(item => item.nombre == this.empleado)
            }

        }
    },
    created() {
        this.array_empleados = store.state.array_empleados
        this.array_empleados.unshift('todos')
        this.busca()
    },
    methods: {
        async guarda_comsion() {
            store.commit("dialogoprogress", 1)
            await grabaConfigura("comision", this.val_comision)
            this.dial_comision = false
            store.commit("dialogoprogress", 1)
            this.busca()
        },
        selecciona_item(item) {
            this.seleccionado = item
            this.genera_pdf = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM hh:mm A')
        },
        sumaventas() {
            var venta = 0
            var a_cuenta = 0
            var comision = 0
            var lista = this.listafiltrada
            for (var i = 0; i < lista.length; i++) {
                a_cuenta = a_cuenta + parseFloat(lista[i].a_cuenta)
                venta = venta + parseFloat(lista[i].total)
                comision = comision + parseFloat(lista[i].comision)
            }
            var array = {
                venta: venta.toFixed(2),
                a_cuenta: a_cuenta.toFixed(2),
                comsion: comision.toFixed(2)
            }
            return array
        },
        ejecutaConsolida(value) {
            store.commit("dialogoprogress")
            this.arrayConsolidar = []
            consultaDetalle_bd(value.bd, value.numeracion).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.arrayConsolidar.push(item.val())
                })
                this.dialog = true
                store.commit("dialogoprogress", 1)
            })
        },
        redondear(valor) {
            return parseFloat(valor).toFixed(store.state.configuracion.decimal)
        },
        async busca() {
            store.commit("dialogoprogress", 1)
            var array = []
            this.desserts = []
            var tiendas = store.state.array_tiendas
            for (var i = 0; i < tiendas.length; i++) {
                var snapshot = await allCabecera_general(tiendas[i].bd)
                    .orderByChild('fecha')
                    .startAt(moment(String(this.date)) / 1000)
                    .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000)
                    .once("value")
                snapshot.forEach((item) => {
                    var data = item.val()
                    if (data.estado == 'aprobado') {
                        data.tienda = tiendas[i].nom_tienda
                        data.tienda_bd = tiendas[i].bd
                        array.push(data)
                    }
                })

            }
            await this.suma_vendedor(array)
            store.commit("dialogoprogress", 1)
        },
        async suma_vendedor(data) {
            var lista_empleados = store.state.tabla_empleados
            var array_final = []
            for (var i = 0; i < lista_empleados.length; i++) {
                var array = data.filter(item => item.vendedor == lista_empleados[i].nombre)
                var suma = 0
                var a_cuenta = 0
                for (var e = 0; e < array.length; e++) {
                    suma = suma + parseFloat(array[e].total)
                    a_cuenta = a_cuenta + parseFloat(array[e].a_cuenta)
                }
                array_final.push({
                    codigo: lista_empleados[i].codigo,
                    nombre: lista_empleados[i].nombre,
                    total: suma,
                    a_cuenta: a_cuenta,
                    comision: (a_cuenta * (this.val_comision / 100)).toFixed(2)
                })
            }
            this.desserts = array_final
        },
        acorta_vendedor(val) {
            let indice = val.indexOf(" ")
            let indice2 = val.indexOf(",")
            var nom = String(val.substring(indice2 + 2, val.length)).trim()
            let indc = nom.indexOf(" ")
            var vend = val.substring(0, indice) + ' ' + nom.substring(0, indc);
            return vend
        }
    }
}
</script>
