<template>
    <v-dialog v-model="dial" max-width="750" persistent>
        <div>
            <v-system-bar window dark height="45">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h4>{{ productos.nombre }}</h4>
                <v-spacer></v-spacer>
                <v-icon color="green" large @click="guarda_parametros()" v-if="editar">mdi-content-save</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-row class="mt-n4 text-center">
                <v-col cols="6">
                    <H4>Parametros de Personalizacion</H4>
                </v-col>
                <v-col cols="6">
                    <H4>Parametros de Montura</H4>
                </v-col>
            </v-row>
            <v-row class="mt-n1">
                <v-col cols="6">
                    <v-text-field :readonly="!editar" outlined type="number" dense v-model="a_panora"
                        label="Angulo Panoramico"></v-text-field>
                    <v-text-field :readonly="!editar" class="mt-n3" outlined type="number" dense v-model="a_pantos"
                        label="Angulo Pantoscopico"></v-text-field>
                    <v-text-field :readonly="!editar" class="mt-n3" outlined type="number" dense v-model="d_verice"
                        label="Distancia al Vertice"></v-text-field>
                    <v-text-field :readonly="!editar" class="mt-n3" outlined type="number" dense v-model="altura"
                        label="Altura"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field :readonly="!editar" outlined type="number" dense v-model="horizontal"
                        label="Horizontal"></v-text-field>
                    <v-text-field :readonly="!editar" class="mt-n3" outlined type="number" dense v-model="vertical"
                        label="Vertical"></v-text-field>
                    <v-text-field id="id_diagonal" :readonly="!editar" class="mt-n3" outlined type="number" dense
                        v-model="diagonal" label="Diagonal" @blur="validar()"></v-text-field>
                    <v-text-field :readonly="!editar" class="mt-n3" outlined type="number" dense v-model="puente"
                        label="Puente" @blur='abre_corredor()'></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n9">
                <v-col cols="6">
                    <v-select :readonly="!editar" style="font-size:75%;" :items="$store.state.tipo_montura"
                        label="Montura" dense outlined v-model="montura"></v-select>
                </v-col>
                <v-col cols="6">

                    <v-text-field outlined dense v-model="corredor" label="Corredor o Diseño" append-icon="mdi-magnify"
                        @click="abre_corredor()" readonly @click:append="abre_corredor()"></v-text-field>
                </v-col>
            </v-row>
            <v-row class="mt-n9">
                <v-col cols="3">
                    <v-text-field id="id_naso" :readonly="!editar" outlined type="number" dense v-model="naso_pu_od"
                        label="Naso Pupilar OD"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field :readonly="!editar" outlined type="number" dense v-model="naso_pu_oi"
                        label="Naso Pupilar OI"></v-text-field>
                </v-col>

                <v-col cols="6">
                    <v-select readonly style="font-size:75%;" :items="array_optometra" label="Optometra" dense outlined
                        v-model="optometra"></v-select>
                </v-col>
            </v-row>
            <v-row class="mt-n9">
                <v-col cols="6">
                    <v-text-field outlined dense v-model="diametro" label="Diametro o Reduccion"></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-select :readonly="!editar" style="font-size:75%;" :items="array_tecnicos" label="Tecnico" dense
                        outlined v-model="tecnico"></v-select>
                    <p style="font-size:75%;" class="red--text mt-n6 ml-2" v-if="tecnico_ant != ''"> Antes : {{
        tecnico_ant }}</p>
                </v-col>
                <v-icon class="mx-auto text-center mt-n2 mb-3" color="green" large @click="guarda_parametros()"
                    v-if="editar">mdi-content-save</v-icon>
            </v-row>

        </v-card>

        <v-dialog v-model="dial_corredor" max-width="150">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_corredor = false">mdi-close</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-simple-table dense fixed-header height="50vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-center">
                                    Corredor
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_corredor" :key="item" @click.prevent="selec_corredor(item)">
                                <td class="text-center" style="font-size:85%;">{{ item }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    busca_tabla,
    busca_Productos,
    nuevo_parametro
} from '../../db'
export default {
    props: {
        productos: '',
        cliente: '',
        parametro: '',
        editar: ''
    },
    data() {
        return {
            dial: false,
            dial_selecciona: false,
            dial_corredor: false,
            d_verice: '',
            horizontal: '',
            a_pantos: '',
            vertical: '',
            a_panora: '',
            diagonal: '',
            altura: '',
            puente: '',
            montura: 'ARO COMPLETO',
            corredor: '',
            naso_pu_oi: '',
            naso_pu_od: '',
            optometra: '',
            diametro: 'SEGUN PARAMETROS',
            tecnico: '',
            array_tecnicos: [],
            array_optometra: ['RECETA EXTERNA'],
            producto: '',
            array_corredor: [],
            id: '',
            pos_producto: 0,
            desactiva: false,
            tecnico_ant: ''
        }
    },
    created() {
        this.inicio()

    },
    mounted() {

    },
    beforeDestroy() {

    },
    computed: {

    },
    methods: {
        async inicio() {
            if (store.state.array_paciente != '') {
                this.desactiva = true
            } else {
                this.desactiva = false
            }
            var snapshot = await busca_tabla('tecnicos').once("value")
            snapshot.forEach((item) => {
                let data = item.val();
                this.array_tecnicos[this.array_tecnicos.length] = data.nombre
            });
            var snapshot = await busca_tabla('especialistas').once("value")
            snapshot.forEach((item) => {
                let data = item.val();
                this.array_optometra[this.array_optometra.length] = data.nombre
            });
            this.id = ''

            if (Boolean(this.parametro)) {
                console.log(this.parametro)
                this.id = this.parametro.id
                this.a_panora = this.parametro.a_panora
                this.a_pantos = this.parametro.a_pantos
                this.d_verice = this.parametro.d_verice
                this.altura = this.parametro.altura
                this.montura = this.parametro.montura
                this.naso_pu_od = this.parametro.naso_pu_od
                this.naso_pu_oi = this.parametro.naso_pu_oi
                this.diametro = this.parametro.diametro
                this.horizontal = this.parametro.horizontal
                this.vertical = this.parametro.vertical
                this.diagonal = this.parametro.diagonal
                this.puente = this.parametro.puente
                this.corredor = this.parametro.corredor
                this.optometra = this.parametro.optometra
                this.tecnico = this.parametro.tecnico
                this.dni = this.parametro.dni
                if (Boolean(this.parametro.tecnico_ant)) {
                    console.log(this.parametro.tecnico_ant)
                    this.tecnico_ant = this.parametro.tecnico_ant
                }
            } else {
                this.optometra = this.cliente.especialista
            }

            this.dial = true

        },
        valida_guarda() {
            if (this.diametro == '') {
                this.diametro = 'SEGUN PARAMETROS'
            }
            var resp = false
            if (this.a_panora != '' && this.a_pantos != '' && this.d_verice != '' && this.altura != '' &&
                this.montura != '' && this.naso_pu_od != '' && this.naso_pu_oi != '' && this.horizontal != '' && this.vertical != '' && this.tecnico != '') {
                resp = true
            }
            return resp
        },
        async guarda_parametros() {
            store.commit("dialogoprogress", 1)
            if (this.valida_guarda()) {
                if (this.id == '') {
                    var fec = moment().unix()
                } else {
                    var fec = this.id
                }
                var array = {
                    id: fec,
                    fecha: fec,
                    a_panora: this.a_panora,
                    a_pantos: this.a_pantos,
                    d_verice: this.d_verice,
                    altura: this.altura,
                    montura: this.montura,
                    naso_pu_od: this.naso_pu_od,
                    naso_pu_oi: this.naso_pu_oi,
                    diametro: this.diametro,
                    horizontal: this.horizontal,
                    vertical: this.vertical,
                    diagonal: this.diagonal,
                    puente: this.puente,
                    corredor: this.corredor,
                    optometra: this.optometra,
                    tecnico: this.tecnico,
                    dni: this.cliente.dni_cliente,
                    producto: this.productos.nombre,
                    id_producto: this.productos.id,
                    tecnico_ant: this.tecnico_ant
                }
                console.log(array)
                await nuevo_parametro(array.dni, array.id, array)
                store.commit("dialogoprogress", 1)
                this.emite_guarda(array)
            } else {
                alert('COMPLETE DATOS')
            }
        },
        seleccion_producto() {
            this.dial_selecciona = true
        },
        selecciona(val) {

            this.producto = val
            this.dial_selecciona = false
        },
        limpia_cajas() {
            this.id = ''
            this.a_panora = ''
            this.a_pantos = ''
            this.d_verice = ''
            this.altura = ''
            this.montura = ''
            this.naso_pu_od = ''
            this.naso_pu_oi = ''
            this.diametro = ''
            this.horizontal = ''
            this.vertical = ''
            this.diagonal = ''
            this.puente = ''
            this.corredor = ''
            this.optometra = ''
            this.tecnico = ''
            this.dni = ''
        },
        async abre_corredor() {
            var snap = await busca_Productos(this.productos.id).once("value")
            var item = snap.val()
            var corredor = store.state.corredores.find(id => id.nombre == item.marca)
            this.array_corredor = corredor.valor
            this.dial_corredor = true
        },
        async selec_corredor(val) {
            this.corredor = val
            this.dial_corredor = false
            await new Promise(resolve => setTimeout(resolve, 100))
            document.getElementById('id_naso').focus();
        },
        emite_guarda(data) {
            this.$emit('guarda', data)
        },
        cierra() {
            this.$emit('cierra', false)
        },
        validar() {
            if (parseFloat(this.diagonal) < parseFloat(this.horizontal)) {
                alert('Diagonal no puede ser menor a horizontal')
                this.diagonal = ''
                document.getElementById('id_diagonal').focus();
            }
        }

    },

}
</script>
