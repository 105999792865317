<template>
    <div class="mb-6 pa-4 mt-3">
        <h4>ACCESORIOS</h4>
        <v-row dense :class="$vuetify.breakpoint.smAndDown ? 'mt-n2 mb-n3' : 'mb-n3 mt-n2'">
            <v-col cols="12" md="6" xs="12">
                <v-btn color="success" class="btn mt-2" block small @click="nuevo()" disabled>
                    <v-icon dark left>
                        mdi-spray-bottle
                    </v-icon> Nuevo
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" xs="12">
                <v-text-field outlined dense v-model="buscar" append-icon="mdi-magnify" label="BUSCAR"></v-text-field>
            </v-col>
        </v-row>
        <v-card class="pa-3">

            <v-data-table :headers="headers" :search="buscar" :items="array_tabla" dense :items-per-page="17"
                mobile-breakpoint="1">
                <template v-slot:item.actions="{ item }">
                    <v-row>
                        <v-col cols="12">
                            <v-icon color="green" @click="editar(item)"
                                :disabled="!$store.state.permisos.master">mdi-lead-pencil</v-icon>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="700px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agregar()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="dialogo_elimina = !dialogo_elimina">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field dense disabled v-model="id" label="ID" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="costo" label="costo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="descripcion" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="4">
                        <v-text-field outlined dense type="number" v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field outlined dense type="number" v-model="precio_min"
                            label="Precio minimo"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field outlined dense type="number" v-model="precio" label="Precio venta"></v-text-field>
                    </v-col>
                </v-row>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_elimina" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_elimina = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Esta seguro de Eliminar?</h4>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block @click="eliminar()" color="orange">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    allAccesorios,
    nuevoAccesorios_t,
    eliminaAccesorios_t
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import store from '@/store/index'
export default {
    name: 'caja',
    data() {
        return {
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Descripcion',
                value: 'descripcion',
            },

            {
                text: 'Stock',
                value: 'stock',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            {
                text: 'Actions',
                value: 'actions',
                sortable: false
            },
            ],
            activa_proveedor: false,
            activa_colores: false,
            dialogo_agrega: false,
            dialogo_elimina: false,
            array_tabla: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA',
                'INAFECTA'
            ],
            edit: false,
            tipoproducto: "BIEN",
            operacion: "GRAVADA",
            id: '',
            descripcion: '',
            costo: '',
            stock: '',
            precio_min: '',
            precio: '',
            buscar: ''
        }
    },
    mounted() {
        allAccesorios().on("value", this.onDataChange);
    },
    beforeDestroy() {
        allAccesorios().off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            allAccesorios().on("value", this.onDataChange);
            return this.array_tabla
        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                console.log(data)
                array.push(data);
            });
            this.array_tabla = array;
        },
        async nuevo() {
            this.edit = false
            var a = await obten_contador_externo("orden_accesorios")
            this.id = "A" + a
            this.descripcion = ''
            this.costo = 0
            this.precio_min = 0
            this.stock = 1
            this.precio = 0
            this.dialogo_agrega = true
        },
        async agregar() {
            if (!this.edit) {
                var a = await obten_contador_externo("orden_accesorios")
                this.id = "A" + a
            }
            store.commit("dialogoprogress", 1)
            var array = {
                tipoproducto: this.tipoproducto,
                operacion: this.operacion,
                id: this.id,
                descripcion: this.descripcion,
                costo: this.costo,
                precio_min: this.precio_min,
                stock: this.stock,
                precio: this.precio,
            }
            await nuevoAccesorios_t(this.id, array)
            store.commit("dialogoprogress", 1)
            if (!this.edit) {
                await sumarCorrelativo_externo("orden_accesorios", a)
                this.costo = 0
                this.descripcion = ''
                this.stock = 1
                this.precio = 0
                this.precio_min = 0
                this.dialogo_agrega = false
            } else {
                this.dialogo_agrega = false
            }
        },
        editar(data) {
            this.edit = true
            this.tipoproducto = data.tipoproducto
            this.operacion = data.operacion
            this.id = data.id
            this.descripcion = data.descripcion
            this.costo = data.costo
            this.precio_min = data.precio_min
            this.stock = data.stock
            this.precio = data.precio
            this.dialogo_agrega = true
        },
        eliminar() {
            eliminaAccesorios_t(this.id)
            this.dialogo_elimina = false
            this.dialogo_agrega = false
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
