<template>
    <div class="pa-3">
        <v-row class="mb-4" dense>
            <v-col cols="6" class="pa-1 mx-auto" md="6" sm="6" xs="6">
                <v-card @click.prevent="abre_pagina()">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/movil.png"></v-img>
                        <h4 block class="text-center pa-1">Mensajes</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 mx-auto" md="6" sm="6" xs="6">
                <v-card @click.prevent="abre_conexion()">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/message.png"></v-img>
                        <h4 block class="text-center pa-1">Conexion</h4>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="450px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog = !dialog">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field outlined dense v-model="token_sms" label="Token Acceso"></v-text-field>
                    </v-col>
                </v-row>
                <v-btn color="success" class="btn mt-2" block x-small @click="guardar()">Guardar</v-btn>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_sms" max-width="1150px">

            <iframe v-if="dial_sms" :src="externalPageUrl" width="100%" height="650vh" @load="onIframeLoad"></iframe>

        </v-dialog>
        <v-dialog v-model="loading" persistent width="300">
            <v-card>
                <v-card-text>
                    <v-progress-linear indeterminate color="blue"></v-progress-linear>
                    <div class="text-center mt-3">Cargando...</div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {
    grabaConfigura
} from '../../db'
import store from '@/store/index'
export default {
    name: 'panel',

    components: {

    },
    data() {
        return {
            dial_sms: false,
            loading: false,
            dialog: false,
            token_sms: '',
            externalPageUrl: ''
        }
    },
    methods: {
        abre_pagina() {
            //this.externalPageUrl = 'http://localhost:8080/sms_vision/BD4/' + store.state.permisos.tienda
            this.externalPageUrl = 'https://opticenter-sms.web.app/sms_vision/BD4/' + store.state.permisos.tienda
            this.dial_sms = true
            this.loading = true
        },
        async onIframeLoad() {
            this.loading = false;
        },
        onIframeBeforeUnload() {
            this.loading = false;
        },
        abre_conexion() {
            this.token_sms = (store.state.configuracion.token_sms)
            this.dialog = true
        },
        async guardar() {
            store.commit("dialogoprogress", 1)
            await grabaConfigura("token_sms", this.token_sms)
            this.dialog = false
            store.commit("dialogoprogress", 1)
        },
    },

}
</script>
