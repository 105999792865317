<template>
    <v-dialog v-model="dial_L" max-width="550px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="nuevo()">mdi-plus</v-icon>
                <v-spacer></v-spacer>
                <v-btn color="info" @click="modo = true, dial_grupos = true" x-small>Crea Grupo</v-btn>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-simple-table fixed-header height="70vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">Codigo</th>
                            <th class="text-left">Razon Social</th>
                            <th class="text-left">Accion</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in lista_filtada" :key="item.id">
                            <td>{{ item.id }}</td>
                            <td>{{ item.nombre }}</td>
                            <td width="10">
                                <div class="d-flex pa-1">
                                    <v-icon color="green" medium @click="editar(item)">mdi-pencil</v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <v-card-text></v-card-text>
                </template>
            </v-simple-table>
        </v-card>
        <v-dialog v-model="dialogo" max-width="600px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo = !dialogo">mdi-close</v-icon>
                    <v-switch v-model="activo" color="green" label="ACTIVO"></v-switch>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="save()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="eliminar()">mdi-delete</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-4">
                <v-row dense class="text-center pa-3">
                    <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" />
                    <v-card ref='camara' class="mx-auto pa-1" @click.prevent="abrir_camara()" type="file">
                        <v-container>
                            <v-img class="mx-auto" height="150" width="120" :src="imageSrc || '/camera.png'"></v-img>
                        </v-container>
                    </v-card>
                </v-row>
                <v-row dense>
                    <v-col cols="4">
                        <v-select dense v-model="tipodoc" :items="arraydocumento" menu-props="auto" outlined
                            hide-details label="TIPO DOC"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field type="number" dense v-model="documento" label="DOCUMENTO" outlined
                            append-icon="mdi-magnify" @click:append="BuscarDocumento()"
                            @keyup.enter="BuscarDocumento()"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5" dense>
                    <v-col cols="12">
                        <v-textarea outlined dense v-model="nombre" auto-grow filled label="NOMBRE"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n5" dense>
                    <v-col cols="6">
                        <v-text-field type="date" dense v-model="fecha_ingreso" label="Fecha Ingreso"
                            outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field :disabled="activo" type="date" dense v-model="fecha_cese" label="Fecha Cese"
                            outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5" dense>
                    <v-col cols="6">
                        <v-text-field readonly dense v-model="grupo" label="Grupo"
                            @click="modo = false, dial_grupos = true" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field type="number" dense v-model="sueldo" label="Sueldo x Dia" outlined></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n5" dense>
                    <v-col cols="12">
                        <v-text-field type="number" dense v-model="telefono" label="Telefono" outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <grupos v-if="dial_grupos" @cierra="dial_grupos = false" @selecciona="select_grupo($event)" :modo="modo" />
    </v-dialog>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import grupos from "./dial_grupos.vue";
import * as faceapi from 'face-api.js';
import {
    nuevoEmpleados,
    guardaArchivo
} from '../../db';
import store from '@/store/index';

export default {
    components: {
        grupos,
    },
    name: 'caja',
    props: {
        edicion: ''
    },
    data() {
        return {
            dial_L: false,
            dial_grupos: false,
            dialogo: false,
            buscar: '',
            desserts: [],
            activo: true,
            arraydocumento: ['DNI', 'RUC'],
            tipodoc: 'DNI',
            documento: '',
            nombre: '',
            sueldo: '',
            telefono: '',
            grupo: '',
            fecha_ingreso: moment(String(new Date)).format('YYYY-MM-DD'),
            fecha_cese: '',
            array_tabla: [],
            imageSrc: null,
            archivo: '',
            progress: false,
            selectedFile: null,
            Uploadvalue: 0,
        }
    },
    created() {
        this.dial_L = true;
    },
    computed: {
        lista_filtada() {
            return store.state.empleados;
        }
    },
    methods: {
        editar(data) {
            this.dialogo = true
            this.activo = data.activo
            this.documento = data.documento
            this.tipodoc = data.tipodoc
            this.documento = data.documento
            this.nombre = data.nombre
            this.sueldo = data.sueldo
            this.fecha_ingreso = moment.unix(data.fecha_ingreso).format('YYYY-MM-DD')
            this.telefono = data.telefono
            this.grupo = data.grupo
            this.imageSrc = data.foto
            fetch(data.foto)
                .then(response => response.blob())
                .then(blob => {

                    this.selectedFile = blob
                    this.selectedFile.name = data.documento
                    console.log(this.selectedFile)
                });

        },
        abrir_camara() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                console.log('Archivo seleccionado:', file);
                this.selectedFile = file
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imageSrc = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        select_grupo(data) {
            this.grupo = data.nombre;
            this.dial_grupos = false;
        },
        async save() {
            if (!this.activo) {
                if (!confirm('Esta seguro de querer dar de Baja al trabajador?')) {
                    return;
                } else {
                    if (this.fecha_cese === '') {
                        this.fecha_cese = moment(String(new Date)).format('YYYY-MM-DD');
                    }
                }
            }
            var face = await this.loadLabeledDescriptors(this.documento, this.selectedFile)
            console.log(face)
            var foto = await this.agregarFoto()
            console.log(foto)
            store.commit("dialogoprogress", 1);
            const array = {
                activo: this.activo,
                id: this.documento,
                tipodoc: this.tipodoc,
                documento: this.documento,
                nombre: this.nombre,
                sueldo: this.sueldo,
                fecha_ingreso: (moment(String(this.fecha_ingreso)) / 1000),
                telefono: this.telefono,
                grupo: this.grupo,
                foto: foto,
                face: face
            };
            await nuevoEmpleados(array.id, array);
            store.commit("dialogoprogress", 1);
            this.dialogo = false;
        },
        async loadLabeledDescriptors(id, blo) {
            console.log(blo)
            const imgUrl = URL.createObjectURL(blo);
            const img = await faceapi.fetchImage(imgUrl);
            const detections = await faceapi.detectSingleFace(img, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptor();
            if (detections == undefined) {
                alert('modificar foto ')
                return
            }
            // Envolver el descriptor en un Float32Array
            const descriptorArray = new Float32Array(detections.descriptor);

            // Devolver el descriptor envuelto en un Float32Array
            return descriptorArray;
        },
        agregarFoto() {
            return new Promise((resolve, reject) => {
                // Inicia el diálogo de progreso
                store.commit("dialogoprogress", 1);

                // Verifica que un archivo esté seleccionado
                if (!this.imageSrc) {
                    console.error("No file selected");
                    store.commit("dialogoprogress", 1); // Oculta el diálogo de progreso
                    return reject(new Error("No file selected"));
                }
                if (this.selectedFile == null) {
                    store.commit("dialogoprogress", 1); // Oculta el diálogo de progreso
                    return reject(new Error("No file selected"));
                }
                console.log(this.selectedFile);

                // Inicia la tarea de subida
                const task = guardaArchivo('images/' + store.state.baseDatos.bd, this.selectedFile, this.selectedFile.name);

                // Maneja el estado de la subida
                task.on('state_changed', snapshot => {
                    // Calcula el porcentaje de subida
                    let porcentaje = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.Uploadvalue = porcentaje;
                }, error => {
                    // Maneja errores de subida
                    console.log(error.message);
                    store.commit("dialogoprogress", 0); // Oculta el diálogo de progreso en caso de error
                    reject(error);
                },
                    () => {
                        // Subida completa
                        this.Uploadvalue = 100;
                        task.snapshot.ref.getDownloadURL().then((url) => {
                            console.log("Subida exitosa");
                            this.archivo = url;
                            this.selectedFile = ''; // Limpia el archivo seleccionado
                            store.commit("dialogoprogress", 0); // Oculta el diálogo de progreso
                            resolve(url);
                        }).catch(error => {
                            console.log(error.message);
                            store.commit("dialogoprogress", 0); // Oculta el diálogo de progreso en caso de error
                            reject(error);
                        });
                    });
            });
        },
        nuevo() {
            this.activo = true;
            this.imageSrc = ''
            this.selectedFile = ''
            this.tipodoc = 'DNI';
            this.documento = '';
            this.nombre = '';
            this.sueldo = '';
            this.fecha_ingreso = moment(String(new Date)).format('YYYY-MM-DD');
            this.telefono = '';
            this.grupo = '';
            this.dialogo = true;
        },
        async eliminar() {
            if (!confirm('Esta seguro de querer eliminar?')) {
                return;
            }
            await eliminaEmpleados(this.itemselecto.id);
            this.itemselecto = '';
            this.dialogo = false;
        },
        BuscarDocumento() {
            if (this.documento !== '' && (this.tipodoc === "RUC" || (this.tipodoc === "DNI" && (this.documento.length === 8 || this.documento.length === 11)))) {
                if (Number(this.documento.length) === 8) {
                    this.tipodoc = "DNI";
                }
                if (Number(this.documento.length) === 11) {
                    console.log(Number(this.documento.length));
                    this.tipodoc = "RUC";
                }
                store.commit("dialogoprogress", 1);
                const self = this;
                const token = '80a4a1c5f2e97c2d78fcd5074cd64ff0a29007ef91880ad2c214af67a084433d';
                axios
                    .get(`https://apiperu.dev/api/${this.tipodoc.toLowerCase()}/${this.documento}`, {
                        headers: {
                            Content_Type: 'application/json',
                            authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        this.info = response.data;
                        store.commit("dialogoprogress", 1);
                        self.llenardatos(response.data.data);
                    })
                    .catch(error => {
                        store.commit("dialogoprogress", 1);
                        self.snack('Documento Invalido');
                    });
            } else {
                this.snack("Ingrese Documento");
            }
        },
        llenardatos(data) {
            if (this.tipodoc === 'DNI') {
                this.nombre = data.nombre_completo;
            }
            if (this.tipodoc === 'RUC') {
                this.nombre = data.nombre_o_razon_social;
            }
        },
        cierra() {
            this.$emit('cierra', false);
        }
    },
};
</script>

<style scoped>
/* Añadir estilos si es necesario */
</style>
