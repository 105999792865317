<template>
  <v-container class="mb-8">
    <v-row>
      <v-col cols="3">
        <v-select :items="arraytabla" label="TABLA" dense outlined v-model="tabla"></v-select>
      </v-col>
      <v-col cols="3">
        <v-btn @click="obtenerDatos()" color="success" small block>Consultar Tabla</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn @click="formato()" color="warning" small block>Exportar Tabla</v-btn>
      </v-col>
      <v-col cols="3">
        <v-btn v-if="tableData != ''" :loading="loading" block small @click="guardar()" color="success"
          class="text-center">Subir tabla</v-btn>
      </v-col>
    </v-row>

    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
    <v-card>

      <v-simple-table class="elevation-1" fixed-header height="60vh">
        <template v-slot:default>
          <thead>

            <tr>
              <th class="text-left">
                id
              </th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.id">
              <td>{{ item.id }}</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-card>
  </v-container>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel'
import XLSX from 'xlsx'
import {
  allMonturas,
  allProductos,
  allAccesorios,
  nuevaMontura,
  nuevoAccesorios,
  nuevoProductos
} from '../db'
import store from '@/store/index'
export default {
  name: 'UploadExcel',
  components: {
    UploadExcelComponent
  },
  data() {
    return {
      tableData: [],
      tableHeader: [],
      loading: false,
      arraytabla: ['monturas', 'accesorios', 'productos', 'cliente'],
      tabla: 'monturas'
    }
  },
  methods: {
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess({
      results,
      header
    }) {
      this.tableData = results
      this.tableHeader = header
    },
    formato() {
      var data = ''
      if (this.tabla == 'monturas') {
        var formato = [{
          tipoproducto: 'BIEN',
          operacion: 'GRAVADA',
          id: '',
          tipo: '',
          marca: '',
          modelo: '',
          proveedor: '',
          color: '',
          id_color: '',
          material: '',
          costo: '',
          precio_min: '',
          stock: '',
          precio: '',
          ubicacion: ''
        }]
      }
      if (this.tabla == 'accesorios') {
        var formato = [{
          tipoproducto: 'BIEN',
          operacion: 'GRAVADA',
          id: '',
          descripcion: '',
          costo: '',
          precio_min: '',
          stock: '',
          precio: '',
        }]
      }
      if (this.taba == 'productos') {
        var formato = [{
          tipoproducto: 'BIEN',
          operacion: 'GRAVADA',
          id: '',
          tipo_focal: '',
          marca: '',
          tratamiento: '',
          tipo_material: '',
          indice: '',
          descripcion: '',
          descripcion_corta: '',
          costo: '',
          precio: '',
          stock: '',
          comision: '',
        }]
      }
      if (this.taba == 'cliente') {
        var formato = [{
          activo: '',
          id: '',
          tipodoc: '',
          documento: '',
          nombre: '',
          correo: '',
          departamento: '',
          provincia: '',
          distrito: '',
          ubigeo: '',
          direccion: '',
          telefono: '',
          nacimiento: '',
          edad: '',
          tienda: ''
        }]
      }

      if (this.tableData != '') {
        data = XLSX.utils.json_to_sheet(this.tableData)
      } else {
        data = XLSX.utils.json_to_sheet(formato)
      }

      const workbook = XLSX.utils.book_new()
      const filename = 'DATA'
      XLSX.utils.book_append_sheet(workbook, data, "datos")
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    guardar() {
      if (this.tabla == 'monturas') {
        var array = ''
        for (var i = 0; i < this.tableData.length; i++) {
          var data = this.tableData[i]
          array = {
            tipoproducto: data.tipoproducto,
            operacion: data.operacion,
            id: data.id,
            tipo: data.tipo,
            marca: data.marca,
            modelo: data.modelo,
            proveedor: data.proveedor,
            color: data.color,
            id_color: data.id_color,
            material: data.material,
            costo: data.costo,
            precio_min: data.precio_min,
            stock: data.stock,
            precio: data.precio,
            ubicacion: data.ubicacion
          }
          if (nuevaMontura(this.tableData[i].id, array)) {
            console.log((100 / this.tableData.length) * (i + 1))
          }

        }
        this.tableData = []
      }
      if (this.tabla == 'accesorios') {
        var array = ''
        for (var i = 0; i < this.tableData.length; i++) {
          var data = this.tableData[i]
          array = {
            tipoproducto: data.tipoproducto,
            operacion: data.operacion,
            id: data.id,
            descripcion: data.descripcion,
            costo: data.costo,
            precio_min: data.precio_min,
            stock: data.stock,
            precio: data.precio,
          }
          if (nuevoAccesorios(this.tableData[i].id, array)) {
            console.log((100 / this.tableData.length) * (i + 1))
          }

        }
        this.tableData = []
      }
      if (this.tabla == 'productos') {
        var array = ''
        for (var i = 0; i < this.tableData.length; i++) {
          var data = this.tableData[i]
          if (data.c_palabra == 0) {
            data.c_palabra = ''
          }
          if (data.c_icono == 0) {
            data.c_icono = ''
          }
          array = {
            tipoproducto: data.tipoproducto,
            operacion: data.operacion,
            id: data.id,
            tipo_focal: data.tipo_focal,
            marca: data.marca,
            tratamiento: data.tratamiento,
            tipo_material: data.tipo_material,
            indice: data.indice,
            descripcion: data.descripcion,
            descripcion_corta: data.descripcion_corta,
            costo: data.costo,
            precio: data.precio,
            stock: data.stock,
            comision: data.comision,
            c_palabra: data.c_palabra,
            c_icono: data.c_icono,
            laboratorio: data.laboratorio,
            filtro: data.filtro,
            orden: data.orden
          }
          if (nuevoProductos(this.tableData[i].id, array)) {
            console.log((100 / this.tableData.length) * (i + 1))
          }

        }
        this.tableData = []
      }
    },
    stringToBoolean(string) {
      switch (string.toLowerCase().trim()) {
        case "true":
        case "yes":
        case "1":
          return true;
        case "false":
        case "no":
        case "0":
        case null:
          return false;
        default:
          return Boolean(string);
      }
    },
    obtenerDatos() {
      if (this.tabla == 'cliente') {
        this.tableData = store.state.clientes
      }
      if (this.tabla == 'accesorios') {
        allAccesorios().once("value").then((snapshot) => {
          let array = [];
          snapshot.forEach((item) => {
            let data = item.val();
            array.push({
              tipoproducto: data.tipoproducto,
              operacion: data.operacion,
              id: data.id,
              descripcion: data.descripcion,
              costo: data.costo,
              precio_min: data.precio_min,
              stock: data.stock,
              precio: data.precio,
            });
          });
          this.tableData = array;
        })
      }
      if (this.tabla == 'monturas') {
        allMonturas().once("value").then((snapshot) => {
          let array = [];
          snapshot.forEach((item) => {
            let data = item.val();
            array.push({
              tipoproducto: data.tipoproducto,
              operacion: data.operacion,
              id: data.id,
              tipo: data.tipo,
              marca: data.marca,
              modelo: data.modelo,
              proveedor: data.proveedor,
              color: data.color,
              id_color: data.id_color,
              material: data.material,
              costo: data.costo,
              precio_min: data.precio_min,
              stock: data.stock,
              precio: data.precio,
              ubicacion: data.ubicacion

            });
          });
          this.tableData = array;
        })
      }
      if (this.tabla == 'productos') {
        allProductos().once("value").then((snapshot) => {
          let array = [];
          snapshot.forEach((item) => {
            let data = item.val();
            array.push({
              filtro: data.filtro,
              tipoproducto: data.tipoproducto,
              operacion: data.operacion,
              id: data.id,
              tipo_focal: data.tipo_focal,
              marca: data.marca,
              tratamiento: data.tratamiento,
              tipo_material: data.tipo_material,
              indice: data.indice,
              descripcion: data.descripcion,
              descripcion_corta: data.descripcion_corta,
              costo: data.costo,
              precio: data.precio,
              stock: data.stock,
              comision: data.comision,
              c_palabra: data.c_palabra,
              c_icono: data.c_icono,
              laboratorio: data.laboratorio,
              orden: data.orden
            });
          });
          this.tableData = array;
        })
      }
    }
  },
}
</script>
