import jspdf from "jspdf";
import "jspdf-autotable";
import store from "@/store/index";
import moment from "moment";

export const pdf_pendiente = (array, medida) => {
  switch (medida) {
    case "A4":
      impresionA4(array);
      break;
    case "80":
      impresion80(array);
      break;
  }
};

function impresion80(array) {
  console.log(array);
  var linea = 10;
  var pdfInMM = 75; // width of A4 in mm
  var lMargin = 3.5; //left margin in mm
  var rMargin = 2; //right margin in mm
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var fechaImpresion = moment().format("DD/MM/YYYY hh:mm a");
  var pageCenter = pdfInMM / 2;
  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM],
  });
  doc.setTextColor(10);
  doc.text(".", 0, linea);
  linea = linea + 3;
  doc.setFontSize(11);
  doc.setFont("Helvetica", "Bold");
  var texto = doc.splitTextToSize(
    "Reporte Pendientes",
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  linea = linea + 3;
  doc.setFontSize(9);
  doc.setFont("Helvetica", "Bold");
  var texto = doc.splitTextToSize(fechaImpresion, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center"); //EMPRESA
  linea = linea + 3;
  doc.setFont("Helvetica", "bold");
  doc.setFontSize(9);
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 10;

  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].doc_venta;
    nuevoArray[i][1] = moment.unix(array[i].fecha).format("DD/MM/YY");
    nuevoArray[i][2] = array[i].total;
    nuevoArray[i][3] = array[i].saldo;
  }

  doc.autoTable({
    margin: { top: linea - 9, left: 1 },
    styles: {
      fontSize: 7.5,
      cellPadding: 0.3,
      valign: "middle",
      halign: "center",
    },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 25, halign: "left" },
      1: { columnWidth: 20, halign: "left" },
      2: { columnWidth: 12, halign: "right" },
      3: { columnWidth: 12, halign: "right" },
    },
    theme: ["plain"],
    head: [["Doc", "Fecha", "Tot", "Saldo"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 2;

  window.open(doc.output("bloburi"));
}
