<template>
    <v-dialog v-model="dial" fullscreen hide-overlay transition="dialog-bottom-transition">
        <div>
            <v-system-bar window dark height="40">
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>

                <v-spacer></v-spacer>
                <h3>Periodo : {{ periodo }}</h3>
            </v-system-bar>
        </div>

        <v-card class="">
            <v-simple-table fixed-header height="90vh" dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th style="font-size:75%;" class="text-left"> </th>
                            <th v-for="item in dias_mes" :key="item" style="font-size:75%;"
                                :class="obten_dia(item) == 'dom' ? 'text-center red--text' : ''">
                                {{ obten_dia(item) }}
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style="font-size:75%;" class="text-left"> </th>
                            <th v-for="item in dias_mes" :key="item" style="font-size:75%;" class="text-center">
                                {{ item }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in  listafiltrada " :key="item[0].nombre">
                            <td style="font-size:75%;" width="200">{{ item[0].nombre }}</td>
                            <th width="10" v-for="items in  dias_mes " :key="items" style="font-size:75%;"
                                class="text-center" @click="editar(item, items)">
                                <p :class="item[items].estado == 'A' ? 'green--text' : item[items].estado == 'F' ? 'red--text'
        : item[items].estado == 'D' ? 'blue--text' : 'orange--text'">
                                    {{ item[items].estado }}
                                </p>
                            </th>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment'
import store from '@/store/index'
import {
    nuevo_medicion,
} from '../../db'
export default {

    props: {
        data: '',
        periodo: null
    },
    name: 'caja',
    data() {
        return {
            dial: false,
            dias_mes: '',
            dias_sem: ['dom', 'lun', 'mar', 'mie', 'jue', 'vie', 'sab'],
        }
    },
    created() {
        this.dial = true
        this.dias_mes = moment(String(this.periodo), 'MM-YYYY').daysInMonth()
    },
    computed: {
        listafiltrada() {
            return ''

        },

    },
    methods: {
        obten_dia(data) {
            var fecha = data + '-' + this.periodo
            return this.dias_sem[moment(String(fecha), 'DD-MM-YYYY').days()]
        },
        cierra() {
            this.$emit('cerrar', true);
        }
    },

}
</script>

<style>
.scroll {
    overflow-y: scroll
}
</style>
