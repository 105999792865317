<template>
    <v-dialog v-model="dial" max-width="1050px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="nuevo()" v-if="edicion">mdi-plus</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">

            <v-simple-table fixed-header height="70vh" dense>
                <template v-slot:default>

                    <thead>
                        <tr>
                            <th class="text-left">
                                Color
                            </th>
                            <th class="text-left">
                                Paleta
                            </th>
                            <th class="text-left" v-if="edicion">
                                Accion
                            </th>
                        </tr>
                    </thead>

                    <tbody>

                        <tr v-for="item in array_tabla" :key="item.id" @click="selecciona(item)">
                            <td>{{ item.nombre }}</td>
                            <td> <v-avatar :color="item.codigo" size="25">
                                </v-avatar></td>
                            <td width="10" v-if="edicion">
                                <div class="d-flex pa-1">
                                    <v-icon color="green" medium @click="editar(item)">mdi-pencil</v-icon>
                                    <v-icon color="red" medium @click="eliminar(item)">mdi-delete</v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>

                </template>
            </v-simple-table>

        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="350px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon large color="green" @click="agregar()">mdi-content-save</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <div>
                    <v-textarea dense outlined v-model="nombre" auto-grow filled label="Nombre Color" rows="1"></v-textarea>
                </div>
                <v-color-picker class="mx-auto" show-swatches swatches-max-height="150px" v-model="codigo"></v-color-picker>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import axios from "axios"
import {
    agrega_tabla,
    busca_tabla
} from '../../db'
import store from '@/store/index'
export default {
    name: 'caja',
    props: {
        dial: '',
        edicion: ''
    },
    data() {
        return {
            dialogo_agrega: false,
            codigo: '',
            nombre: '',
            array_tabla: [],
            edit: false
        }
    },
    mounted() {
        busca_tabla('colores').on("value", this.onDataChange);
    },
    beforeDestroy() {
        busca_tabla('colores').off("value", this.onDataChange);
    },
    computed: {
        listafiltrada() {
            busca_tabla('colores').on("value", this.onDataChange);
            return this.array_tabla
        }
    },

    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_tabla = array;
        },
        nuevo() {
            this.codigo = ""
            this.nombre = ''
            this.edit = false
            this.dialogo_agrega = true
        },
        agregar() {
            store.commit("dialogoprogress", 1)
            if (this.edit) {
                var i = this.array_tabla.findIndex(array => array.nombre === this.nombre)
                this.array_tabla[i].codigo = this.codigo.trim()
                this.array_tabla[i].nombre = this.nombre.toUpperCase()
                this.edit = false
            } else {
                this.array_tabla.push({
                    codigo: this.codigo.trim(),
                    nombre: this.nombre.toUpperCase(),
                })
            }
            agrega_tabla("colores", this.array_tabla).then(() => {
                store.commit("dialogoprogress")
                this.dialogo_agrega = false
            })

        },
        editar(date) {
            this.edit = true
            this.codigo = date.codigo
            this.nombre = date.nombre
            this.dialogo_agrega = true
        },
        eliminar(item) {
            store.commit("dialogoprogress", 1)
            var i = this.array_tabla.findIndex(array => array.codigo === item.codigo)
            this.array_tabla.splice(i, 1)
            agrega_tabla("colores", this.array_tabla).then(() => {
                store.commit("dialogoprogress", 1)
                this.dialogo_agrega = false
            })
        },
        selecciona(item) {
            this.$emit('array', item)
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
