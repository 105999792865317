import jspdf from "jspdf";
import "jspdf-autotable";
import store from "@/store/index";
import QR from "qrcode-base64";
import moment from "moment";
import { NumerosALetras } from "numero-a-letras";
let modo_genera = "abre";
import { envia_host } from "./host_impresora";
export const pdfGenera = (arraydatos, cabecera, medida, modo) => {
  modo_genera = modo;
  var a = 0;
  if (cabecera.tipocomprobante != "T") {
    var qrs = generaQR(cabecera);
  }
  switch (medida) {
    case "A4":
      impresionA4(arraydatos, qrs, cabecera);
      break;
    case "80":
      a = impresion80(arraydatos, qrs, cabecera);
      break;
    case "58":
      impresion58(arraydatos, qrs, cabecera);
      break;
  }
  return a;
};
function impresion58(arraydatos, qr, cabecera) {
  var arraycabe = cabecera;
  var linea = parseInt(store.state.configImpresora.msuperior);
  var Ruc = "Ruc: " + store.state.baseDatos.ruc;
  var Direccion =
    store.state.baseDatos.direccion +
    "-" +
    store.state.baseDatos.distrito +
    "-" +
    store.state.baseDatos.provincia +
    "-" +
    store.state.baseDatos.departamento;
  var imagen = store.state.logoempresa;
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var fechaImpresion = moment
    .unix(arraycabe.fecha)
    .format("DD/MM/YYYY hh:mm a");
  var array = arraydatos;
  var total = (
    parseFloat(arraycabe.total_op_exoneradas) +
    parseFloat(arraycabe.total_op_gravadas) +
    parseFloat(arraycabe.igv)
  ).toFixed(2);

  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 2.5; //left margin in mm
  var rMargin = 1; //right margin in mm
  var pdfInMM = 55; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var piepagina = store.state.configImpresora.piepagina;
  var telefono = store.state.configImpresora.telefono;
  var pageCenter = pdfInMM / 2;

  switch (arraycabe.tipocomprobante) {
    case "T":
      var documento = "TICKET DE VENTA";
      if (store.state.seriesdocumentos.notaventa) {
        documento = "NOTA DE VENTA";
      }
      break;
    case "B":
      var documento = "BOLETA ELECTRONICA";
      break;
    case "F":
      var documento = "FACTURA ELECTRONICA";
      break;
  }

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM],
  });
  doc.setTextColor(10);
  doc.text(".", 0, linea);
  linea = linea + 3;
  //console.log(imagen)
  if (imagen != "") {
    doc.addImage(
      "data:image/png;base64," + imagen,
      "png",
      pdfInMM / 2 - 15,
      linea,
      30,
      30
    );
    linea = linea + parseInt(store.state.configImpresora.minferior) - 10; /// modificar margenes de logo
  }
  doc.setFontSize(7);
  doc.setFont("Helvetica", "Bold");
  var texto = doc.splitTextToSize(
    store.state.baseDatos.name,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center"); //EMPRESA

  linea = linea + 4 * texto.length;

  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(
    Ruc + "\n" + Direccion,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center"); //RUC

  linea = linea + 3 * texto.length;

  if (cabecera != "") {
    linea = linea + 2;
    var texto = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center"); //cabecera
    linea = linea + 3.5 * texto.length;
  }
  if (telefono != "") {
    var texto = doc.splitTextToSize(
      "Telf: " + telefono,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //cabecera
    linea = linea + 3.5 * texto.length;
  }

  doc.setFont("Helvetica", "bold");
  doc.setFontSize(7);
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 3;
  var texto = doc.splitTextToSize(documento, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3;
  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(
    arraycabe.serie + "-" + arraycabe.correlativoDocEmitido,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 5;

  var texto = doc.splitTextToSize(
    "EMISION: " + fechaImpresion,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 5;

  /*--------------datos cliente--------------------*/

  var texto = doc.splitTextToSize(
    "Nombre   : " + arraycabe.cliente,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, lMargin, linea, "left");
  linea = linea + 3.5 * texto.length;

  var texto = doc.splitTextToSize(
    "Documento: " + arraycabe.dni,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, lMargin, linea, "left");
  linea = linea + 3.5 * texto.length;

  if (arraycabe.direccion != "") {
    var texto = doc.splitTextToSize(
      "Direccion: " + arraycabe.direccion,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin, linea, "left");
    linea = linea + 3 * texto.length;
  }
  if (arraycabe.tipocomprobante != "T") {
    var texto = doc.splitTextToSize(
      "Condiciones: " + arraycabe.forma_pago,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin, linea, "left");
    linea = linea + 3.5 * texto.length;
  }

  if (arraycabe.observacion != "") {
    var texto = doc.splitTextToSize(
      "Observacion: " + arraycabe.observacion,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin, linea, "left");
    linea = linea + 3 * texto.length;
  }
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    //console.log(array[i])
    var obs = "";
    var tg = "";
    if (array[i].operacion == "GRATUITA") {
      obs = "*";
      tg = " / TG: S/." + array[i].valor_total;
      array[i].precioedita = "0.00";
    }
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] =
      array[i].nombre +
      "\n" +
      "- S/." +
      array[i].precioedita +
      " X " +
      array[i].medida +
      tg;
    nuevoArray[i][2] =
      parseFloat(array[i].precioedita * array[i].cantidad).toFixed(2) + obs;
  }

  doc.autoTable({
    margin: { top: linea - 9, left: 0 },
    styles: {
      fontSize: 7,
      cellPadding: 0.1,
      valign: "middle",
      halign: "center",
    },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 8, halign: "center" },
      1: { columnWidth: 30, halign: "left" },
      2: { columnWidth: 11, halign: "right" },
    },
    theme: ["plain"],
    head: [["Cant", "Descripcion", "P.T"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 2;

  //-------------------------------------------------------
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 3;
  if (arraycabe.modopago != "") {
    doc.setFont("Helvetica", "");
    doc.setFontSize(7);
    doc.text("MODO PAGO", lMargin, linea);
    doc.text(arraycabe.modopago, 50, linea, "right");
    linea = linea + 4;
  }
  if (arraycabe.total_op_gratuitas > 0) {
    doc.setFont("Helvetica", "");
    doc.setFontSize(7);
    var texto = doc.splitTextToSize(
      "* Transferencia Gratuita y/o Servicio Prestado Gratuitamente",
      50
    );
    doc.text(texto, pageCenter, linea, "center");
    linea = linea + 3 * texto.length;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 4;
  }

  doc.setFont("Helvetica", "");
  doc.setFontSize(7);
  if (arraycabe.tipocomprobante != "T") {
    doc.text("OP. GRAVADA", lMargin, linea);
    doc.text("S./" + arraycabe.total_op_gravadas, 50, linea, "right");
    linea = linea + 3.5;
    if (arraycabe.total_op_exoneradas > 0) {
      doc.text("OP. EXONERADA", lMargin, linea);
      doc.text(
        "S./" + arraycabe.total_op_exoneradas.toString(),
        50,
        linea,
        "right"
      );
      linea = linea + 3.5;
    }
    if (arraycabe.total_op_gratuitas > 0) {
      doc.text("OP. GRATUITAS", lMargin, linea);
      doc.text(
        "S./" + arraycabe.total_op_gratuitas.toString(),
        50,
        linea,
        "right"
      );
      linea = linea + 3.5;
    }
    doc.text("IGV " + arraycabe.porcentaje_igv + "%", lMargin, linea);
    doc.text("S./" + arraycabe.igv, 50, linea, "right");
    linea = linea + 3.5;
  }

  doc.text("Total", lMargin, linea);
  doc.text("S./" + total, 50, linea, "right");
  linea = linea + 3.5;

  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 3;
  doc.setFont("Helvetica", "");
  doc.setFontSize(7);

  doc.setFont("Helvetica", "");
  doc.setFontSize(7);
  var texto = doc.splitTextToSize(
    "Son: " + NumerosALetras(parseFloat(total).toFixed(2)),
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  if (arraycabe.tipocomprobante != "T") {
    doc.setFontSize(7);
    var texto = doc.splitTextToSize(
      "Representación Impresa de la " +
        documento +
        " Consultar su validez en https://factura-peru.web.app/buscardocumentos",
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center");
    linea = linea + 3 * texto.length;

    if (qr != "") {
      if (
        arraycabe.tipocomprobante == "F" ||
        arraycabe.tipocomprobante == "B"
      ) {
        doc.addImage(qr, "png", pdfInMM / 2 - 10, linea, 18, 18);
        linea = linea + 14;
      }
    }
  }

  if (arraycabe.forma_pago == "Credito") {
    linea = linea + 10;
    doc.autoTable({
      margin: { top: 10, left: 1 },
      startY: linea,
      styles: {
        fontSize: 7,
        cellPadding: 0.1,
        valign: "middle",
        halign: "center",
        lineWidth: 0.2,
        lineColor: 1,
      },
      headStyles: { lineWidth: 0.2, lineColor: 1 },
      columnStyles: {
        0: { columnWidth: 11, halign: "center", fontStyle: "bold" },
        1: { columnWidth: 22, halign: "center" },
        2: { columnWidth: 17, halign: "center" },
      },
      theme: ["plain"],
      head: [["CUOTA", "IMPORTE", "VENCE"]],
      body: cuotascredito(arraycabe.cuotas),
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
  }

  if (piepagina != "") {
    linea = linea + 7;
    doc.setFont("Helvetica", "");
    var texto = doc.splitTextToSize(piepagina, pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center");
  }
  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral);
  // console.log("aqui"+store.state.configImpresora.minferiorgeneral)
  doc.text(".", 0, linea);

  switch (modo_genera) {
    case "abre":
      if (store.state.esmovil) {
        window.open(doc.output("bloburi"));
      } else {
        abre_dialogo_impresion(doc.output("bloburi"));
      }
      break;
    case "host":
      break;
    case "descarga":
      doc.save(
        arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + ".pdf"
      );
      break;
  }
}
function impresion80(arraydatos, qr, cabecera) {
  var arraycabe = cabecera;
  var linea = parseInt(store.state.configImpresora.msuperior);
  var Ruc = "Ruc: " + store.state.baseDatos.ruc;
  var Direccion =
    store.state.baseDatos.direccion +
    "-" +
    store.state.baseDatos.distrito +
    "-" +
    store.state.baseDatos.provincia +
    "-" +
    store.state.baseDatos.departamento;
  var imagen = store.state.logoempresa;
  var separacion =
    "-------------------------------------------------------------------------------------------------------------------";
  var fechaImpresion = moment
    .unix(arraycabe.fecha)
    .format("DD/MM/YYYY hh:mm a");
  var array = arraydatos;
  var total = (
    parseFloat(arraycabe.total_op_exoneradas) +
    parseFloat(arraycabe.total_op_gravadas) +
    parseFloat(arraycabe.igv)
  ).toFixed(2);
  //formato de pagina de PF
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var lMargin = 3.5; //left margin in mm
  var rMargin = 2; //right margin in mm
  var pdfInMM = 75; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var piepagina = store.state.configImpresora.piepagina;
  var telefono = store.state.configImpresora.telefono;
  var pageCenter = pdfInMM / 2;

  switch (arraycabe.tipocomprobante) {
    case "T":
      var array_cliente = {
        nombre: arraycabe.cliente,
        dni: arraycabe.dni,
        direccion: arraycabe.direccion,
      };
      var documento = "TICKET DE VENTA";
      if (store.state.seriesdocumentos.notaventa) {
        documento = "NOTA DE VENTA";
      }
      break;
    case "B":
      var array_cliente = {
        nombre: arraycabe.cliente,
        dni: arraycabe.dni,
        direccion: arraycabe.direccion,
      };
      var documento = "BOLETA ELECTRONICA";
      break;
    case "F":
      var array_cliente = {
        nombre: arraycabe.nom_fact,
        dni: arraycabe.num_fact,
        direccion: arraycabe.dir_fact,
      };
      var documento = "FACTURA ELECTRONICA";
      break;
  }

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM],
  });
  doc.setTextColor(10);
  doc.text(".", 0, linea);
  linea = linea + 3;
  //console.log(imagen)
  if (imagen != "") {
    doc.addImage(
      "data:image/png;base64," + imagen,
      "png",
      pdfInMM / 2 - 20,
      linea,
      40,
      40
    );
    linea = linea + parseInt(store.state.configImpresora.minferior); /// modificar margenes de logo
  }
  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  var texto = doc.splitTextToSize(
    store.state.baseDatos.name,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center"); //EMPRESA

  linea = linea + 4 * texto.length;

  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(
    Ruc + "\n" + Direccion,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center"); //RUC

  linea = linea + 3.5 * texto.length;

  if (cabecera != "") {
    linea = linea + 2;
    var texto = doc.splitTextToSize(cabecera, pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center"); //cabecera
    linea = linea + 3.5 * texto.length;
  }
  if (telefono != "") {
    var texto = doc.splitTextToSize(
      "Telf: " + telefono,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center"); //cabecera
    linea = linea + 3.5 * texto.length;
  }

  doc.setFont("Helvetica", "bold");
  doc.setFontSize(8);
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 5;
  var texto = doc.splitTextToSize(documento, pdfInMM - lMargin - rMargin);
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3;
  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(
    arraycabe.serie + "-" + arraycabe.correlativoDocEmitido,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 5;

  var texto = doc.splitTextToSize(
    "EMISION: " + fechaImpresion,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 5;

  /*--------------datos cliente--------------------*/

  var texto = doc.splitTextToSize(
    "Nombre   : " + array_cliente.nombre,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, lMargin, linea, "left");
  linea = linea + 3.5 * texto.length;

  var texto = doc.splitTextToSize(
    "Documento: " + array_cliente.dni,
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, lMargin, linea, "left");
  linea = linea + 3.5 * texto.length;

  if (array_cliente.direccion != "") {
    var texto = doc.splitTextToSize(
      "Direccion: " + array_cliente.direccion,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin, linea, "left");
    linea = linea + 3.5 * texto.length;
  }

  if (Boolean(arraycabe.array_receta)) {
    var receta = arraycabe.array_receta;
    var texto = doc.splitTextToSize(
      "Refraccion: OD : Esf.: " +
        receta.esfera_od +
        "; Cil.: " +
        receta.cilindro_od,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin, linea, "left");
    linea = linea + 3.5 * texto.length;
    var texto = doc.splitTextToSize(
      "OI  : Esf.: " +
        receta.esfera_oi +
        "; Cil.: " +
        receta.cilindro_oi +
        "; ADD: " +
        receta.add,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, lMargin + 15, linea, "left");
    linea = linea + 3.5 * texto.length;
  }

  /*
    var texto = doc.splitTextToSize('Condiciones: ' + arraycabe.forma_pago, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3.5 * texto.length)
  */
  /*
  if (arraycabe.observacion != "") {
    var texto = doc.splitTextToSize('Observacion: ' + arraycabe.observacion, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3.5 * texto.length)
  }
*/
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 7;
  doc.text(separacion, pageCenter, linea, "center");

  //-----------------productos-----------------------
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    var obs = "";
    var tg = "";
    if (array[i].operacion == "GRATUITA") {
      obs = "*";
      tg = " / TG: S/. 0.00";
      array[i].precioedita = "0.00";
    }
    if (arraycabe.tamano == "larga") {
      var nom = array[i].nombre + "\n" + "-" + array[i].medida + tg;
    } else {
      if (array[i].id[0] == "P") {
        var txt = store.state.productos.find((item) => item.id == array[i].id);
        var final = txt.tipo_focal + " " + txt.tratamiento;
        var nom = final + "\n" + "-" + array[i].medida + tg;
      }
      if (array[i].id[0] == "M") {
        var pos = array[i].nombre.lastIndexOf(" ");
        var nom =
          array[i].nombre.substring(0, pos) + "\n" + "-" + array[i].medida + tg;
      }
      if (array[i].id[0] == "A") {
        var nom = array[i].nombre + "\n" + "-" + array[i].medida + tg;
      }
    }
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = nom;
    nuevoArray[i][2] = array[i].precioedita;
    nuevoArray[i][3] =
      parseFloat(array[i].precioedita * array[i].cantidad).toFixed(2) + obs;
  }

  doc.autoTable({
    margin: { top: linea - 9, left: 1 },
    styles: {
      fontSize: 7.5,
      cellPadding: 0.1,
      valign: "middle",
      halign: "center",
    },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 8, halign: "center" },
      1: { columnWidth: 35, halign: "left" },
      2: { columnWidth: 12, halign: "right" },
      3: { columnWidth: 12, halign: "right" },
    },
    theme: ["plain"],
    head: [["Cant", "Descripcion", "P.U", "P.T"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 2;

  //-------------------------------------------------------
  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 3;
  /*if (arraycabe.modopago != '') {
    doc.setFont('Helvetica', '');
    doc.setFontSize(7)
    doc.text("MODO PAGO", lMargin, linea)
    doc.text(arraycabe.modopago[0].nombre, 68, linea, 'right')
    linea = linea + 4
  }*/
  if (arraycabe.total_op_gratuitas > 0) {
    doc.setFont("Helvetica", "");
    doc.setFontSize(7);
    var texto = doc.splitTextToSize(
      "* Transferencia Gratuita y/o Servicio Prestado Gratuitamente",
      100
    );
    doc.text(texto, 2, linea, "left");
    linea = linea + 3;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 4;
  }

  doc.setFont("Helvetica", "");
  doc.setFontSize(8);
  if (arraycabe.tipocomprobante != "T") {
    doc.text("OP. GRAVADA", lMargin, linea);
    doc.text("S./" + arraycabe.total_op_gravadas, 68, linea, "right");
    linea = linea + 3.5;
    if (arraycabe.total_op_exoneradas > 0) {
      doc.text("OP. EXONERADA", lMargin, linea);
      doc.text(
        "S./" + arraycabe.total_op_exoneradas.toString(),
        68,
        linea,
        "right"
      );
      linea = linea + 3.5;
    }
    if (arraycabe.total_op_gratuitas > 0) {
      doc.text("OP. GRATUITAS", lMargin, linea);
      doc.text("S./ 0.00", 68, linea, "right");
      linea = linea + 3.5;
    }
    doc.text("IGV " + arraycabe.porcentaje_igv + "%", lMargin, linea);
    doc.text("S./" + arraycabe.igv, 68, linea, "right");
    linea = linea + 3.5;
  }
  doc.text("Total", lMargin, linea);
  doc.text("S./" + total, 68, linea, "right");
  linea = linea + 4;

  if (Boolean(arraycabe.a_cuenta)) {
    doc.text("A Cuenta", lMargin, linea);
    doc.text("S./" + arraycabe.a_cuenta, 68, linea, "right");
    linea = linea + 3.5;
  }

  doc.text("Saldo", lMargin, linea);
  doc.text(
    "S./" + (parseFloat(total) - parseFloat(arraycabe.a_cuenta)).toFixed(2),
    68,
    linea,
    "right"
  );
  linea = linea + 3.5;

  doc.setFont("Helvetica", "bold");
  doc.text(separacion, pageCenter, linea, "center");
  linea = linea + 3;
  doc.setFont("Helvetica", "");
  doc.setFontSize(8);
  /*
  doc.setFont('Helvetica', 'bold');
  var texto = doc.splitTextToSize('Vendedor : ' + arraycabe.vendedor, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + (3.5 * texto.length)*/
  if (arraycabe.vendedor != "" && arraycabe.vendedor != undefined) {
    doc.setFont("Helvetica", "");
    let indice = arraycabe.vendedor.indexOf(" ");
    let indice2 = arraycabe.vendedor.indexOf(",");
    var nom = String(
      arraycabe.vendedor.substring(indice2 + 2, arraycabe.vendedor.length)
    ).trim();
    let indc = nom.indexOf(" ");
    var vend =
      arraycabe.vendedor.substring(0, indice) + " " + nom.substring(0, indc);
    var texto = doc.splitTextToSize(
      "Vendedor: " + vend,
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center");
    linea = linea + 3.5 * texto.length;
    doc.setFont("Helvetica", "bold");
    doc.text(separacion, pageCenter, linea, "center");
    linea = linea + 3;
  }
  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(
    "Son: " + NumerosALetras(parseFloat(total).toFixed(2)),
    pdfInMM - lMargin - rMargin
  );
  doc.text(texto, pageCenter, linea, "center");
  linea = linea + 3.5 * texto.length;

  if (arraycabe.tipocomprobante != "T") {
    doc.setFontSize(8);
    var texto = doc.splitTextToSize(
      "Representación Impresa de la " +
        documento +
        " Consultar su validez en https://factura-peru.web.app/buscardocumentos",
      pdfInMM - lMargin - rMargin
    );
    doc.text(texto, pageCenter, linea, "center");
    linea = linea + 3 * texto.length;

    if (qr != "") {
      if (
        arraycabe.tipocomprobante == "F" ||
        arraycabe.tipocomprobante == "B"
      ) {
        doc.addImage(qr, "png", pdfInMM / 2 - 10, linea, 18, 18);
        linea = linea + 14;
      }
    }
  }

  if (arraycabe.forma_pago == "Credito") {
    linea = linea + 10;
    doc.autoTable({
      margin: { top: 10, left: 3 },
      startY: linea,
      styles: {
        fontSize: 7.5,
        cellPadding: 0.2,
        valign: "middle",
        halign: "center",
        lineWidth: 0.2,
        lineColor: 1,
      },
      headStyles: { lineWidth: 0.2, lineColor: 1 },
      columnStyles: {
        0: { columnWidth: 15, halign: "center", fontStyle: "bold" },
        1: { columnWidth: 26, halign: "center" },
        2: { columnWidth: 25, halign: "center" },
      },
      theme: ["plain"],
      head: [["CUOTA", "IMPORTE", "VENCE"]],
      body: cuotascredito(arraycabe.cuotas),
    });
    let finalY = doc.previousAutoTable.finalY;
    linea = finalY + 3;
  }

  if (piepagina != "") {
    linea = linea + 7;
    doc.setFont("Helvetica", "");
    var texto = doc.splitTextToSize(piepagina, pdfInMM - lMargin - rMargin);
    doc.text(texto, pageCenter, linea, "center");
  }
  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral);
  // console.log("aqui"+store.state.configImpresora.minferiorgeneral)
  doc.text(".", 0, linea);
  var a = 0;
  switch (modo_genera) {
    case "abre":
      if (store.state.esmovil) {
        window.open(doc.output("bloburi"));
      } else {
        abre_dialogo_impresion(doc.output("bloburi"));
      }
      break;
    case "host":
      envia_host(
        doc.output("blob"),
        arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + ".pdf",
        "caja"
      );
      break;
    case "host":
      envia_host(
        doc.output("blob"),
        arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + ".pdf",
        "caja"
      );
      break;
    case "sube":
      a = doc.output("blob");
      break;
    case "descarga":
      doc.save(
        arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + ".pdf"
      );
      break;
  }
  return a;
}
function abre_dialogo_impresion(blob) {
  var Ancho = screen.width;
  var Alto = screen.height;
  var A = (Ancho * 50) / 100;
  var H = (Alto * 50) / 100;
  var difA = Ancho - A;
  var difH = Alto - H;
  var tope = difH / 2;
  var lado = difA / 2;
  var Opciones =
    "status=no, menubar=no, directories=no, location=no, toolbar=no, scrollbars=yes, resizable=no, width=" +
    A +
    ", height=" +
    H +
    ", top=" +
    tope +
    ", left=" +
    lado +
    "";
  var w = window.open(blob, "_blank", Opciones);
  w.print();
}

function impresionA4(array, qr, arraycabecera) {
  var arraycabe = arraycabecera;
  var linea = parseInt(store.state.configImpresora.msuperior);
  var nombreEmpresa = store.state.baseDatos.name;
  var imagen = store.state.logoempresa;
  var Direccion =
    store.state.baseDatos.direccion +
    " - " +
    store.state.baseDatos.distrito +
    " - " +
    store.state.baseDatos.provincia +
    " - " +
    store.state.baseDatos.departamento;
  var fechaImpresion = moment
    .unix(arraycabe.fecha)
    .format("DD/MM/YYYY hh:mm a");
  var fecha_vencimiento = moment
    .unix(arraycabe.vencimientoDoc)
    .format("DD/MM/YYYY");
  var total = (
    parseFloat(arraycabe.total_op_exoneradas) +
    parseFloat(arraycabe.total_op_gravadas) +
    parseFloat(arraycabe.igv)
  ).toFixed(2);
  var totalDesc = arraycabe.descuentos;
  //formato de pagina de PF
  var guardadocumento = store.state.configImpresora.guardadocumento;
  var pdfInMM = 210; // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var telefono = store.state.configImpresora.telefono;
  var bancos = store.state.bancos;
  if (arraycabe.total_op_gratuitas == undefined) {
    arraycabe.total_op_gratuitas = 0;
  }
  switch (arraycabe.tipocomprobante) {
    case "T":
      var documento = "TICKET DE VENTA";
      if (store.state.seriesdocumentos.notaventa) {
        documento = "NOTA DE VENTA";
      }
      break;
    case "B":
      var documento = "BOLETA DE VENTA ELECTRONICA";
      break;
    case "F":
      var documento = "FACTURA ELECTRONICA";
      break;
  }

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [297, pdfInMM],
  });

  doc.text(".", -1, linea);
  linea = linea + 3;

  if (imagen != "") {
    doc.rect(10, 10, 30, 30);
    doc.addImage("data:image/png;base64," + imagen, "png", 12, 12, 26, 26);
    linea = linea + 15;

    doc.setFont("Helvetica", "Bold");
    doc.setFontSize(9.5);
    var texto = doc.splitTextToSize(nombreEmpresa, 80);
    doc.text(texto, 55, linea, "left"); //EMPRESA

    linea = linea + 4 * texto.length;

    if (cabecera != "") {
      doc.setFont("Helvetica", "");
      doc.setFontSize(8);
      var texto = doc.splitTextToSize(cabecera, 70);
      doc.text(texto, 55, linea, "left"); //CABECERA
      linea = linea + 4 * texto.length;
    }

    doc.setFont("Helvetica", "");
    doc.setFontSize(8);
    var texto = doc.splitTextToSize(Direccion, 70);
    doc.text(texto, 55, linea, "left"); //direccion

    linea = linea + 4 * texto.length;
    if (telefono != "") {
      doc.setFont("Helvetica", "");
      doc.setFontSize(8);
      var texto = doc.splitTextToSize("TELEFONO: " + telefono, 70);
      doc.text(texto, 55, linea, "left"); //TELEFONO EMPRESA
    }
  } else {
    linea = linea + 15;
    doc.setFont("Helvetica", "Bold");
    doc.setFontSize(15);
    var texto = doc.splitTextToSize(nombreEmpresa, 100);
    doc.text(texto, 10, linea, "left"); //EMPRESA

    linea = linea + 5 * texto.length;

    if (cabecera != "") {
      doc.setFont("Helvetica", "");
      doc.setFontSize(10);
      var texto = doc.splitTextToSize(cabecera, 120);
      doc.text(texto, 10, linea, "left"); //CABECERA
      linea = linea + 4 * texto.length;
    }

    doc.setFont("Helvetica", "");
    doc.setFontSize(8);
    var texto = doc.splitTextToSize(Direccion, 120);
    doc.text(texto, 10, linea, "left"); //direccion

    linea = linea + 4 * texto.length;
    if (telefono != "") {
      doc.setFont("Helvetica", "");
      doc.setFontSize(8);
      var texto = doc.splitTextToSize("TELEFONO: " + telefono, 70);
      doc.text(texto, 10, linea, "left"); //TELEFONO EMPRESA
    }
  }
  doc.setLineWidth(0.7);
  doc.rect(140, 10, 60, 25);

  doc.setFontSize(11);
  doc.setFont("Helvetica", "Bold");
  var texto = doc.splitTextToSize("Ruc: " + store.state.baseDatos.ruc, 50);
  doc.text(texto, 170, 18, "center");
  doc.setFontSize(8);
  var texto = doc.splitTextToSize(documento, 50);
  doc.text(texto, 170, 22, "center");
  doc.setFontSize(10);
  var texto = doc.splitTextToSize(
    arraycabe.serie + "-" + arraycabe.correlativoDocEmitido,
    50
  );
  doc.text(texto, 170, 26, "center");

  doc.setFontSize(8);
  doc.setLineWidth(0.3);
  doc.rect(10, 40, 190, 20);
  linea = 45;

  doc.setFont("Helvetica", "Bold");
  doc.text("SEÑORES", 15, linea, "left");
  doc.text(" : ", 32, linea, "left");
  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(arraycabe.cliente, 85);
  doc.text(texto, 36, linea, "left");
  linea = linea + 4 * texto.length;

  doc.setFont("Helvetica", "Bold");
  doc.text("RUC", 15, linea, "left");
  doc.text(" : ", 32, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text(arraycabe.dni, 36, linea, "left");
  linea = linea + 4;

  doc.setFont("Helvetica", "Bold");
  doc.text("DIRECCION", 15, linea, "left");
  doc.text(" : ", 32, linea, "left");
  doc.setFont("Helvetica", "");
  var texto = doc.splitTextToSize(arraycabe.direccion, 85);
  doc.text(texto, 36, linea, "left");
  linea = linea + 4 * texto.length;

  linea = 45;

  doc.setFont("Helvetica", "Bold");
  doc.text("FECHA EMISION", 130, linea, "left");
  doc.text(" : ", 164, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text(fechaImpresion, 167, linea, "left");
  linea = linea + 4;

  if (arraycabe.vencimientoDoc.toString().length == 10) {
    doc.setFont("Helvetica", "Bold");
    doc.text("FECHA VENCIMIENTO", 130, linea, "left");
    doc.text(" : ", 164, linea, "left");
    doc.setFont("Helvetica", "");
    doc.text(fecha_vencimiento, 167, linea, "left");
    linea = linea + 4;
  }

  doc.setFont("Helvetica", "Bold");
  doc.text("CONDICIONES", 130, linea, "left");
  doc.text(" : ", 164, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text(arraycabecera.forma_pago, 167, linea, "left");

  linea = 65;

  //-----------------productos-----------------------
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    var obs = "";
    var tg = "";
    if (array[i].operacion == "GRATUITA") {
      obs = "*";
      tg = " / TG: S/." + array[i].valor_total;
      array[i].precioedita = "0.00";
    }
    nuevoArray[i] = new Array(5);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = array[i].nombre + tg;
    nuevoArray[i][2] = array[i].medida;
    nuevoArray[i][3] = array[i].precioedita;
    nuevoArray[i][4] =
      parseFloat(array[i].precioedita * array[i].cantidad).toFixed(2) + obs;
  }

  doc.autoTable({
    margin: { top: linea, left: 10 },
    styles: {
      fontSize: 8,
      cellPadding: 1,
      valign: "middle",
      halign: "center",
      lineWidth: 0.2,
      lineColor: 1,
    },
    headStyles: { lineWidth: 0.2, lineColor: 1 },
    columnStyles: {
      0: { columnWidth: 20, halign: "center" },
      1: { columnWidth: 110, halign: "left" },
      2: { columnWidth: 20, halign: "center" },
      3: { columnWidth: 20, halign: "center" },
      4: { columnWidth: 20, halign: "center" },
    },
    theme: ["plain"],
    head: [["Cantidad", "Descripcion", "Medida", "P.Unitario", "P.Total"]],
    body: nuevoArray,
  });

  let finalY = doc.previousAutoTable.finalY;
  linea = finalY + 5;
  var lineaqr = linea;
  if (arraycabe.total_op_gratuitas > 0) {
    doc.setFont("Helvetica", "");
    doc.setFontSize(8);
    var texto = doc.splitTextToSize(
      "* Transferencia Gratuita y/o Servicio Prestado Gratuitamente",
      100
    );
    doc.text(texto, 10, lineaqr, "left");
    lineaqr = lineaqr + 3;
  }

  doc.setFont("Helvetica", "");
  doc.setFontSize(8);
  var texto = doc.splitTextToSize(
    "Son: " + NumerosALetras(parseFloat(total).toFixed(2)),
    100
  );
  doc.text(texto, 10, lineaqr, "left");
  lineaqr = lineaqr + 3;
  let nextSection = 1;
  let currentSection;
  const remainingVSpace =
    doc.internal.pageSize.height - doc.lastAutoTable.finalY;
  if (remainingVSpace > 50) {
    nextSection = currentSection;
    linea = doc.lastAutoTable.finalY + 4;
    lineaqr = doc.lastAutoTable.finalY + 10;
  } else {
    linea = 10;
    lineaqr = 10;
    if (nextSection == 1) doc.addPage();
  }
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.3);
  doc.rect(130, linea, 70, 25);

  linea = linea + 4;
  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  doc.text("OP. GRAVADA", 135, linea, "left");
  doc.text(" : ", 159, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text("S./" + arraycabe.total_op_gravadas, 190, linea, "right");
  linea = linea + 4;

  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  doc.text("OP. EXONERADA", 135, linea, "left");
  doc.text(" : ", 159, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text(
    "S./" + arraycabe.total_op_exoneradas.toString(),
    190,
    linea,
    "right"
  );
  linea = linea + 4;

  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  doc.text("OP. GRATUITAS", 135, linea, "left");
  doc.text(" : ", 159, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text(
    "S./" + arraycabe.total_op_gratuitas.toString(),
    190,
    linea,
    "right"
  );
  linea = linea + 4;

  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  doc.text("IGV " + arraycabe.porcentaje_igv + "%", 135, linea, "left");
  doc.text(" : ", 159, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text("S./" + arraycabe.igv, 190, linea, "right");
  linea = linea + 4;

  doc.setFontSize(8);
  doc.setFont("Helvetica", "Bold");
  doc.text("Total", 135, linea, "left");
  doc.text(" : ", 159, linea, "left");
  doc.setFont("Helvetica", "");
  doc.text("S./" + total.toString(), 190, linea, "right");
  linea = linea + 4;

  if (arraycabe.forma_pago == "Credito") {
    doc.autoTable({
      margin: { top: 10, left: 10 },
      styles: {
        fontSize: 8,
        cellPadding: 1,
        valign: "middle",
        halign: "center",
        lineWidth: 0.2,
        lineColor: 1,
      },
      headStyles: { lineWidth: 0.2, lineColor: 1 },
      columnStyles: {
        0: { columnWidth: 35, halign: "center", fontStyle: "bold" },
        1: { columnWidth: 35, halign: "center" },
        2: { columnWidth: 40, halign: "center" },
      },
      theme: ["plain"],
      head: [["CUOTA", "IMPORTE", "VENCE"]],
      body: cuotascredito(arraycabe.cuotas),
    });
    let finalY = doc.previousAutoTable.finalY;
    lineaqr = finalY + 3;
  }

  if (qr != "") {
    if (arraycabe.tipocomprobante == "F" || arraycabe.tipocomprobante == "B") {
      doc.addImage(qr, "png", 10, lineaqr, 20, 20);
    }
    if (arraycabe.tipocomprobante != "T") {
      lineaqr = lineaqr + 15;
      doc.setFont("Helvetica", "");
      doc.setFontSize(7);
      var texto = doc.splitTextToSize(
        "Representación Impresa de la " +
          documento +
          " Consultar su validez en https://factura-peru.web.app/buscardocumentos",
        90
      );
      doc.text(texto, 35, lineaqr, "left");
    }
  }

  linea = linea + 15;
  doc.text(".", 0, linea);

  switch (modo_genera) {
    case "abre":
      if (store.state.esmovil) {
        window.open(doc.output("bloburi"));
      } else {
        abre_dialogo_impresion(doc.output("bloburi"));
      }
      break;
    case "host":
      break;
    case "descarga":
      doc.save(
        arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + ".pdf"
      );
      break;
  }
}

export const generaQR = (cabecera) => {
  var ruc = store.state.baseDatos.ruc;
  var fecha = moment.unix(cabecera.fecha).format("DD/MM/YYYY");
  var imgData = QR.drawImg(
    ruc +
      "|" +
      cabecera.cod_comprobante +
      "|" +
      cabecera.serie +
      "|" +
      cabecera.correlativoDocEmitido +
      "|" +
      cabecera.igv +
      "|" +
      cabecera.total +
      "|" +
      fecha +
      "|" +
      cabecera.cod_tipoDocumento +
      "|" +
      cabecera.dni +
      "|",
    {
      typeNumber: 4,
      errorCorrectLevel: "M",
      size: 500,
    }
  );
  return imgData;
};
function arraybancos(array) {
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].banco;
    nuevoArray[i][1] = array[i].moneda;
    nuevoArray[i][2] = array[i].cuenta;
    nuevoArray[i][3] = array[i].cci;
  }
  return nuevoArray;
}
function cuotascredito(array) {
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    nuevoArray[i] = new Array(3);
    nuevoArray[i][0] = array[i].numero;
    nuevoArray[i][1] = array[i].importe;
    nuevoArray[i][2] = array[i].vencimiento;
  }
  return nuevoArray;
}
