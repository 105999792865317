<template>
    <v-dialog v-model="dial" max-width="650" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <strong>Fecha Medicion : </strong> {{ conviertefecha(fecha) }}
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-card class="pa-1">
                <v-row dense>
                    <v-col cols="12">
                        <p style="font-size:80%;"> <strong>Especialista :</strong> {{ especialista }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense class="mt-n6 ">
                    <v-col cols="6">
                        <p style="font-size:80%;"> <strong>Diagnostico :</strong> {{ diagnostico }}
                        </p>
                    </v-col>
                    <v-col cols="6" class="mt-n6 mb-n1">
                        <p style="font-size:80%;">
                        </p>
                    </v-col>
                </v-row>
                <v-row dense class="mt-n6 mb-n1">
                    <v-col cols="12">
                        <p style="font-size:80%;"> <strong>Comentarios :</strong> {{ comentarios }}
                        </p>
                    </v-col>
                </v-row>
            </v-card>

            <v-row class="mt-2" style="font-size:85%;">
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n4 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OD
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>Esfera:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ esfera_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Cilindro:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ cilindro_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>EJE:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ eje_od }}°
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>DNP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dnp_od }}mm
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>A/V:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    20/{{ av_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>PIO:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ pio_od }}mmHg
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n4 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OI
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>Esfera:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ esfera_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Cilindro:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ cilindro_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>EJE:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ eje_oi }}°
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>DNP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dnp_oi }}mm
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>A/V:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    20/{{ av_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>PIO:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ pio_oi }}mmHg
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

            </v-row>
            <v-row class="mt-n4" style="font-size:85%;">
                <v-col cols="12">
                    <v-card>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>ADD:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ add }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>DIP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dip }}mm
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    allMonturas,
} from '../../db'
export default {
    props: {
        data: ''
    },
    data() {
        return {
            dial: false,
            esfera_od: 0,
            cilindro_od: 0,
            eje_od: 0,
            dnp_od: 25,
            av_od: '',
            pio_od: '',
            esfera_oi: 0,
            cilindro_oi: 0,
            eje_oi: 0,
            dnp_oi: 25,
            av_oi: '',
            pio_oi: '',
            add: 0,
            dip: 0,
            nom_paciente: '',
            especialista: '',
            diagnostico: '',
            comentarios: '',
            recomendaciones: '',
            fecha: '',
            array_recom: []
        }
    },
    created() {

        this.inicio()
    },
    mounted() {

    },
    beforeDestroy() {

    },
    computed: {
        recomenda() {
            return this.array_recom
        }
    },
    methods: {
        inicio() {
            console.log(this.data)
            var array = this.data[0]
            this.esfera_od = array.esfera_od
            this.esfera_oi = array.esfera_oi
            this.cilindro_od = array.cilindro_od
            this.cilindro_oi = array.cilindro_oi
            this.eje_od = array.eje_od
            this.eje_oi = array.eje_oi
            this.dnp_od = array.dnp_od
            this.dnp_oi = array.dnp_oi
            this.av_od = array.av_od
            this.av_oi = array.av_oi
            this.pio_od = array.pio_od
            this.pio_oi = array.pio_oi
            this.add = array.add
            this.dip = array.dip
            this.nom_paciente = array.nom_cliente
            this.especialista = array.especialista
            this.diagnostico = array.diagnostico
            this.comentarios = array.comentarios
            this.fecha = array.fecha
            this.recomendaciones = array.recomendaciones
            this.array_recom = array.array_recom
            this.dial = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD-MM-YYYY')
        },
        cierra() {
            this.$emit('cierra', false)
        }

    },

}
</script>
