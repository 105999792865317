<template>
    <v-dialog v-model="dial" max-width="750" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-btn class="" color="red" @click="abre_buscar()" x-small>Busqueda x modelo</v-btn>
            </v-system-bar>
        </div>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field v-model="buscar" type="number" append-icon="mdi-magnify" label="Buscar" single-line
                    hide-details dense></v-text-field>
            </v-card-title>
            <v-data-table class="elevation-1 mt-1" fixed-eadher height='70vh' :headers="headers" :items="lista_producto"
                :items-per-page="15" hide-default-footer mobile-breakpoint="1" dense>
                <template v-slot:item="{ item }">
                    <tr @click="agrega_montura(item)">
                        <td style="font-size:75%;">{{ item.id }}</td>
                        <td style="font-size:75%;">{{ item.marca }}</td>
                        <td style="font-size:75%;">{{ item.modelo }}</td>
                        <td style="font-size:75%;">S/.{{ item.precio }}</td>
                    </tr>

                </template>
            </v-data-table>

        </v-card>
        <v-dialog v-model="dialo_cantidad" max-width="350px">
            <v-card class="pa-3">
                <h4 class="mb-2">CODIGO : {{ item_selecto.id }}</h4>
                <v-text-field type="number" autofocus outlined dense v-model="precio" :label="precio_min"
                    @focus="$event.target.select()" @keyup.enter="agregaCatalogo()"></v-text-field>

                <v-row class="mt-2 mb-6 text-center" dense v-if="false">
                    <v-col cols="4">
                        <v-icon @click="suma()" color="green">mdi-plus</v-icon>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field type="number" outlined dense v-model="cantidad" label="CANTIDAD"
                            @keyup.enter="agregaCatalogo()"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-icon @click="resta()" color="red">mdi-minus</v-icon>
                    </v-col>
                </v-row>
                <v-btn class="mt-n6" color="red" @click="agregaCatalogo()" block>OK</v-btn>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dial_modelos" max-width="650">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="(dial_modelos = !dial_modelos)">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-5">
                <h4 class="text-center"> INGRESE MODELO </h4>
                <v-text-field autofocus v-model="modelo" append-icon="mdi-magnify" label="INGRESE MODELO" single-line
                    hide-details dense @keyup.enter="busca_modelos(modelo)"></v-text-field>
                <v-simple-table class="elevation-1 mt-1" fixed-header height="60vh">
                    <template v-slot:default>
                        <thead>

                            <tr>
                                <th class="text-left">
                                    id
                                </th>
                                <th class="text-left">
                                    Marca
                                </th>
                                <th class="text-left">
                                    Modelo
                                </th>
                                <th class="text-left">
                                    Color
                                </th>
                                <th class="text-left">
                                    Precio
                                </th>
                                <th class="text-left">
                                    Ubicacion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in array_modelos" :key="item.id">
                                <td style="font-size:75%;">{{ item.id }}</td>
                                <td style="font-size:75%;">{{ item.marca }}</td>
                                <td style="font-size:75%;">{{ item.modelo }}</td>
                                <td style="font-size:75%;">{{ item.color }}</td>
                                <td style="font-size:75%;">S/.{{ item.precio }}</td>
                                <td style="font-size:75%;">{{ item.ubicacion }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import store from '@/store/index'
import {
    allMonturas,
} from '../../db'
export default {
    props: {

    },
    data() {
        return {
            lista_modelos: false,
            modelo: '',
            dial_modelos: false,
            array_modelos: [],
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Modelo',
                value: 'modelo',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            ],
            buscar: '',
            array_tabla: [],
            dialo_cantidad: false,
            cantidad: '',
            precio: '',
            precio_min: '',
            item_selecto: [],
            dial: false,

        }
    },
    created() {
        this.dial = true
    },

    computed: {
        lista_producto() {
            this.array_tabla = store.state.monturas
            return this.array_tabla.filter((item) =>
                (item.id + item.marca + item.modelo)
                    .toLowerCase().includes(this.buscar.toLowerCase()))
                .filter((item) => item.stock > 0)
        },
    },
    methods: {
        abre_buscar() {
            this.array_modelos = []
            this.dial_modelos = true
            this.modelo = ''
        },
        async busca_modelos(val) {
            this.array_modelos = []
            const regex = /^[0-9]*$/;
            const onlyNumbers = regex.test(val); // true
            var dd = Number(val)
            var bb = val
            console.log(bb)
            var snap = await allMonturas().orderByChild('modelo').equalTo(bb).once('value')
            if (snap.exists()) {
                snap.forEach((item) => {
                    let data = item.val();
                    console.log(data.stock)
                    if (data.stock != 0) {
                        this.array_modelos.push(data);
                    }
                });
            }

            var snaps = await allMonturas().orderByChild('modelo').equalTo(dd).once('value')
            if (snaps.exists()) {
                snaps.forEach((item) => {
                    let data = item.val();
                    console.log(data.stock)
                    if (data.stock != 0) {
                        this.array_modelos.push(data);
                    }
                });
            }
            this.lista_modelos = true
            this.modelo = ''
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_tabla = array;
            this.dial = true

        },
        agrega_montura(item) {
            this.precio_min = "PRECIO MIN : S/." + item.precio_min
            this.precio = item.precio
            this.cantidad = 1
            this.item_selecto = item
            this.dialo_cantidad = true
        },
        agregaCatalogo() {
            var item = this.item_selecto
            console.log(item)
            var operacion = item.operacion
            if (this.precio == 0) {
                operacion = 'GRATUITA'
            }
            var array = {
                id: item.id,
                marca: item.marca,
                modelo: item.modelo,
                cantidad: this.cantidad,
                precio: this.precio,
                operacion: operacion,
                tipoproducto: item.tipoproducto,
                costo: item.costo
            }
            if (parseFloat(this.precio) < parseFloat(item.precio_min)) {
                store.commit('dialogosnackbar', 'Revise Precio')
            } else {
                this.$emit('array', array)
                this.dialo_cantidad = false
            }

        },
        suma() {
            this.cantidad++
        },
        resta() {
            this.cantidad--
        },
        cierra() {
            this.$emit('cierra', false)
        },


    },

}
</script>
