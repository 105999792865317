<template>
    <div class="mb-6 mt-1 pa-4">

        <v-row dense>
            <v-col cols="6" sm="4">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date" label="Inicio"></v-text-field>
            </v-col>
            <v-col cols="6" sm="4">
                <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" :class="$vuetify.breakpoint.smAndDown ? 'mt-n6' : ''">
                <v-text-field class="mx-1" outlined dense v-model="num_doc" label="Busca Documento"
                    append-icon="mdi-magnify" @click:append="busca()" :prepend-inner-icon="tipo_doc"
                    @click:prepend-inner="cambia_doc()" @keyup.enter="busca()"></v-text-field>
            </v-col>
        </v-row>
        <v-btn color="success" v-if="false" @click='modificafechas()'>text</v-btn>
        <v-row class="mt-n8">
            <v-col cols="6">
                <h4 class="text-center"> Boletas: S/.{{ sumaventas().boleta }}</h4>
            </v-col>
            <v-col cols="6">
                <h4 class="text-center"> Facturas: S/.{{ sumaventas().factura }}</h4>
            </v-col>
        </v-row>

        <v-simple-table fixed-header height="70vh" dense>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            Correlativo
                        </th>
                        <th class="text-left" v-if="!$store.state.esmovil">
                            Cliente
                        </th>
                        <th class="text-left">
                            Fecha
                        </th>
                        <th class="text-left">
                            Estado
                        </th>
                        <th class="text-left">
                            Total
                        </th>
                        <th class="text-left">
                            Accion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in listafiltrada" :key="item.id">
                        <td style="font-size:75%;">{{ item.numeracion }}</td>
                        <td style="font-size:75%;" v-if="!$store.state.esmovil">{{ item.dni + ' - ' + item.cliente }}</td>
                        <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                        <td>
                            <v-icon @click="consultaApisunat(item)" :color="item.color">mdi-circle</v-icon>
                        </td>
                        <td style="font-size:75%;">S/.{{ redondear(item.total) }}</td>
                        <td width="100">
                            <v-row>
                                <v-col cols="6" xs="6">
                                    <v-icon color="green"
                                        @click.prevent="ejecutaConsolida(item.numeracion)">mdi-eye</v-icon>
                                </v-col>
                                <v-col cols="6" xs="6">
                                    <v-icon color="red"
                                        @click.prevent="selecciona_item(item)">mdi-text-box-search-outline</v-icon>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>

        <v-dialog v-model="dialog" max-width="850px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialog = !dialog">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-row dense>
                    <v-col cols="12">
                    </v-col>
                </v-row>
                <v-simple-table dark fixed-header max-width="68vh" dense>
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Medida
                                </th>
                                <th class="text-left">
                                    Cantidad.
                                </th>
                                <th class="text-left">
                                    Precio
                                </th>
                                <th class="text-left">
                                    Total
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in arrayConsolidar" :key="item.id">
                                <td>{{ item.id }}-{{ item.nombre }}</td>
                                <td>{{ item.medida }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>S/.{{ item.precioedita }}</td>
                                <td>S/.{{ redondear(item.precioedita * item.cantidad) }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogocomprobante" max-width="590">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogocomprobante = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-2">
                <v-card-text>
                    <span class="red--text">RESPUESTA SUNAT:</span> {{ seleccionado.mensajeSunat }}
                </v-card-text>
                <v-row dense class="text-center mt-n5">
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="descargaXML()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/xml.png"></v-img>
                                <h5 block class="text-center">XML</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="descargaCDR()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/cdr.png"></v-img>
                                <h5 block class="text-center">CDR</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="consultar()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/sunat.png"></v-img>
                                <h5 block class="text-center">Consulta Sunat</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="bajaC()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/anular.png"></v-img>
                                <h5 block class="text-center">ANULAR</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="reenvia()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/reenvia.png"></v-img>
                                <h5 block class="text-center">Reenvia Sunat</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="convertir()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/convertir.png"></v-img>
                                <h5 block class="text-center">Convertir</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" sm="3">
                        <v-card @click.prevent="nota_cred()">
                            <v-container>
                                <v-img class="mx-auto" height="30" width="30" src="/nota_credito.png"></v-img>
                                <h5 block class="text-center">Nota de Credito</h5>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <imprime v-if="genera_pdf" :data="seleccionado" @cierra="genera_pdf = $event" />

        <fecha @fecha="date = $event, busca()" />
        <fecha2 @fecha="date2 = $event, busca()" />
        <notas v-if="dial_nota" :items="data_nota_items" :cabecera="data_cabecera_n" @cierra="dial_nota = false" />
        <dial_convertir v-if="dial_convertir" :cabecera="seleccionado" :items="items_convertir"
            @cierra="dial_convertir = false, busca()" />
    </div>
</template>

<script>
import dial_convertir from '@/views/sunat/convertir'
import {
    allCabecera,
    consultaDetalle,
    grabaconsultacomprobante,
    grabaDatoC,
    consulta_Cabecera,
    editaMontura
} from '../../db'
import store from '@/store/index'
import imprime from '@/components/dialogos/dialog_imprime'
import {
    ejecutaresumen
} from '../../resumenes'
import fecha from '@/components/dialogos/dialogFecha'
import fecha2 from '@/components/dialogos/dialogFecha2'
import moment from 'moment'
import {
    enviaDocumentoApiSunat,
    descargaXML,
    descargaCDR,
    consultasunat
} from '../../servidorsunat'
import notas from '@/components/dialogos/dialogo_Nota'
import {
    modifica_stock_array
} from '../../control_stock'
export default {
    components: {
        fecha,
        fecha2,
        imprime,
        dial_convertir,
        notas
    },

    data: () => ({
        dial_nota: false,
        data_nota_items: [],
        data_cabecera_n: [],
        dial_convertir: false,
        dialogocomprobante: false,
        dialogoprogress: false,
        genera_pdf: false,
        documento: '',
        desserts: [],
        documentoEnvio: '',
        dialog: false,
        arrayConsolidar: [],
        buscar: '',
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        rechazados: 0,
        img: '',
        error: '',
        seleccionado: '',
        num_doc: '',
        tipo_doc: 'B',
        items_convertir: []

    }),

    computed: {
        listafiltrada() {
            this.desserts.reverse()
            return this.desserts.filter((item) => (item.numeracion)
                .toLowerCase().includes(this.num_doc.toLowerCase()))
        }
    },
    created() {
        this.busca()
    },
    methods: {
        async convertir() {
            var array = []
            var data = this.seleccionado
            var snapshot = await consultaDetalle(data.numeracion).once("value")
            snapshot.forEach((item) => {
                array.push(item.val())
            })
            console.log(array)
            this.items_convertir = array
            this.dialogocomprobante = false
            this.dial_convertir = true
        },
        selecciona_item(item) {
            this.seleccionado = item
            this.genera_pdf = true
        },
        consultaApisunat(item) {
            console.log(item)
            store.commit("dialogoprogress")
            this.seleccionado = item
            this.arrayConsolidar = []
            consultaDetalle(item.numeracion).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.arrayConsolidar.push(item.val())
                })
                store.commit("dialogoprogress")
                this.dialogocomprobante = true
            })
        },
        comparafecha(date) {
            var hoy = moment(String(new Date())).add(-50, 'd').format('YYYY-MM-DD')
            var fecha = moment.unix(date).format('YYYY-MM-DD')
            if (moment(fecha).isAfter(hoy)) {
                return true
            } else {
                return false
            }
        },
        reenvia() {
            var cabecera = this.seleccionado
            if (this.comparafecha(cabecera.fecha)) {
                store.commit("dialogoprogress", 1)
                var items = this.arrayConsolidar
                grabaDatoC(cabecera.numeracion, "automata", '')
                enviaDocumentoApiSunat(cabecera, items).then(r => {
                    store.commit('dialogosnackbar', r.data)
                    store.commit("dialogoprogress", 1)
                    this.dialogocomprobante = false
                    this.busca()
                })
            } else {
                store.commit('dialogosnackbar', 'FECHA EXCEDE EL LIMITE PERMITIDO')
            }
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        sumaventas() {
            var array = {}
            var ventaboleta = 0
            var ventafactura = 0
            for (var i = 0; i < this.desserts.length; i++) {
                if (this.desserts[i].tipocomprobante == 'B') {
                    ventaboleta = ventaboleta + parseFloat(this.desserts[i].total)
                }
                if (this.desserts[i].tipocomprobante == 'F') {
                    ventafactura = ventafactura + parseFloat(this.desserts[i].total)
                }
            }

            array = {
                boleta: this.redondear(ventaboleta),
                factura: this.redondear(ventafactura),
            }
            return array
        },
        ejecutaConsolida(value) {
            store.commit("dialogoprogress", 1)
            this.arrayConsolidar = []
            consultaDetalle(value).once("value").then((snapshot) => {
                snapshot.forEach((item) => {
                    this.arrayConsolidar.push(item.val())
                })
                store.commit("dialogoprogress", 1)
                this.dialog = true
            })
        },

        redondear(valor) {
            return parseFloat(valor).toFixed(2)
        },
        descargaXML() {
            if (this.seleccionado.tipocomprobante == "F") {
                var tipodoc = '01'
                var serie = this.seleccionado.serie
            } else {
                var tipodoc = '03'
                var serie = this.seleccionado.serie
            }
            descargaXML(store.state.baseDatos.ruc, tipodoc, serie, this.seleccionado.correlativoDocEmitido)
        },
        descargaCDR() {
            if (this.seleccionado.tipocomprobante == "F") {
                var tipodoc = '01'
                var serie = this.seleccionado.serie
            } else {
                var tipodoc = '03'
                var serie = this.seleccionado.serie
            }
            descargaCDR(store.state.baseDatos.ruc, tipodoc, serie, this.seleccionado.correlativoDocEmitido)
        },
        consultar() {
            store.commit("dialogoprogress", 1)
            if (this.seleccionado.tipocomprobante == "F") {
                var tipodoc = '01'
                var serie = this.seleccionado.serie
            } else {
                var tipodoc = '03'
                var serie = this.seleccionado.serie
            }
            console.log(tipodoc, serie,
                this.seleccionado.numeracion.substring(1))
            consultasunat(tipodoc, serie,
                this.seleccionado.correlativoDocEmitido).then((r) => {
                    store.commit('dialogosnackbar', r)

                    if (r.data == '0002') {
                        grabaconsultacomprobante(this.seleccionado.numeracion, "RECHAZADO", r.hash)
                        grabaDatoC(this.seleccionado.numeracion, "automata", '0002')
                        this.dialogocomprobante = false
                    }
                    if (r.data == '0001') {
                        grabaconsultacomprobante(this.seleccionado.numeracion, "aprobado", r.hash)
                        grabaDatoC(this.seleccionado.numeracion, "automata", '0001')
                        this.dialogocomprobante = false
                    } else {
                        grabaconsultacomprobante(this.seleccionado.numeracion, "error", r.hash)
                        this.dialogocomprobante = false
                    }
                    this.busca()
                    store.commit("dialogoprogress", 1)
                })
        },
        editacomprobante() {
            for (var i = 0; i < this.desserts.length; i++) {
                //grabaDatoC(this.desserts[i].numeracion,"automata","")
                // grabaconsultacomprobante(this.desserts[i].numeracion,"rechazado","El comprobante fue registrado previamente con otros datos - Detalle: xxx.xxx.xxx value='ticket: 202208635648110 error: El comprobante B001-00000094 fue informado anteriormente'")
                console.log(this.desserts[i].numeracion)
                if (this.desserts[i].tipocomprobante == "F") {
                    var tipodoc = '01'
                    var serie = this.desserts[i].serie
                } else {
                    var tipodoc = '03'
                    var serie = this.desserts[i].serie
                }
                this.consultamasiva(tipodoc, serie, this.desserts[i], this.desserts.length - 1, i)

            }
        },
        consultamasiva(tipodoc, serie, array, tam, pos) {
            consultasunat(tipodoc, serie,
                array.numeracion.substring(1)).then((r) => {
                    console.log(r)
                    // store.commit('dialogosnackbar',r.data)
                    if (r.data == '0001') {
                        grabaDatoC(array.numeracion, "automata", '0001')
                        grabaconsultacomprobante(array.numeracion, "aprobado", r.hash)
                        //    this.dialogocomprobante=false
                    } else {
                        grabaconsultacomprobante(array.numeracion, "RECHAZADO", r.hash)
                        //  this.dialogocomprobante=false
                    }
                    if (tam == pos) {
                        //store.commit("dialogoprogress")
                    }
                    //  this.dialogoprogress=false   
                })
        },
        async bajaC() {
            var array = this.seleccionado
            var lista_pro = []
            store.commit("dialogoprogress", 1)
            grabaDatoC(array.numeracion, "automata", "")
            let items = await consultaDetalle(array.numeracion).once("value")
            var data = items.val()
            var monturas = data.filter(item => item.id[0] == 'M')
            if (monturas != '') {
                if (confirm('EXISTE MONTURA EN ESTA VENTA, DESEA REGRESAR STOCK?'))
                    for (var i = 0; i < monturas.length; i++) {
                        var ll = monturas[i]
                        console.log(ll)
                        await editaMontura(ll.id + '/stock', 1)
                    }
            }
            await ejecutaresumen(array)
            this.dialogocomprobante = false
            store.commit("dialogoprogress", 1)
            this.busca()

        },
        modificafechas() {
            for (var i = 0; i < this.desserts.length; i++) {
                if (this.desserts[i].estado != 'aprobado') {
                    console.log("rechazado")
                    /*  grabaDatoC(this.desserts[i].numeracion, "serie", 'B003')
                      grabaDatoC(this.desserts[i].numeracion, "fecha", 1674508422)
                      grabaDatoC(this.desserts[i].numeracion, "vencimientoDoc", 1674508422)*/
                }
            }
        },
        busca() {
            var array = []
            this.desserts = []
            if (this.num_doc != '') {
                var data = this.tipo_doc + (this.num_doc).toString().padStart(8, 0)
                consulta_Cabecera(data)
                    .once("value").then((snapshot) => {
                        if (snapshot.exists()) {
                            var data = snapshot.val()
                            data.color = this.asigna_color_doc(data),
                                this.desserts.push(data)
                        } else {
                            store.commit('dialogosnackbar', 'Comprobante no existe')
                        }
                    })
            } else {
                allCabecera()
                    .orderByChild('fecha')
                    .startAt(moment(String(this.date)) / 1000)
                    .endAt(moment(String(this.date2)).add(23, 'h').add(59, 'm').add(59, 's') / 1000)
                    .once("value").then((snapshot) => {
                        snapshot.forEach((item) => {
                            var data = item.val()
                            if (data.tipocomprobante != 'T') {
                                data.color = this.asigna_color_doc(data),
                                    array.push(data)
                            }
                        })
                        this.desserts = array
                    })
            }
        },
        cambia_doc() {
            if (this.tipo_doc == 'B') {
                this.tipo_doc = 'F'
            } else {
                this.tipo_doc = 'B'
            }
        },
        asigna_color_doc(datas) {
            let data = datas
            var color = ''
            if (data.estado != 'aprobado' || data.estado != 'ACEPTADO' ||
                data.estado != 'PENDIENTE' || data.estado != 'pendiente') {
                color = '#FF0000'
            }
            if (data.estado == 'aprobado' || data.estado == 'ACEPTADO') {
                color = '#46FF00'
            }
            if (data.estado == 'PENDIENTE' || data.estado == 'pendiente') {
                color = '#FFB200'
            }
            return color
        },
        nota_cred() {
            this.data_cabecera_n = this.seleccionado
            this.arrayConsolidar = []
            for (var i = 0; i < this.desserts.length; i++) {
                if (this.desserts[i].numeracion == this.seleccionado.numeracion) {
                    consultaDetalle(this.desserts[i].numeracion).once("value").then((snapshot) => {
                        snapshot.forEach((item) => {
                            this.arrayConsolidar.push(item.val())
                        })
                        this.data_nota_items = this.arrayConsolidar
                        this.dial_nota = true
                    })

                }
            }
        }
    }
}
</script>
