<template>
    <div class="div-1 pa-3">
        <v-card shaped elevation="10" class="card-1 mx-auto mt-10" color="white" width="500px">
            <v-card-title primary-title class="text-center">

            </v-card-title>
            <div>
                <p><span class="black--text">Paciente:</span> {{ this.nom_paciente }}</p>
            </div>
            <v-card-text>
                <v-row dense class="text-center">
                    <v-col cols="12">
                        <v-card @click.prevent="abre_receta()">
                            <v-container>
                                <v-img class="mx-auto" height="100" width="100" src="/favicon.png"></v-img>
                                <h4 block class="text-center pa-1">Visualizar Receta</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-img src="/power-by-cloud.png" max-width="250" max-height="250" class="mx-auto"></v-img>
            </v-card-actions>
        </v-card>
        <receta v-if="dial_receta" :data="data_receta" @cierra="dial_receta = false" />
    </div>
</template>

<script>
import {
    solicitaPermiso,
    ObtenerToken
} from '../../sendMessaging'
import receta from '@/components/dialogos/dialog_receta'
import {
    busca_medicion,
} from '../../db'
import store from '@/store/index'
import {
    pdfGenera
} from '../../pdf_comprobantes'
export default {
    name: "App",
    components: {
        receta,
    },
    data: function () {
        return {
            dial_receta: false,
            id_receta: '',
            nom_paciente: ''
        };
    },
    created() {
        this.id_receta = this.$route.params.id_receta
        solicitaPermiso()
        this.inicia()
    },
    methods: {
        async inicia() {
            store.commit("dialogoprogress", 1);
            console.log(this.id_receta)
            var snap = await busca_medicion(this.id_receta).once('value')
            console.log(snap.val())
            if (snap.exists()) {
                this.data_receta = snap.val()
                this.nom_paciente = this.data_receta.nom_cliente
            } else {
                alert('PROBLEMA INTERNO COMUNIQUESE CON ADMINISTRADOR')
            }
            await new Promise((resolve) => setTimeout(resolve, 1500));
            store.commit("dialogoprogress", 1);
        },
        abre_receta() {
            this.dial_receta = true
        },
        GuardaToken() {

            ObtenerToken().getToken().then((currentToken) => {
                if (currentToken) {
                    console.log('token = ', currentToken);
                } else {
                    console.log('No Instance ID token available. Request permission to generate one.');
                    updateUIForPushPermissionRequired();
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });
        }
    },

};
</script>

<style scoped>
.div-1 {
    background-color: #333030;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    position: relative;
}

.card-1 {
    align-items: center;
}
</style>
