<template>
    <div class="mb-6 pa-4">
        <v-card>
            <v-row>
                <v-col cols="3">
                    <v-select :items="array_tiendas" label="Tienda" dense outlined v-model="tienda"></v-select>
                </v-col>
                <v-col cols="3">
                    <v-text-field class="mx-1" outlined dense v-model="buscar" label="BUSCAR"
                        append-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date" label="Inicio"></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field class="mx-1" outlined dense type="date" v-model="date2" label="Fin"></v-text-field>
                </v-col>
            </v-row>

            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Fecha Pedido
                            </th>
                            <th class="text-left">
                                Producto
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                            <th class="text-left">
                                Estado
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" item  in  listafiltrada " :key="item.id">
                            <td style="font-size:75%;">{{ conviertefecha(item.fecha) }}</td>
                            <td style="font-size:75%;">{{ item.producto.nombre }}</td>
                            <td>
                                <v-icon :color="item.color">mdi-circle</v-icon>
                            </td>
                            <td style="font-size:75%;">{{ item.estado }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card>

    </div>
</template>

<script>
import {
    allpedidos,
} from '../../db'
import {
    pdfGenera
} from '../../pdf_pedidos'
import store from '@/store/index'
import moment from 'moment'

export default {

    data: () => ({
        desserts: [],
        date: moment(String(new Date)).format('YYYY-MM-DD'),
        date2: moment(String(new Date)).format('YYYY-MM-DD'),
        buscar: '',
        array_tiendas: ['TODAS'],
        tienda: 'TODAS',
        item_selecto: [],
    }),
    created() {
        var tiendas = store.state.permisos.tiendas
        for (var i = 0; i < tiendas.length; i++) {
            this.array_tiendas[this.array_tiendas.length] = tiendas[i]
        }
    },
    computed: {
        listafiltrada() {
            var array = this.desserts
            if (this.tienda == 'TODAS') {
                return array.filter((item) => (item.id + item.doc_venta + item.cliente_dni + item.cliente_nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase())).reverse()
            } else {
                array = array.filter((item) => (item.tienda).toLowerCase().includes(this.tienda.toLowerCase()))
                return array.filter((item) => (item.id + item.doc_venta + item.cliente_dni + item.cliente_nombre)
                    .toLowerCase().includes(this.buscar.toLowerCase())).reverse()

            }
        }
    },
    mounted() {
        allpedidos().on("value", this.onDataChange);
    },
    destroyed() {
        allpedidos().off("value");
    },
    methods: {
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                if (data.p_confirmado == undefined) {
                    data.estado = 'EN CAMINO'
                    data.color = '#FF0000'
                }
                if (data.p_confirmado) {
                    data.estado = 'PENDIENTE'
                    data.color = '#FFB200'
                }
                if (data.pedido_entregado) {
                    data.estado = 'BICELADO'
                    data.color = '#46FF00'
                }
                data.f_entrega = moment(this.conviertefecha(data.fecha), 'DD/MM/YYYY hh:mm A').add(data.producto.dias_entrega, 'd') / 1000
                array.push(data);
                console.log(data)
            });

            this.desserts = array;
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },

    }
}
</script>

<style scoped>
@keyframes anim {
    0% {
        background-color: rgb(255, 255, 255);

    }

    50% {
        background-color: rgb(255, 196, 196);
    }

    100% {
        background-color: rgb(255, 255, 255);
    }
}

.id_card_rect {
    animation-name: anim;
    animation-duration: 2s;
    animation-iteration-count: infinite;

}
</style>
