import QR from 'qrcode-base64'
import jspdf from 'jspdf'
import 'jspdf-autotable'
import store from '@/store/index'
import moment from 'moment'
export const pdfGenera = (data) => {

    var qrs = generaQR(data)
    impresion80(qrs)
}

function impresion80(qr) {

    var fechaImpresion = moment(String(new Date)).format('DD/MM/YYYY hh:mm a')
    var linea = parseInt(store.state.configImpresora.msuperior)
    var lMargin = 3.5; //left margin in mm
    var rMargin = 2; //right margin in mm
    var pdfInMM = 75;  // width of A4 in mm
    var pageCenter = pdfInMM / 2;

    const doc = new jspdf({
        orientation: "portrait",
        unit: "mm",
        format: [1000, pdfInMM]
    })
    doc.setTextColor(10);
    doc.text('.', 0, linea)
    linea = linea + 3
    //console.log(imagen)

    doc.setFontSize(8)
    doc.setFont('Helvetica', 'Bold');
    var texto = doc.splitTextToSize('ESCANEAR RECETA', (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center'); //EMPRESA
    linea = linea + (4 * texto.length)

    var texto = doc.splitTextToSize(fechaImpresion, (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center');
    linea = linea + (4 * texto.length)

    doc.addImage(qr, 'png', (pdfInMM / 2) - 25, linea, 50, 50);
    linea = linea + 55

    doc.text('.', 0, linea)

    window.open(doc.output('bloburi'))
    //doc.save(arraycabe.serie + "-" + arraycabe.correlativoDocEmitido + '.pdf')

}
export const generaQR = (data) => {
    var imgData = QR.drawImg(data, {
        typeNumber: 4,
        errorCorrectLevel: 'M',
        size: 500
    })
    return imgData
}