<template>
    <div class="mb-6 pa-4 mt-3">
        <v-card class="pa-3">
            <v-tabs dark background-color="teal darken-3" show-arrows v-model="tab">
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                <v-tab v-for="i in $store.state.array_filtro" :key="i">
                    {{ i }}
                </v-tab>
            </v-tabs>
            <v-simple-table fixed-header height='70vh' dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Laboratorio
                            </th>
                            <th class="text-left">
                                Marca
                            </th>
                            <th class="text-left">
                                Tratamiento
                            </th>
                            <th class="text-left">
                                IR
                            </th>
                            <th class="text-left">
                                Descripcion
                            </th>
                            <th class="text-left">
                                Precio
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in listafiltrada" :key="item.id">
                            <td style="font-size:75%;">{{ item.laboratorio }}</td>
                            <td style="font-size:75%;">{{ item.marca }}</td>
                            <td style="font-size:75%;">{{ item.tratamiento }}</td>
                            <td style="font-size:75%;">{{ item.indice }}</td>
                            <td style="font-size:75%;">
                                <span :style="{ color: item.c_palabra, fontWeight: 'bold' }">
                                    {{ item.descripcion }}
                                </span>

                            </td>
                            <td style="font-size:75%;">{{ item.precio }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-dialog v-model="dialogo_agrega" max-width="700px">
            <div>
                <v-system-bar window dark>
                    <v-icon color="red" large @click="dialogo_agrega = !dialogo_agrega">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-icon color="green" large @click="agregar()">mdi-content-save</v-icon>
                    <v-icon color="red" large @click="dialogo_elimina = !dialogo_elimina">mdi-delete</v-icon>
                </v-system-bar>
            </div>

            <v-card class="pa-4">

                <v-row v-if="false">
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arraytipoProducto" label="Tipo" dense outlined
                            v-model="tipoproducto"></v-select>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                        <v-select disabled :items="arrayOperacion" label="Operacion" dense outlined
                            v-model="operacion"></v-select>
                    </v-col>
                </v-row>

                <v-row class="mt-n3">
                    <v-col cols="6">
                        <v-text-field dense disabled v-model="id" label="ID" outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="$store.state.tipo_focal" label="Tipo" dense outlined
                            v-model="tipo_focal"></v-select>

                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-text-field readonly @click:append="activa_productos = true" append-icon="mdi-magnify"
                            outlined dense type="text" v-model="marca" label="Marca"> </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="$store.state.tratamiento" label="Tratamiento" dense outlined
                            v-model="tratamiento"></v-select>
                    </v-col>

                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="6">
                        <v-select :items="$store.state.tipo_material" label="Material" dense outlined
                            v-model="tipo_material"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense v-model="laboratorio" label="Laboratorio"></v-text-field>
                    </v-col>

                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="descripcion" auto-grow filled label="Descripcion"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col cols="12">
                        <v-textarea dense outlined v-model="descripcion_corta" auto-grow filled label="Nombre Corto"
                            rows="1"></v-textarea>
                    </v-col>
                </v-row>
                <v-row class="mt-n8 mb-n6">
                    <v-col cols="4">
                        <v-text-field class="mt-1" outlined dense type="number" v-model="indice"
                            label="Indice Refraccion"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="pa-1" @click="modo_color = 'palabra', activa_colores = true" :color="c_palabra">
                            <P class="text-center">C.Palabra</P>
                        </v-card>
                    </v-col>
                    <v-col cols="4">
                        <v-card class="pa-1" :color="c_icono" @click="modo_color = 'icono', activa_colores = true">
                            <P class="text-center">C.Icono</P>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-n1">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="stock" label="Stock"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="costo" label="costo"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="precio" label="Precio venta"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field outlined dense type="number" v-model="comision" label="Comision"></v-text-field>
                    </v-col>
                </v-row>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dialogo_elimina" max-width="300">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dialogo_elimina = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <h4 class="text-center">Esta seguro de Eliminar?</h4>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn block @click="eliminar()" color="orange">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <t_productos :filtro="tipo_focal" :dial_L="activa_productos" :edicion="false" @cierra="activa_productos = false"
            @array="selec_producto($event)" />

        <t_colores :dial="activa_colores" :edicion="true" @cierra="activa_colores = false"
            @array="selec_color($event)" />
    </div>
</template>

<script>
import t_productos from '@/components/configEmpresa/tabla_productos'
import t_colores from '@/components/configEmpresa/tabla_colores'
import {
    allProductos,
    nuevoProductos,
    eliminaProductos,
    busca_tabla
} from '../../db'
import {
    obten_contador_externo,
    sumarCorrelativo_externo
} from '../../contadores'
import store from '@/store/index'
export default {
    name: 'caja',
    components: {
        t_productos,
        t_colores
    },
    data() {
        return {
            headers: [
                {
                    text: 'Laboratorio',
                    value: 'laboratorio',
                },
                {
                    text: 'Marca',
                    value: 'marca',
                },
                {
                    text: 'Tratamiento',
                    value: 'tratamiento',
                },
                {
                    text: 'IR',
                    value: 'indice',
                },
                {
                    text: 'Descripcion',
                    value: 'descripcion',
                },
                {
                    text: 'Precio',
                    value: 'precio',
                },
            ],
            activa_productos: false,
            dialogo_agrega: false,
            dialogo_elimina: false,
            array_tabla: [],
            arraytipoProducto: ['BIEN', 'SERVICIO'],
            tipoproducto: "BIEN",
            arrayOperacion: [
                'GRAVADA',
                'EXONERADA',
                'INAFECTA'
            ],
            operacion: "GRAVADA",
            edit: false,
            id: '',
            tratamiento: 'ANTIREFLEJO',
            tipo_focal: 'MULTIFOCAL',
            tipo_material: 'RESINA',
            indice: 0,
            marca: '',
            descripcion: '',
            descripcion_corta: '',
            costo: '',
            stock: '',
            comision: '',
            precio: '',
            buscar: '',
            activa_colores: false,
            c_palabra: '',
            c_icono: '',
            modo_color: 'palabra',
            laboratorio: '',
            tab: '',
            array_marcas: []
        }
    },
    async created() {
        var snapshot = await busca_tabla('productos').once("value")
        let array = [];
        snapshot.forEach((item) => {
            let data = item.val();
            if (data.activo) {
                array.push(data);
            }
        });
        var ss = []
        var tt = store.state.productos.filter(item => item.activo)
        for (var i = 0; i < tt.length; i++) {
            var r = tt[i]
            var a = array.find(i => i.nombre == r.marca)
            if (a != undefined) {
                ss.push(r)
            }
        }
        this.array_tabla = ss
    },
    computed: {
        listafiltrada() {
            //this.array_tabla = store.state.productos
            if (store.state.array_filtro[this.tab] == 'TODOS') {
                return this.array_tabla
            } else {
                return this.array_tabla.filter(item => item.filtro == store.state.array_filtro[this.tab])
            }

        }
    },

    methods: {
        selec_color(data) {
            console.log(data)
            if (this.modo_color == 'palabra') {
                this.c_palabra = data.codigo
            } else {
                this.c_icono = data.codigo
            }
            this.activa_colores = false
        },
        selec_producto(data) {
            this.marca = data.nombre
            this.activa_productos = false
        },
        async nuevo() {
            this.edit = false
            var a = await obten_contador_externo("orden_productos")
            this.id = "P" + a
            this.marca = ''
            this.descripcion = ''
            this.descripcion_corta = ''
            this.indice = 0
            this.stock = 1
            this.costo = 0
            this.precio = 0
            this.comision = 0
            this.c_palabra = ''
            this.c_icono = '#FFFFFF'
            this.laboratorio = ''
            this.dialogo_agrega = true
        },
        async agregar() {
            if (!this.edit) {
                var a = await obten_contador_externo("orden_productos")
                this.id = "P" + a
            }
            store.commit("dialogoprogress", 1)
            var array = {
                tipoproducto: this.tipoproducto,
                operacion: this.operacion,
                id: this.id,
                tipo_focal: this.tipo_focal,
                marca: this.marca,
                tratamiento: this.tratamiento,
                tipo_material: this.tipo_material,
                indice: this.indice,
                descripcion: this.descripcion,
                descripcion_corta: this.descripcion_corta,
                costo: this.costo,
                precio: this.precio,
                stock: this.stock,
                comision: this.comision,
                c_palabra: this.c_palabra,
                c_icono: this.c_icono,
                laboratorio: this.laboratorio
            }
            await nuevoProductos(this.id, array)
            store.commit("dialogoprogress", 1)
            if (!this.edit) {
                await sumarCorrelativo_externo("orden_productos", a)
                this.marca = ''
                this.descripcion = ''
                this.descripcion_corta = ''
                this.indice = 0
                this.stock = 1
                this.costo = 0
                this.precio = 0
                this.comision = 0
                this.c_palabra = '#FFFFFF'
                this.c_icono = '#FFFFFF'
                this.laboratorio = ''
                this.dialogo_agrega = false
            } else {
                this.dialogo_agrega = false
            }
        },
        editar(data) {
            console.log(data)
            this.edit = true
            this.id = data.id
            this.tratamiento = data.tratamiento
            this.tipo_focal = data.tipo_focal
            this.marca = data.marca
            this.tipo_material = data.tipo_material
            this.indice = data.indice
            this.descripcion = data.descripcion
            this.descripcion_corta = data.descripcion_corta
            this.stock = data.stock
            this.costo = data.costo
            this.precio = data.precio
            this.comision = data.comision
            this.c_palabra = data.c_palabra
            this.c_icono = data.c_icono
            this.laboratorio = data.laboratorio
            this.dialogo_agrega = true
        },
        eliminar() {
            eliminaProductos(this.id)
            this.dialogo_elimina = false
            this.dialogo_agrega = false
        },
        cierra() {
            this.$emit('cierra', false)
        }
    },

}
</script>
