<template>
    <div class="pa-4 mb-5">
        <v-row class="mb-4 mt-3">

            <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="abre_personal()">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/empleados.png"></v-img>
                        <h4 block class="text-center pa-1">Personal</h4>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                <v-card @click.prevent="dial_periodos = true">
                    <v-container>
                        <v-img class="mx-auto" height="70" width="70" src="/repor.png"></v-img>
                        <h4 block class="text-center pa-1">Reportes</h4>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dial_periodos" max-width="450px">
            <div>
                <v-system-bar window dark>
                    <v-icon @click="dial_periodos = !dial_periodos">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card class="pa-3">
                <v-select :items="$store.state.array_periodos" label="PERIODO" outlined v-model="periodo"
                    dense></v-select>
                <v-row class="mb-4 mt-3">

                    <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                        <v-card @click.prevent="abre_marca()">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/huella.png"></v-img>
                                <h4 block class="text-center pa-1">Marcaciones</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pa-1 mx-auto " md="4" sm="4" xs="6">
                        <v-card @click.prevent="abre_total()">
                            <v-container>
                                <v-img class="mx-auto" height="70" width="70" src="/repor.png"></v-img>
                                <h4 block class="text-center pa-1">Reporte Total</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>

        <lista_personal v-if="dial_personal" @cierra="dial_personal = false" />
        <marc v-if="marcas" @cerrar="marcas = false" :periodo="periodo">
        </marc>
    </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store/index'
import moment from 'moment'
import lista_personal from "./lista_personal.vue";
import marc from "./rep_marca.vue";
import {
    all_grupo,
} from '../../db'
export default {
    name: 'panel',
    components: {
        lista_personal,
        marc
    },
    data() {
        return {
            periodo: moment(String(new Date)).format('MM-YYYY'),
            dial_personal: false,
            dial_periodos: false,
            marcas: false
        }
    },
    created() {
        this.iniciar()
    },
    beforeDestroy() {
        all_grupo().off('value')
    },

    methods: {
        iniciar() {
            all_grupo().on('value', (snapshot) => {
                let array = [];
                if (snapshot.exists()) {
                    snapshot.forEach((item) => {
                        let data = item.val();
                        let key = item.key;
                        data.id = key
                        array.push(data);
                    });
                    store.commit('grupos', array)
                }
            })
        },
        abre_personal() {
            this.dial_personal = true
        },
        abre_marca() {
            this.marcas = true
        },
        abre_total() {

        },
    },

}
</script>
