<template>
    <v-dialog v-model="dial" max-width="480px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <v-icon large color="green" @click="graba()">mdi-content-save</v-icon>
            </v-system-bar>
        </div>

        <v-card class="pa-5">
            <div>

                <v-row dense>
                    <v-col cols="6">
                        <v-checkbox dense v-model="inventario" label="Control Inventario"></v-checkbox>
                    </v-col>

                    <v-col cols="6">
                        <v-checkbox dense v-model="sunat" label="Envio Directo Sunat"></v-checkbox>
                    </v-col>
                    <v-col dense cols="6">
                        <v-checkbox dense v-model="flujocaja" label="Flujo caja"></v-checkbox>
                    </v-col>
                    <v-col dense cols="6">
                        <v-checkbox dense v-model="calvuelto" label="Calcula vuelto"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="codbarras" label="Codigo Barras"></v-checkbox>
                    </v-col>

                    <v-col dense cols="6">
                        <v-checkbox dense v-model="pmayorista" label="Descuentos"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="dataclientes" label="Busca Tab. Clientes"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="puntosclientes" label="Puntos a Clientes"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="ordencategorias" label="X Categorias"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="iconocategorias" label="icon Categorias"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="proformas" label="PROFORMAS"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="creditofactura" label="Ventas al Credito"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="obscomprobante" label="Obs comprobantes"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="comisiones" label="Comisiones Empleado"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="persistencia_catalogo" label="Persistencia catalogo"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-checkbox dense v-model="muestra_ubicaciones" label="Muestra Ubicacion"></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense v-model="decimal" label="Decimales"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense v-model="igv" label="IGV" suffix="%"></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field dense v-model="icbper" label="ICBPER"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-select dense v-model="pordefecto" :items="arraydoc" menu-props="auto" hide-details
                            label="Comprobante Defecto"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field dense type="number" v-model="conversion_puntos"
                            label="conversion puntos"></v-text-field>
                    </v-col>
                </v-row>
            </div>

        </v-card>

    </v-dialog>
</template>

<script>
import {
    allConfigura,
    grabaConfigura
} from '../../db'
import store from '@/store/index'

export default {
    data() {
        return {
            dial: false,
            inventario: false,
            sunat: false,
            codbarras: false,
            flujocaja: false,
            pmayorista: false,
            dataclientes: false,
            puntosclientes: false,
            controlplatos: false,
            ordencategorias: false,
            iconocategorias: false,
            proformas: false,
            calvuelto: false,
            creditofactura: false,
            obscomprobante: false,
            comisiones: false,
            persistencia_catalogo: false,
            muestra_ubicaciones: false,
            conversion_puntos: 0,
            decimal: 2,
            igv: '18',
            icbper: '0.30',
            pordefecto: 'T',
            arraydoc: ['T', 'B', 'F']
        }
    },
    created() {
        this.dial = true
        this.inicializar()
    },
    methods: {
        inicializar() {
            allConfigura().once("value").then((snapshot) => {
                if (snapshot.val() != null) {
                    this.sunat = snapshot.val().sunat
                    this.inventario = snapshot.val().inventario
                    this.decimal = snapshot.val().decimal
                    this.codbarras = snapshot.val().codbarras
                    this.igv = snapshot.val().igv
                    this.icbper = snapshot.val().icbper
                    this.flujocaja = snapshot.val().flujocaja
                    this.pordefecto = snapshot.val().defecto
                    this.pmayorista = snapshot.val().pmayorista
                    this.dataclientes = snapshot.val().dataclientes
                    this.puntosclientes = snapshot.val().puntosclientes
                    this.controlplatos = snapshot.val().controlplatos
                    this.ordencategorias = snapshot.val().ordencategorias
                    this.iconocategorias = snapshot.val().iconocategorias
                    this.calvuelto = snapshot.val().calvuelto
                    this.proformas = snapshot.val().proformas
                    this.creditofactura = snapshot.val().creditofactura
                    this.obscomprobante = snapshot.val().obscomprobante
                    this.comisiones = snapshot.val().comisiones
                    this.persistencia_catalogo = snapshot.val().persistencia_catalogo
                    this.conversion_puntos = snapshot.val().conversion_puntos
                    this.muestra_ubicaciones = snapshot.val().muestra_ubicaciones
                }

            })
        },
        graba() {
            grabaConfigura("inventario", this.inventario)
            grabaConfigura("sunat", this.sunat)
            grabaConfigura("decimal", this.decimal)
            grabaConfigura("codbarras", this.codbarras)
            grabaConfigura("igv", this.igv)
            grabaConfigura("icbper", this.icbper)
            grabaConfigura("flujocaja", this.flujocaja)
            grabaConfigura("defecto", this.pordefecto)
            grabaConfigura("pmayorista", this.pmayorista)
            grabaConfigura("dataclientes", this.dataclientes)
            grabaConfigura("puntosclientes", this.puntosclientes)
            grabaConfigura("controlplatos", this.controlplatos)
            grabaConfigura("ordencategorias", this.ordencategorias)
            grabaConfigura("iconocategorias", this.iconocategorias)
            grabaConfigura("calvuelto", this.calvuelto)
            grabaConfigura("proformas", this.proformas)
            grabaConfigura("creditofactura", this.creditofactura)
            grabaConfigura("obscomprobante", this.obscomprobante)
            grabaConfigura("comisiones", this.comisiones)
            grabaConfigura("persistencia_catalogo", this.persistencia_catalogo)
            grabaConfigura("conversion_puntos", this.conversion_puntos)
            if (!Boolean(this.muestra_ubicaciones)) {
                this.muestra_ubicaciones = false
            }
            grabaConfigura("muestra_ubicaciones", this.muestra_ubicaciones)
            this.cierra()
        },
        cierra() {
            this.$emit('cierra', false)
        }
    }
}
</script>
