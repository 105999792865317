<template>
    <div class="pa-4">
        <v-card>
            <div class="d-flex align-content-start flex-wrap mb-n6">
                <v-row class="mt-n3 mb-1 pa-2">

                    <v-col cols="4">
                        <h5>Envio Monturas</h5>
                        <v-btn color="success" class="" block small @click="dial_crea = true">Nuevo
                            Envio</v-btn>
                    </v-col>
                    <v-col cols="4">

                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date1" label="Desde"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field type="date" outlined dense v-model="date2" label="Desde"></v-text-field>
                    </v-col>
                </v-row>
            </div>

            <v-card>
                <v-simple-table dense fixed-header height="70vh">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Fecha
                                </th>
                                <th class="text-left">
                                    Modo
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Observacion
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in listafiltrada" :key="item.id">
                                <td style="font-size:85%;">{{ conviertefecha(item.fecha) }}</td>
                                <td style="font-size:85%;">{{ item.origen }} --> {{ item.destino }}</td>
                                <td style="font-size:85%;">{{ item.cantidad }}</td>
                                <td style="font-size:85%;">{{ item.observacion }}</td>
                                <td width="100">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-icon color="green" @click.prevent="abre_edita(item)">mdi-pencil</v-icon>
                                        </v-col>
                                    </v-row>
                                </td>
                            </tr>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>

        </v-card>

        <v-dialog v-model="dial" max-width="850px" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large @click="dial = false" medium color="red">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-icon v-if="false" color="info" large @click="abre_monturas()">mdi-eye</v-icon>
                </v-system-bar>
            </div>
            <v-card class="pa-3">

                <v-row class="mt-n2" dense>
                    <v-col cols="6">
                        <v-select disabled :items="array_origen" label="ORIGEN" dense outlined v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select disabled :items="$store.state.tiendas" label="DESTINO" dense outlined
                            v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-row class="mt-n6 mb-2" dense>
                    <v-col cols="12">
                        <v-btn block elevation="10" x-small rounded color="info" @click="dial_accesorios = true">
                            Buscar Accesorios
                        </v-btn>
                    </v-col>
                </v-row>

                <v-simple-table dark dense fixed-header height="52vh">
                    <template v-slot:default>

                        <thead>
                            <tr>
                                <th class="text-left">
                                    Descripcion
                                </th>
                                <th class="text-left">
                                    Cantidad
                                </th>
                                <th class="text-left">
                                    Accion
                                </th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr v-for="item in lista_accesorios" :key="item.uuid">
                                <td>{{ item.descripcion }}</td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <v-icon color="red" @click="elimina(item)">mdi-delete</v-icon>
                                </td>
                            </tr>
                        </tbody>

                    </template>

                </v-simple-table>

            </v-card>

        </v-dialog>
        <v-dialog v-model="dial_crea" max-width="450px">
            <v-card class="pa-6 mx-auto text-center">
                <v-row dense>
                    <v-col cols="6">
                        <v-select :items="array_origen" disabled label="ORIGEN" dense outlined v-model="origen"></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select :items="$store.state.tiendas" label="DESTINO" dense outlined v-model="destino"></v-select>
                    </v-col>
                </v-row>
                <v-textarea style="font-size:85%;" outlined dense v-model="obs" auto-grow filled label="Comentario"
                    rows="1"></v-textarea>
                <v-btn :disabled="destino == ''" color="green darken-1" block dark @click="abre_nuevo()">
                    Graba
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dial_accesorios" max-width="750" persistent>
            <div>
                <v-system-bar window dark>
                    <v-icon large color="red" @click="dial_accesorios = false">mdi-close</v-icon>
                    <v-spacer></v-spacer>
                    <h5>LISTA DE ACCESORIOS</h5>
                    <v-spacer></v-spacer>
                </v-system-bar>
            </div>
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="buscar" append-icon="mdi-magnify" label="Buscar" single-line hide-details
                        dense></v-text-field>
                </v-card-title>
                <v-data-table class="elevation-1 mt-1" fixed-eadher height='70vh' :headers="headers" :items="lista_acces"
                    :items-per-page="15" hide-default-footer mobile-breakpoint="1" dense>
                    <template v-slot:item="{ item }">
                        <tr @click="agrega_accesorio(item)">
                            <td style="font-size:75%;">{{ item.id }}</td>
                            <td style="font-size:75%;">{{ item.descripcion }}</td>
                            <td style="font-size:75%;">{{ item.stock }}</td>
                            <td style="font-size:75%;">S/.{{ item.precio }}</td>
                        </tr>

                    </template>
                </v-data-table>

            </v-card>
            <v-dialog v-model="dialo_cantidad" max-width="350px">
                <v-card class="pa-3">
                    <h5 class="mb-2">{{ item_selecto.descripcion }}</h5>

                    <v-row class="mt-2 mb-6 text-center" dense>
                        <v-col cols="12">
                            <v-text-field autofocus type="number" outlined dense v-model="cantidad" label="CANTIDAD"
                                @keyup.enter="agrega_()"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-n2 mb-6 text-center" dense>
                        <v-col cols="12">
                            <v-btn :disabled="cantidad == ''" block elevation="10" small rounded color="info"
                                @click="agrega_()">
                                Agrega
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
        </v-dialog>
    </div>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    allAccesorios,
    editaAccesorios,
    nueva_envios_a,
    all_envios_a,
    edita_envios_a,
    busca_Accesorios_t_bd,
    nuevoAccesorios_t_bd
} from '../../db'
export default {

    data() {
        return {
            headers: [{
                text: 'id',
                align: 'start',
                sortable: false,
                value: 'id',
            },
            {
                text: 'Marca',
                value: 'marca',
            },
            {
                text: 'Stock',
                value: 'stock',
            },
            {
                text: 'Precio',
                value: 'precio',
            },
            ],
            dial_accesorios: false,
            dialo_cantidad: false,
            cantidad: '',
            dial_crea: false,
            array_accesorios: [],
            dial: false,
            date1: moment(String(new Date)).format('YYYY-MM-DD'),
            date2: moment(String(new Date)).format('YYYY-MM-DD'),
            desserts: [],
            lista_accesorios: [],
            codigo: '',
            origen: 'almacen',
            array_origen: ['almacen'],
            destino: '',
            obs: '',
            item_selecto: [],
            accesorio_selecto: [],
            buscar: ''
        }
    },
    created() {
        all_envios_a().on("value", this.onDataChange);

        var dia = moment(String(new Date)).format('DD')
        this.date1 = moment().subtract(parseFloat(dia) - 1, 'd').format('YYYY-MM-DD')
        this.date2 = moment(String(new Date)).format('YYYY-MM-DD')
    },
    mounted() {
        all_envios_a().on("value", this.onDataChange);
        allAccesorios().on("value", this.onDataChange_);
    },
    destroyed() {
        all_envios_a().off("value", this.onDataChange);
        allAccesorios().on("value", this.onDataChange_);
    },
    computed: {
        listafiltrada() {
            all_envios_a().on("value", this.onDataChange);
            return this.desserts
        },
        lista_acces() {
            allAccesorios().on("value", this.onDataChange_);
            return this.array_accesorios
        }
    },
    methods: {
        async agrega_() {
            var value = this.accesorio_selecto
            if (this.lista_accesorios.find(id => id.id == value.id)) {
                alert('PRODUCTO YA AGREGADO')
            } else {
                value.cantidad = this.cantidad
                var bd_destino = store.state.array_tiendas.find(item => item.nom_tienda == this.destino).bd
                var snap = await busca_Accesorios_t_bd(bd_destino, value.id).once('value')
                if (snap.exists()) {
                    var dats = snap.val()
                    var nuevo_stock = parseFloat(dats.stock) + parseFloat(this.cantidad)
                    await nuevoAccesorios_t_bd(bd_destino, dats.id + '/stock', nuevo_stock)
                } else {
                    var array = {
                        tipoproducto: value.tipoproducto,
                        operacion: value.operacion,
                        id: value.id,
                        descripcion: value.descripcion,
                        costo: value.costo,
                        precio_min: value.precio_min,
                        stock: value.cantidad,
                        precio: value.precio,
                    }
                    await nuevoAccesorios_t_bd(bd_destino, array.id, array)
                }
                var stock = parseFloat(value.stock) - parseFloat(this.cantidad)
                await editaAccesorios(value.id + '/stock', stock)
                this.lista_accesorios.push(value)
                edita_envios_a(this.item_selecto.id + '/data', this.lista_accesorios)
                this.dial_accesorios = false
                this.cantidad = ''
            }
        },

        agrega_accesorio(data) {
            this.accesorio_selecto = data
            this.dialo_cantidad = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('DD/MM/YYYY hh:mm A')
        },
        onDataChange(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.desserts = array;
        },
        onDataChange_(items) {
            let array = [];
            items.forEach((item) => {
                let data = item.val();
                array.push(data);
            });
            this.array_accesorios = array;
        },
        async abre_nuevo() {
            store.commit("dialogoprogress", 1)
            var fecha = moment().unix()
            var array = {
                id: fecha,
                fecha: fecha,
                origen: this.origen,
                destino: this.destino,
                data: [],
                observacion: this.obs,
                estado: 'PENDIENTE'
            }
            this.item_selecto = array
            await nueva_envios_a(array.id, array)
            store.commit("dialogoprogress", 1)
            this.dial_crea = false
            this.dial = true
        },
        async abre_edita(data) {
            this.item_selecto = data
            this.origen = data.origen
            this.destino = data.destino
            if (data.data != undefined) {
                this.lista_accesorios = data.data
            }

            this.dial = true
        },
        buscaProducto() {
            if (this.destino == '') {
                alert('SELECCIONE DESTINO VALIDO')
                return
            }
            if (this.lista_accesorios.find(id => id.id == this.codigo)) {
                alert('PRODUCTO YA ESCANEADO')
            } else {
                var a = this.array_accesorios.find(id => id.id == this.codigo)
                if (a == undefined) {
                    alert('PRODUCTO NO EXISTE EN ORIGEN')
                    return
                }
                a.estado = 'PENDIENTE'
                this.lista_accesorios.push(a)
                editaAccesorios(this.codigo + '/ubicacion', this.destino)
                edita_envios_a(this.item_selecto.id + '/data', this.lista_accesorios)
                edita_envios_a(this.item_selecto.id + '/cantidad', this.lista_accesorios.length)
            }

        },
        async elimina(item) {
            console.log(item)
            var bd_destino = store.state.array_tiendas.find(item => item.nom_tienda == this.destino).bd
            var snap = await busca_Accesorios_t_bd(bd_destino, item.id).once('value')
            if (snap.exists()) {
                var dats = snap.val()
                var nuevo_stock = parseFloat(dats.stock) - parseFloat(item.cantidad)
                await nuevoAccesorios_t_bd(bd_destino, dats.id + '/stock', nuevo_stock)
            } else {

                alert('comuniquese con administrador')
            }
            var value = this.lista_acces.find(e => e.id == item.id)
            var stock = parseFloat(value.stock) + parseFloat(item.cantidad)
            await editaAccesorios(value.id + '/stock', stock)
            var i = this.lista_accesorios.map(e => e.id).indexOf(item.id)
            this.lista_accesorios.splice(i, 1)
            edita_envios_a(this.item_selecto.id + '/data', this.lista_accesorios)
        }
    },

}
</script>
