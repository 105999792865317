import jspdf from 'jspdf'
import 'jspdf-autotable'
import store from '@/store/index'
import { grabaCabeceraProforma, grabaDetalleProforma, consultaArchivo } from '@/db'
import moment from 'moment'
import 'jspdf-autotable'
import imageToBase64 from 'image-to-base64/browser'
import QR from 'qrcode-base64'
import numeros_a_letras from 'numeros_a_letras'

export const generaproforma = (array, formato) => {

  switch (formato) {
    case 'A4':
      impresionA4(array)
      break;
    case '80':
      impresion80(array)
      break;
    case '58':
      impresion58(array)
      break;
  }

}
function impresion58(arrays) {
  var linea = parseInt(store.state.configImpresora.msuperior)
  var Ruc = 'Ruc: ' + store.state.baseDatos.ruc
  var Direccion = store.state.baseDatos.direccion + '-' + store.state.baseDatos.distrito + '-' + store.state.baseDatos.provincia + '-' + store.state.baseDatos.departamento
  var imagen = store.state.logoempresa
  var separacion = "-------------------------------------------------------------------------------------------------------------------"
  var fecha_emision = moment.unix(arrays.fecha).format('DD/MM/YYYY')
  var fecha_vencimiento = moment.unix(arrays.date_vence).format('DD/MM/YYYY')
  var array = arrays.data
  var total = arrays.total

  var guardadocumento = store.state.configImpresora.guardadocumento
  var lMargin = 2; //left margin in mm
  var rMargin = 1; //right margin in mm
  var pdfInMM = 55;  // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var piepagina = store.state.configImpresora.piepagina;
  var telefono = store.state.configImpresora.telefono;
  var pageCenter = pdfInMM / 2;
  var serie = 'P001'

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM]
  })
  doc.setTextColor(10);
  doc.text('.', 0, linea)
  linea = linea + 3
  //console.log(imagen)
  if (imagen != '') {
    doc.addImage("data:image/png;base64," + imagen, 'png', (pdfInMM / 2) - 15, linea, 30, 30);
    linea = linea + parseInt(store.state.configImpresora.minferior) - 10 /// modificar margenes de logo
  }
  doc.setFontSize(7)
  doc.setFont('Helvetica', 'Bold');
  var texto = doc.splitTextToSize(store.state.baseDatos.name, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center'); //EMPRESA

  linea = linea + (4 * texto.length)

  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize(Ruc + '\n' + Direccion
    , (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center'); //RUC

  linea = linea + (3 * texto.length)

  if (cabecera != '') {
    linea = linea + 2
    var texto = doc.splitTextToSize(cabecera, (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center'); //cabecera
    linea = linea + (3.5 * texto.length)
  }
  if (telefono != '') {
    var texto = doc.splitTextToSize("Telf: " + telefono, (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center'); //cabecera
    linea = linea + (3.5 * texto.length)
  }

  doc.setFont('Helvetica', 'bold');
  doc.setFontSize(7)
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3
  var texto = doc.splitTextToSize('PROFORMA', (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + 3
  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize(serie, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + 5

  var texto = doc.splitTextToSize("EMISION: " + fecha_emision, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + 5

  /*--------------datos cliente--------------------*/

  var texto = doc.splitTextToSize('Nombre   : ' + arrays.nom_cliente, (pdfInMM - lMargin - rMargin));
  doc.text(texto, lMargin, linea, 'left');
  linea = linea + (3.5 * texto.length)

  var texto = doc.splitTextToSize('Documento: ' + arrays.num_cliente, (pdfInMM - lMargin - rMargin));
  doc.text(texto, lMargin, linea, 'left');
  linea = linea + (3.5 * texto.length)

  if (arrays.dir_cliente != "") {
    var texto = doc.splitTextToSize('Direccion: ' + arrays.dir_cliente, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3 * texto.length)
  }

  if (arrays.observacion != "") {
    var texto = doc.splitTextToSize('Observacion: ' + arrays.observacion, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3 * texto.length)
  }

  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 7
  doc.text(separacion, pageCenter, linea, 'center');

  //-----------------productos-----------------------
  var operacionexonerada = 0
  var operaciongravada = 0
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    //console.log(array[i])
    var descuento = parseFloat(array[i].preciodescuento)
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = array[i].nombre + '\n' + '- S/.' + array[i].precioedita + ' X ' + array[i].medida;
    nuevoArray[i][2] = parseFloat((array[i].precioedita) * array[i].cantidad).toFixed(store.state.configuracion.decimal);
    if (array[i].operacion == 'EXONERADA') {
      operacionexonerada = parseFloat(operacionexonerada) + parseFloat((array[i].precioedita) * array[i].cantidad) - descuento
    }
    if (array[i].operacion == 'GRAVADA') {
      operaciongravada = parseFloat(operaciongravada) + parseFloat((array[i].precioedita) * array[i].cantidad) - descuento
    }
  }


  doc.autoTable({
    margin: { top: linea - 9, left: 0 },
    styles: { fontSize: 7, cellPadding: 0.1, valign: 'middle', halign: 'center' },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 8, halign: 'center' },
      1: { columnWidth: 30, halign: 'left' },
      2: { columnWidth: 11, halign: 'right' },
    },
    theme: ['plain'],
    head: [['Cant', 'Descripcion', 'P.T']],
    body: nuevoArray
  })


  let finalY = doc.previousAutoTable.finalY
  linea = finalY + 2

  //-------------------------------------------------------
  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3

  doc.setFont('Helvetica', '');
  doc.setFontSize(7)


  doc.text("OP. GRAVADA", lMargin, linea)
  doc.text("S./" + (parseFloat(operaciongravada) / 1.18).toFixed(2).toString(), 50, linea, 'right')
  linea = linea + 3.5

  doc.text("OP. EXONERADA", lMargin, linea)
  doc.text("S./" + (operacionexonerada).toFixed(2).toString().toString(), 50, linea, 'right')
  linea = linea + 3.5

  doc.text("IGV 18%", lMargin, linea)
  doc.text("S./" + ((parseFloat(operaciongravada) / 1.18) * 0.18).toFixed(2).toString(), 50, linea, 'right')
  linea = linea + 3.5


  doc.text("Total", lMargin, linea)
  doc.text("S./" + total, 50, linea, 'right')
  linea = linea + 3.5

  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3
  doc.setFont('Helvetica', '');
  doc.setFontSize(7)


  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral)
  // console.log("aqui"+store.state.configImpresora.minferiorgeneral)
  doc.text('.', 0, linea)
  if (guardadocumento) {
    doc.save(ordendocumento + '.pdf')
  } else {
    window.open(doc.output('bloburl'))
  }

}
function impresion80(arrays) {
  var linea = parseInt(store.state.configImpresora.msuperior)
  var Ruc = 'Ruc: ' + store.state.baseDatos.ruc
  var Direccion = store.state.baseDatos.direccion + '-' + store.state.baseDatos.distrito + '-' + store.state.baseDatos.provincia + '-' + store.state.baseDatos.departamento
  var imagen = store.state.logoempresa
  var separacion = "-------------------------------------------------------------------------------------------------------------------"
  var fecha_emision = moment.unix(arrays.fecha).format('DD/MM/YYYY')
  var fecha_vencimiento = moment.unix(arrays.date_vence).format('DD/MM/YYYY')
  var array = arrays.data
  var total = arrays.total

  var guardadocumento = store.state.configImpresora.guardadocumento
  var lMargin = 3.5; //left margin in mm
  var rMargin = 2; //right margin in mm
  var pdfInMM = 75;  // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var piepagina = store.state.configImpresora.piepagina;
  var telefono = store.state.configImpresora.telefono;
  var pageCenter = pdfInMM / 2;
  var serie = 'P001'

  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [1000, pdfInMM]
  })
  doc.setTextColor(10);
  doc.text('.', 0, -5)
  linea = linea
  //console.log(imagen)
  if (imagen != '') {
    doc.addImage("data:image/png;base64," + imagen, 'png', (pdfInMM / 2) - 20, linea, 40, 40);
    linea = linea + parseInt(store.state.configImpresora.minferior) /// modificar margenes de logo
  }
  doc.setFontSize(8)
  doc.setFont('Helvetica', 'Bold');
  var texto = doc.splitTextToSize(store.state.baseDatos.name, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center'); //EMPRESA

  linea = linea + (4 * texto.length)

  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize(Ruc + '\n' + Direccion
    , (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center'); //RUC

  linea = linea + (3 * texto.length)

  if (cabecera != '') {
    linea = linea + 2
    var texto = doc.splitTextToSize(cabecera, (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center'); //cabecera
    linea = linea + (3.5 * texto.length)
  }
  if (telefono != '') {
    var texto = doc.splitTextToSize("Telf: " + telefono, (pdfInMM - lMargin - rMargin));
    doc.text(texto, pageCenter, linea, 'center'); //cabecera
    linea = linea + (3.5 * texto.length)
  }

  doc.setFont('Helvetica', 'bold');

  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3
  var texto = doc.splitTextToSize('PROFORMA', (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + 3
  doc.setFont('Helvetica', '');

  var texto = doc.splitTextToSize("EMISION: " + fecha_emision, (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');
  linea = linea + 5

  /*--------------datos cliente--------------------*/
  var texto = doc.splitTextToSize('N° Doc  : ' + arrays.cliente_dni, (pdfInMM - lMargin - rMargin));
  doc.text(texto, lMargin, linea, 'left');
  linea = linea + (3.5 * texto.length)

  var texto = doc.splitTextToSize('Cliente  : ' + arrays.cliente_nombre, (pdfInMM - lMargin - rMargin));
  doc.text(texto, lMargin, linea, 'left');
  linea = linea + (3.5 * texto.length)

  var texto = doc.splitTextToSize('Vence  : ' + fecha_vencimiento, (pdfInMM - lMargin - rMargin));
  doc.text(texto, lMargin, linea, 'left');
  linea = linea + (3.5 * texto.length)

  if (arrays.observacion != "") {
    var texto = doc.splitTextToSize('Observacion: ' + arrays.observacion, (pdfInMM - lMargin - rMargin));
    doc.text(texto, lMargin, linea, 'left');
    linea = linea + (3 * texto.length)
  }

  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 7
  doc.text(separacion, pageCenter, linea, 'center');

  //-----------------productos-----------------------

  var operaciongravada = 0
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    if (array[i].tipo == 'P') {
      var nombre = array[i].tipo_focal
    }
    if (array[i].tipo == 'M') {
      var nombre = 'MONTURA'
    }
    nuevoArray[i] = new Array(3);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = nombre;
    nuevoArray[i][2] = parseFloat((array[i].precio) * array[i].cantidad).toFixed(store.state.configuracion.decimal);

    operaciongravada = parseFloat(operaciongravada) + parseFloat((array[i].precio) * array[i].cantidad)

  }


  doc.autoTable({
    margin: { top: linea - 9, left: 1 },
    styles: { fontSize: 8, cellPadding: 0.2, valign: 'middle', halign: 'center' },
    headStyles: { lineWidth: 0, minCellHeight: 9 },
    columnStyles: {
      0: { columnWidth: 8, halign: 'center' },
      1: { columnWidth: 48, halign: 'left' },
      2: { columnWidth: 12, halign: 'right' },
    },
    theme: ['plain'],
    head: [['Cant', 'Descripcion', 'Precio']],
    body: nuevoArray
  })


  let finalY = doc.previousAutoTable.finalY
  linea = finalY + 2

  //-------------------------------------------------------
  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3

  doc.setFont('Helvetica', '');

  doc.text("OP. GRAVADA", lMargin, linea)
  doc.text("S./" + (parseFloat(operaciongravada) / 1.18).toFixed(2).toString(), 68, linea, 'right')
  linea = linea + 3.5

  doc.text("IGV 18%", lMargin, linea)
  doc.text("S./" + ((parseFloat(operaciongravada) / 1.18) * 0.18).toFixed(2).toString(), 68, linea, 'right')
  linea = linea + 3.5


  doc.text("Total", lMargin, linea)
  doc.text("S./" + total, 68, linea, 'right')
  linea = linea + 3.5

  doc.setFont('Helvetica', 'bold');
  doc.text(separacion, pageCenter, linea, 'center');
  linea = linea + 3
  doc.setFont('Helvetica', '');

  linea = linea + 3
  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize('TE ESPERAMOS!', (pdfInMM - lMargin - rMargin));
  doc.text(texto, pageCenter, linea, 'center');

  linea = linea + parseFloat(store.state.configImpresora.minferiorgeneral)
  // console.log("aqui"+store.state.configImpresora.minferiorgeneral)
  doc.text('.', 0, linea)
  if (guardadocumento) {
    doc.save(ordendocumento + '.pdf')
  } else {
    window.open(doc.output('bloburl'))
  }

}
async function obten_logo(data) {
  var response = await imageToBase64('/marca_agua.png')
  var response2 = await imageToBase64('/favicon.png')
  var array = {
    logo: response2,
    marca_: response
  }
  return array
}
async function impresionA4(arrays) {

  var linea = 10
  var nombreEmpresa = store.state.baseDatos.name
  var Ruc = 'Ruc: ' + store.state.baseDatos.ruc
  var Direccion = store.state.baseDatos.direccion + '-' + store.state.baseDatos.distrito + '-' + store.state.baseDatos.provincia + '-' + store.state.baseDatos.departamento
  var imagen_agua = (await obten_logo()).marca_
  var imagen = (await obten_logo()).logo
  var array = arrays.data
  var total = arrays.total
  var fecha_emision = moment.unix(arrays.fecha).format('DD/MM/YYYY')
  var fecha_vencimiento = moment.unix(arrays.date_vence).format('DD/MM/YYYY')

  //formato de pagina de PF
  var guardadocumento = store.state.configImpresora.guardadocumento
  var lMargin = store.state.configImpresora.lMargin; //left margin in mm
  var rMargin = store.state.configImpresora.rMargin; //right margin in mm
  var pdfInMM = 210;  // width of A4 in mm
  var cabecera = store.state.configImpresora.cabecera;
  var telefono = store.state.configImpresora.telefono;


  const doc = new jspdf({
    orientation: "portrait",
    unit: "mm",
    format: [149, pdfInMM]
  })

  doc.text('.', -1, linea)

  doc.addImage("data:image/png;base64," + imagen_agua, 'png', 12, 15, 130, 130, '', 'FAST');
  doc.addImage("data:image/png;base64," + imagen, 'png', 55, 5, 40, 40, '', 'FAST');


  /*  linea = linea + 10
      doc.setFont('Helvetica', 'Bold');
      doc.setFontSize(9.5)
      var texto = doc.splitTextToSize(nombreEmpresa, 80);
      doc.text(texto, 55, linea, 'left'); //EMPRESA
  
      linea = linea + (4 * texto.length)
  
      if (cabecera != '') {
        doc.setFont('Helvetica', '');
        doc.setFontSize(8)
        var texto = doc.splitTextToSize(cabecera, 70);
        doc.text(texto, 55, linea, 'left'); //CABECERA
        linea = linea + (4 * texto.length)
      }
  
      doc.setFont('Helvetica', '');
      doc.setFontSize(8)
      var texto = doc.splitTextToSize(Direccion, 70);
      doc.text(texto, 55, linea, 'left'); //direccion
  
      linea = linea + (4 * texto.length)
      if (telefono != '') {
        doc.setFont('Helvetica', '');
        doc.setFontSize(8)
        var texto = doc.splitTextToSize("TELEFONO: " + telefono, 70);
        doc.text(texto, 55, linea, 'left'); //TELEFONO EMPRESA
      }
  */

  doc.setFontSize(8)
  doc.setLineWidth(0.3);
  doc.rect(5, 40, 140, 15);
  linea = 45

  doc.setFont('Helvetica', 'Bold');
  doc.text("SEÑORES", 10, linea, 'left');
  doc.text(" : ", 27, linea, 'left');
  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize(arrays.cliente_nombre, 85);
  doc.text(texto, 31, linea, 'left');
  linea = linea + (3.5 * texto.length)

  doc.setFont('Helvetica', 'Bold');
  doc.text("RUC", 10, linea, 'left');
  doc.text(" : ", 27, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text(arrays.cliente_dni, 31, linea, 'left');
  linea = linea + (3.5)

  linea = 45

  doc.setFont('Helvetica', 'Bold');
  doc.text("F.EMISION", 100, linea, 'left');
  doc.text(" : ", 124, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text(fecha_emision, 127, linea, 'left');
  linea = linea + 4

  doc.setFont('Helvetica', 'Bold');
  doc.text("F.VENCIMIENTO", 100, linea, 'left');
  doc.text(" : ", 124, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text(fecha_vencimiento, 127, linea, 'left');
  linea = linea + (4)



  linea = 60

  doc.setFont('Helvetica', '');
  doc.setFontSize(9)
  var texto = doc.splitTextToSize("De nuestra consideración:" + '\n'
    + "Por medio de la presente y de acuerdo a los requerimientos, nos complace hacerles llegar nuestra propuesta:", 150);
  doc.text(texto, 5, linea, 'left'); //direccion
  linea = linea + (3.5 * texto.length)



  //-----------------productos-----------------------
  var operacionexonerada = 0
  var operaciongravada = 0
  var nuevoArray = new Array(array.length);
  for (var i = 0; i < array.length; i++) {
    if (array[i].tipo == 'P') {
      var nombre = array[i].tipo_focal
    }
    if (array[i].tipo == 'M') {
      var nombre = 'MONTURA'
    }
    nuevoArray[i] = new Array(4);
    nuevoArray[i][0] = array[i].cantidad;
    nuevoArray[i][1] = nombre;
    nuevoArray[i][2] = array[i].medida;
    nuevoArray[i][3] = 'S/.' + array[i].precio;
    operaciongravada = parseFloat(operaciongravada) + parseFloat((array[i].precio) * array[i].cantidad)


  }

  doc.autoTable({
    margin: { top: linea, left: 5 },
    styles: { fontSize: 8, cellPadding: 2, valign: 'middle', halign: 'center', lineWidth: 0.2, lineColor: 1 },
    headStyles: { lineWidth: 0.2, lineColor: 1 },
    columnStyles: {
      0: { columnWidth: 15, halign: 'center', fontStyle: 'bold' },
      1: { columnWidth: 95, halign: 'left', },
      2: { columnWidth: 15, halign: 'center', },
      3: { columnWidth: 15, halign: 'center' },
    },
    theme: ['striped'],
    head: [['Cant', 'Descripcion', 'Medida', 'P.Total']],
    body: nuevoArray
  })

  let finalY = doc.previousAutoTable.finalY
  linea = finalY + 5
  var lineaqr = linea
  doc.setDrawColor(0, 0, 0);
  doc.setLineWidth(0.3);
  doc.rect(85, linea, 60, 20);

  linea = linea + 5


  doc.setFontSize(8)
  doc.setFont('Helvetica', 'Bold');
  doc.text("OP. GRAVADA", 90, linea, 'left');
  doc.text(" : ", 112, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text("S./" + (parseFloat(operaciongravada) / 1.18).toFixed(2).toString(), 122, linea, 'left');
  linea = linea + 4

  doc.setFontSize(8)
  doc.setFont('Helvetica', 'Bold');
  doc.text("IGV 18%", 90, linea, 'left');
  doc.text(" : ", 112, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text("S./" + ((parseFloat(operaciongravada) / 1.18) * 0.18).toFixed(2).toString(), 122, linea, 'left');
  linea = linea + 4

  doc.setFontSize(8)
  doc.setFont('Helvetica', 'Bold');
  doc.text("TOTAL", 90, linea, 'left');
  doc.text(" : ", 112, linea, 'left');
  doc.setFont('Helvetica', '');
  doc.text("S./" + parseFloat(total).toFixed(2).toString(), 122, linea, 'left');
  linea = linea + 4

  doc.text('.', 0, linea)
  //doc.text(numeros_a_letras(parseFloat(cuentatotal),'nominal',0,'CENTIMOS','SOLES'),0,linea) 
  doc.setFontSize(10)
  linea = linea + 15
  doc.setFont('Helvetica', '');
  var texto = doc.splitTextToSize('TE ESPERAMOS!', (pdfInMM - lMargin - rMargin));
  doc.text(texto, 72, linea, 'center');

  if (guardadocumento) {
    doc.save(ordendocumento + '.pdf')
  } else {
    window.open(doc.output('bloburl'))
  }

}
