<template>
    <v-dialog v-model="dial" max-width="460px" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
            </v-system-bar>
        </div>

        <v-card class="pa-4">

            <v-card-title primary-title>
                <div>
                    <v-row>
                        <v-checkbox dense v-model="notaventa" label="Nota de venta"></v-checkbox>
                    </v-row>
                    <v-row>
                        <v-text-field v-model="ticket" label="TICKET"></v-text-field>
                        <v-text-field v-model="factura" label="FACTURA"></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field v-model="boleta" label="BOLETA"></v-text-field>
                        <v-text-field v-model="notacredito" label="NOTA DE CREDITO"></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field v-model="guia" label="GUIA REMISION"></v-text-field>
                    </v-row>
                </div>
            </v-card-title>
            <v-card-actions>
                <v-btn @click="grabaContador()" block color="green">Guardar</v-btn>
            </v-card-actions>

        </v-card>

    </v-dialog>
</template>

<script>
import {
    obtenerSeries,
    actualizaSeries
} from '../../db'
import store from '@/store/index'

export default {

    data() {
        return {
            dial: false,
            ticket: 'T001',
            factura: 'F001',
            boleta: 'B001',
            notacredito: '01',
            guia: 'T001',
            notaventa: false,
        }
    },
    mounted() {
        this.initialize()
    },
    created() {
        this.dial = true
    },

    methods: {
        initialize() {
            this.desserts = []
            obtenerSeries().once("value").then((snapshot) => {
                if (snapshot.exists) {
                    this.ticket = snapshot.val().ticket
                    this.factura = snapshot.val().factura
                    this.boleta = snapshot.val().boleta
                    this.notacredito = snapshot.val().notacredito
                    this.notaventa = snapshot.val().notaventa
                    this.guia = snapshot.val().guia

                } else {
                    this.ticket = 'T001'
                    this.factura = 'F001'
                    this.boleta = 'B001'
                    this.notacredito = 'NC01'
                    this.guia = 'T001'
                    this.notaventa = false
                }
            })
        },
        grabaContador() {
            var array = {}
            array = {
                ticket: this.ticket,
                factura: this.factura,
                boleta: this.boleta,
                notacredito: this.notacredito,
                notaventa: this.notaventa,
                guia: this.guia
            }
            store.commit("seriesdocumentos", array)
            actualizaSeries('ticket', this.ticket)
            actualizaSeries('factura', this.factura)
            actualizaSeries('boleta', this.boleta)
            actualizaSeries('notacredito', this.notacredito)
            actualizaSeries('notaventa', this.notaventa)
            actualizaSeries('guia', this.guia)
            this.cierra()
        },
        cierra() {
            this.$emit('cierra', false);
        }

    }

}
</script>
