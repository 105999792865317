<template>
    <v-dialog v-model="dial" max-width="900" persistent>
        <div>
            <v-system-bar window dark>
                <v-icon large color="red" @click="cierra()">mdi-close</v-icon>
                <v-spacer></v-spacer>
                <h5 style="font-size:100%;"> <strong>Fecha Medicion :</strong> {{ conviertefecha(fecha) }}
                </h5>
                <v-spacer></v-spacer>
                <h4>{{ serie }}</h4>

                <v-icon v-if="false" color="green" @click="edita_externa()">mdi-pencil</v-icon>
            </v-system-bar>
        </div>
        <v-card class="pa-3">
            <v-card class="pa-1">
                <v-row dense>
                    <v-col cols="12">
                        <p style="font-size:80%;"> <strong>Paciente :</strong> {{ nom_paciente }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense class="mt-n6">
                    <v-col cols="12">
                        <p style="font-size:80%;"> <strong>Especialista :</strong> {{ especialista }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense class="mt-n6 ">
                    <v-col cols="6">
                        <p style="font-size:80%;"> <strong>Diagnostico :</strong> {{ diagnostico }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense class="mt-n6 mb-n1">
                    <v-col cols="6">
                        <p style="font-size:80%;"> <strong>Comentarios :</strong> {{ comentarios }}
                        </p>
                    </v-col>
                    <v-col cols="6" class="mt-n14 mb-n1">

                        <p style="font-size:80%;"> <strong>Recomendaciones :</strong>

                        </p>
                        <div class="mt-n4" style="font-size:80%;">
                            <div class="ml-n2" v-for="item in recomenda" :key="item.id">
                                <div>
                                    <v-icon color="red" @click="selecciona_recomendacion(item)">mdi-crosshairs-gps</v-icon>
                                    <u v-if="item.comprado">{{ item.marca }}/{{ item.descripcion }} </u>
                                    <span v-if="!item.comprado">{{ item.marca }}/{{ item.descripcion }} </span>
                                </div>

                            </div>

                        </div>
                    </v-col>
                    <v-col cols="6" class="mt-n10 mb-n1">
                    </v-col>
                    <v-col cols="6" class="mt-n1 mb-n1" v-if="array_compra != ''">
                        <p style="font-size:80%;"> <strong>Comprado :</strong>

                        </p>
                        <div class="mt-n4" style="font-size:80%;">
                            <div class="ml-n2" v-for="item in array_compra" :key="item.id">
                                <div>
                                    <span>{{ item.id_pos + 1 }}) {{ item.nombre }} </span>
                                </div>

                            </div>
                        </div>

                    </v-col>
                </v-row>
            </v-card>

            <v-row class="mt-2" style="font-size:85%;">
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n4 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OD
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Esfera:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ esfera_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Cilindro:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ cilindro_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>EJE:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ eje_od }}°
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>DNP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dnp_od }}mm
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>A/V:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    20/{{ av_od }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>PIO:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ pio_od }}mmHg
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card>
                        <v-card-title class="subheading font-weight-bold mt-n4 mb-n4">
                            <v-icon class="ml-1 mr-1" color="red" dark>mdi-eye-outline</v-icon>OI
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-list dense>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Esfera:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ esfera_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>Cilindro:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ cilindro_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>EJE:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ eje_oi }}°
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>DNP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dnp_oi }}mm
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>A/V:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    20/{{ av_oi }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>PIO:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ pio_oi }}mmHg
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

            </v-row>
            <v-row class="mt-n4 mb-n2" style="font-size:85%;">
                <v-col cols="12">
                    <v-card>
                        <v-list dense>
                            <v-list-item class="mt-n3">
                                <v-list-item-content>ADD CERCA:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ add }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4">
                                <v-list-item-content>ADD INTERM:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ add_int }}
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item class="mt-n4 mb-n2">
                                <v-list-item-content>DIP:</v-list-item-content>
                                <v-list-item-content class="align-end">
                                    {{ dip }}mm
                                </v-list-item-content>
                            </v-list-item>

                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import store from '@/store/index'
import moment from 'moment'
import {
    allMonturas,
} from '../../db'
export default {
    props: {
        data: ''
    },
    data() {
        return {
            dial: false,
            esfera_od: 0,
            cilindro_od: 0,
            eje_od: 0,
            dnp_od: 25,
            av_od: '',
            pio_od: '',
            esfera_oi: 0,
            cilindro_oi: 0,
            eje_oi: 0,
            dnp_oi: 25,
            av_oi: '',
            pio_oi: '',
            add: 0,
            add_int: 0,
            dip: 0,
            nom_paciente: '',
            especialista: '',
            diagnostico: '',
            comentarios: '',
            recomendaciones: '',
            fecha: '',
            array_recom: [],
            array_compra: [],
            serie: ''
        }
    },
    created() {

        this.inicio()
    },
    mounted() {

    },
    beforeDestroy() {

    },
    computed: {
        recomenda() {
            this.array_recom = this.data.array_recom
            return this.array_recom
        }
    },
    methods: {
        inicio() {
            var arra = []
            if (Boolean(this.data.array_recom)) {
                var iss = this.data.array_recom
                for (var i = 0; i < iss.length; i++) {
                    iss[i].id_pos = i
                    arra.push(iss[i])
                }
            }
            console.log(this.data)
            this.data.array_recom = arra
            this.esfera_od = this.data.esfera_od
            this.esfera_oi = this.data.esfera_oi
            this.cilindro_od = this.data.cilindro_od
            this.cilindro_oi = this.data.cilindro_oi
            this.eje_od = this.data.eje_od
            this.eje_oi = this.data.eje_oi
            this.dnp_od = this.data.dnp_od
            this.dnp_oi = this.data.dnp_oi
            this.av_od = this.data.av_od
            this.av_oi = this.data.av_oi
            this.pio_od = this.data.pio_od
            this.pio_oi = this.data.pio_oi
            this.add = this.data.add
            this.add_int = this.data.add_int
            this.dip = this.data.dip
            this.nom_paciente = this.data.nom_cliente
            this.especialista = this.data.especialista
            this.diagnostico = this.data.diagnostico
            this.comentarios = this.data.comentarios
            this.fecha = this.data.fecha
            this.recomendaciones = this.data.recomendaciones
            this.array_recom = this.data.array_recom
            this.serie = this.data.serie
            if (this.data.array_compra != undefined) {
                this.array_compra = this.data.array_compra
            }

            this.dial = true
        },
        conviertefecha(date) {
            return moment.unix(date).format('YYYY-MM-DD')
        },
        cierra() {
            this.$emit('cierra', false)
        },
        selecciona_recomendacion(item) {
            this.$emit('recomienda', item)
        },
        edita_externa() {

        }

    },

}
</script>
